<ng-container *ngIf="texts$ | async as texts">
  <!-- no link content button -->
  <ng-container *ngIf="!link">
    <div class="read-more-container">
      <div [@openClose]="isContentToggled" class="content">
        <!-- animation working only on parent for some reason..-->
        <marlenka-wysiwyg-view [html]="content"> </marlenka-wysiwyg-view>
      </div>
      <div class="buttons-wrap" *ngIf="initialTextLength > limit">
        <button *ngIf="!isContentToggled" (click)="toggleContent()">
          {{ texts.readMore }}
        </button>
        <button *ngIf="isContentToggled" (click)="toggleContent()">
          {{ texts.readLess }}
        </button>
      </div>
    </div>
  </ng-container>

  <!-- link no content button -->
  <ng-container *ngIf="link">
    <a [routerLink]="link" mat-flat-button class="button">
      {{ texts.readMore }}
    </a>
  </ng-container>
</ng-container>
