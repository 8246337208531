<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  <marlenka-wysiwyg-view [html]="data.content"></marlenka-wysiwyg-view>
</div>
<div class="actions" mat-dialog-actions>
  <button
    mat-stroked-button
    class="button secondary"
    [mat-dialog-close]="false"
  >
    Nesouhlasím
  </button>
  <button mat-button class="button filled primary" [mat-dialog-close]="true">
    Souhlasím
  </button>
</div>
