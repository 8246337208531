import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusCellComponent } from './status-cell.component';
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";



@NgModule({
    declarations: [
        StatusCellComponent
    ],
    exports: [
        StatusCellComponent
    ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule
  ]
})
export class StatusCellModule { }
