<div class="content">
  <marlenka-section-header
    [name]="texts.title"
    class="padded-header"
    *ngIf="pageTexts$ | async as texts"
  >
    <p class="content" content>
      {{ texts.subtitle }}
    </p>
  </marlenka-section-header>
  <marlenka-code-input
    #codeInputComponent
    (valueChanged)="couponChange($event)"
    [isLoading]="loadingCounter > 0"
  ></marlenka-code-input>

  <mat-error [style]="error.hasError ? 'display:flex' : 'display:none'">
    {{ error.message }}
  </mat-error>
  <ng-container *ngIf="reservationService.selectedCoupons$ | async as coupons">
    <marlenka-item-list
      *ngIf="coupons.length > 0"
      [items]="reservationService.selectedCoupons$"
      [fields]="couponTableFields"
      (remove)="removeCoupon($event)"
    ></marlenka-item-list>
  </ng-container>
  <marlenka-step-summary
    [top]="false"
    *ngIf="reservationService.selectedCoupons.length > 0"
  >
    <div bottom-row-additional>Celková cena za vouchery</div>
    <div bottom-row-main>{{ couponsTotal$ | async }} Kč</div>
  </marlenka-step-summary>
</div>

<mat-dialog-actions align="end" *ngIf="data">
  <span style="font-style: italic; font-size: 90%"
    >Pokračujte v zadávání, nebo
  </span>
  <button mat-flat-button class="finish-button" mat-dialog-close>
    Dokončit zadávání
  </button>
</mat-dialog-actions>
