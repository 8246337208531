import { gql } from 'apollo-angular';

export const deleteOwners = gql`
  mutation deleteOwners($input: [String!]!) {
    response: deleteOwner(ids: $input) {
      userMessage
      error
      description
    }
  }
`;
