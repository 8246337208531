import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieConsentManager } from './cookie-consent-manager.service';
import { CookieConsentDialogComponent } from './cookie-consent-dialog/cookie-consent-dialog.component';
import { COOKIE_CONSENT_CONFIG, CookieConsentConfig } from './types/config';

@NgModule({
  imports: [CommonModule],
  providers: [CookieConsentManager],
  declarations: [CookieConsentDialogComponent],
})
export class SharedNgFeatureCookiesModule {
  /**
   * forRoot method for setting user configuration
   *
   * @param config
   */
  static forRoot(
    config: CookieConsentConfig
  ): ModuleWithProviders<SharedNgFeatureCookiesModule> {
    return {
      ngModule: SharedNgFeatureCookiesModule,
      providers: [
        {
          provide: COOKIE_CONSENT_CONFIG,
          useValue: config,
        },
      ],
    };
  }

  constructor(private csm: CookieConsentManager) {}
}
