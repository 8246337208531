import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, map } from "rxjs";

export type NameAndHolidayInfo = { name?: string, holiday?: string }

export interface NamesAndHolidaysServiceSettings {
  dayInfoUrl?: string;
  dateFormat?: string;
  nameFieldName?: string;
  holidayFieldName?: string;
}

@Injectable()
export class NamesAndHolidaysService {

  constructor(
    private readonly http: HttpClient,
    @Inject(NAMES_AND_HOLIDAYS_SERVICE_SETTINS_TOKEN) private readonly settings: NamesAndHolidaysServiceSettings) { }


  getInfo(date: Date = undefined): Observable<NameAndHolidayInfo> {
    const d = date ? DateTime.fromJSDate(date) : DateTime.now();
    return this.http.get(this.settings.dayInfoUrl.replace('{0}', d.toFormat(this.settings.dateFormat ?? 'yyyy-MM-dd')))
      .pipe(map((x: any) => ({
        name: x[this.settings.nameFieldName ?? 'name'],
        holiday: x[this.settings.holidayFieldName ?? 'holidayName'],
      })));
  }
}

export const NAMES_AND_HOLIDAYS_SERVICE_SETTINS_TOKEN = new InjectionToken<NamesAndHolidaysServiceSettings>("NAMES_AND_HOLIDAYS_SERVICE_SETTINS_TOKEN");