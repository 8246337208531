<div
  class="flex flex-col gap-2 p-2 text-lg"
  [ngClass]="[bgColor | prefix: 'bg']"
>
  <!-- next | current | prev -->
  <div class="flex justify-between items-center p-2 pb-4">
    <!-- previous month -->
    <button (click)="previousMonth()" class="px-4 py-2 text-black text-xl">
      <i class="fas fa-arrow-left fa-fw"></i>
    </button>

    <!-- current month and year -->
    <span
      class="uppercase font-semibold"
      [ngClass]="[accentColor | prefix: 'text']"
      >{{ currentMonth | monthName: language }}
      {{ currentMonth.getFullYear() }}</span
    >

    <!-- next month-->
    <button (click)="nextMonth()" class="px-4 py-2 text-black text-xl">
      <i class="fas fa-arrow-right fa-fw"></i>
    </button>
  </div>

  <!-- days of the week -->
  <div class="grid grid-cols-7 gap-2 text-white">
    <div
      *ngFor="let day of days"
      class="flex items-center justify-center px-4 py-2 uppercase"
      [ngClass]="[accentColor | prefix: 'bg']"
    >
      {{ day }}
    </div>
  </div>

  <!-- days of the month -->
  <div class="grid grid-cols-7 gap-2 text-white">
    <div
      *ngFor="let i of currentMonth | daysOfMonth; first as isFirst"
      class="flex items-center justify-center px-4 py-2 cursor-pointer"
      [ngClass]="[
        isSelected(i)
          ? (activeColor | prefix: 'bg')
          : (secondaryColor | prefix: 'bg')
      ]"
      [class.text-white]="isSelected(i)"
      (click)="selectDay(i)"
      [style.grid-column]="isFirst ? (currentMonth | firstDayOfMonth) : ''"
    >
      {{ i + 1 | number: '2.0' }}
    </div>
  </div>
</div>
