<nav
  class="flex container mx-auto h-12 sm:h-20 justify-between font-body gap-8"
  [ngClass]="[bgColor | prefix: 'bg']"
>
  <img
    alt="Logo Obce"
    (click)="itemClicked.next(navigation?.screen?.slug ?? '/')"
    class="cursor-pointer order-2 sm:order-1 justify-center sm:justify-start h-full sm:block hidden"
    [src]="fullLogo?.url ?? smallLogo?.url"
  />
  <img
    alt="Logo Obce"
    (click)="itemClicked.next(navigation?.screen?.slug ?? '/')"
    class="cursor-pointer order-2 sm:order-1 justify-center sm:justify-start h-full block sm:hidden"
    [src]="smallLogo?.url ?? fullLogo?.url"
  />

  <!-- NAV ITEMS -->
  <div class="flex items-center md:flex-1 order-1 sm:order-2">
    <div class="hidden sm:flex">
      <echo-nx-nav-dropdown-item
        (itemClicked)="itemClicked.next($event)"
        [item]="navigation"
      ></echo-nx-nav-dropdown-item>
    </div>

    <div
      class="flex sm:hidden relative cursor-pointer"
      style="width: 2.25rem; height: 2.25rem"
      (click)="toggleSideNav()"
    >
      <div
        class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <span
          class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
          [ngClass]="{
            'rotate-45': sideMenuActive,
            ' -translate-y-2': !sideMenuActive
          }"
        ></span>
        <span
          class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
          [ngClass]="{ 'opacity-0': sideMenuActive }"
        ></span>
        <span
          class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
          [ngClass]="{
            '-rotate-45': sideMenuActive,
            ' translate-y-2': !sideMenuActive
          }"
        ></span>
      </div>
    </div>
  </div>

  <!-- SEARCH -->
  <div class="flex items-center justify-end order-3">
    <form
      (submit)="onSearchSubmit($event, searchInput.value)"
      class="hidden sm:block relative"
    >
      <input
        #searchInput
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 mr-1"
        placeholder="Vyhledat..."
        type="text"
      />
      <button
        type="submit"
        class="absolute top-0 bottom-0 right-0 bg-primary-500 text-white font-medium rounded-r-lg text-sm p-3 text-center inline-flex items-center"
      >
        <i class="fas fa-search"></i>
      </button>
    </form>
    <div class="w-8 flex sm:hidden" (click)="showSearch()">
      <i
        [ngClass]="[primaryColor | prefix: 'text']"
        class="text-xl fas fa-search"
      ></i>
    </div>
  </div>
</nav>
