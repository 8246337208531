import { Component, Input, OnChanges } from '@angular/core';
import { MarlenkaRouteData } from '@echo-nx/marlenka/common';
import { LanguageRouteDataService } from '@echo-nx/shared/ng/feature/language';
import { SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'marlenka-menu-grid-banner',
  templateUrl: './menu-grid-banner.component.html',
  styleUrls: ['./menu-grid-banner.component.scss'],
})
export class MenuGridBannerComponent implements OnChanges {
  @Input()
  public mainItem!: GridMenuItem & { gradientStyle?: SafeStyle };

  @Input()
  public mainSideItems?: GridMenuItem[];

  @Input()
  public bottomItems?: GridMenuItem[];

  @Input()
  public topWave!: boolean; // svg wave on top of the Jumbotron

  @Input()
  public firstColBigger?: boolean;

  constructor(public mrdService: LanguageRouteDataService<MarlenkaRouteData>) {}

  ngOnChanges(): void {
    if (this.mainItem.backgroundColor) {
      this.mainItem.gradientStyle = `linear-gradient(180deg, ${this.mainItem.backgroundColor} 0%, rgba(255,255,255,0) 100%)`;
    }
  }
}

export interface IGridMenuItem {
  title: string;
  subtitle?: string;
  routeTo?: string;
  backgroundImg?: string;
  backgroundColor?: string;
  foregroundColor?: string;
}

export class GridMenuItem {
  title: string;
  subtitle?: string;
  routeTo?: string;
  isLinkExternal: boolean;
  fragment?: string;
  backgroundImg?: string;
  backgroundColor?: string;
  foregroundColor?: string;

  constructor(
    gridMenuItem: IGridMenuItem,
    public mrdService: LanguageRouteDataService<MarlenkaRouteData>
  ) {
    this.title = gridMenuItem.title;
    this.subtitle = gridMenuItem.subtitle;
    this.backgroundImg = gridMenuItem.backgroundImg;
    this.backgroundColor = gridMenuItem.backgroundColor;
    this.foregroundColor = gridMenuItem.foregroundColor;

    if (gridMenuItem.routeTo) {
      this.isLinkExternal = isValidURL(gridMenuItem.routeTo);
      if (this.isLinkExternal) {
        this.routeTo = gridMenuItem.routeTo;
      } else {
        const splitByHash = gridMenuItem.routeTo.split('#');
        this.routeTo = `/${this.mrdService.currentLanguage.toLowerCase()}/${
          splitByHash[0]
        }`;
        if (splitByHash.length > 1) {
          this.fragment = splitByHash[1];
        }
      }
    }
  }
}

const isValidURL = (str: string) => {
  const res = str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};
