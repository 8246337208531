import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { EchoNxFormErrors, IEntityDefinition } from '../interfaces';
import {
  IBaseFormFieldSettings,
  IGroupFieldSettings,
  IRepeaterFieldSettings,
} from '../modules/form-fields';
import { MongoID } from '@echo-nx/shared/common';
import { IInjectableEntityFormServiceData } from '@echo-nx/shared/ng/feature/common';

/**
 * Rekurzivní funkce, která staví formulář a zároveň do něj dává správné defaulty (no need to patch after)
 *
 * @param entityDefinitions
 * @param objectToUpdate - JSON z aktuálního podstromu formuláře/entity
 */
export const buildEntityForm = (
  entityDefinitions: IEntityDefinition[],
  objectToUpdate?: any,
  formToBuild?: UntypedFormGroup
) => {
  const form = formToBuild ? formToBuild : new UntypedFormGroup({});

  for (const entityDefinition of entityDefinitions) {
    const { name, settings, disabled } = entityDefinition;
    const validators = entityDefinition.validators ?? [];
    const { formControlName, defaultValue } =
      settings as IBaseFormFieldSettings;

    let controlToAdd: AbstractControl;
    let childrenEntityDefinition: IEntityDefinition[];

    if (name === 'PkInputGroupFieldComponent') {
      childrenEntityDefinition = (settings as IGroupFieldSettings)
        .groupDefinition;
      controlToAdd = buildEntityForm(
        childrenEntityDefinition,
        objectToUpdate?.[formControlName],
        new UntypedFormGroup({})
      );
    } else if (name === 'PkInputRepeaterFieldComponent') {
      childrenEntityDefinition = (settings as IRepeaterFieldSettings)
        .rowDefinition;
      const hasValue =
        objectToUpdate?.[formControlName] &&
        objectToUpdate?.[formControlName]?.length > 0;
      const value = !hasValue
        ? []
        : objectToUpdate?.[formControlName].map((e: any) =>
            buildEntityForm(
              childrenEntityDefinition,
              e,
              new UntypedFormGroup({})
            )
          );
      controlToAdd = new UntypedFormArray(value, validators);
    } else {
      const value =
        objectToUpdate?.[formControlName] ?? defaultValue ?? undefined;
      controlToAdd = new UntypedFormControl({ value, disabled }, validators);
    }

    form.addControl(formControlName, controlToAdd);
  }
  return form;
};

export function createFormWatcher(entityDefinitions: IEntityDefinition[]): {
  [key: string]: Subject<boolean>;
} {
  const displayObservables: { [key: string]: Subject<boolean> } = {};

  // build displayObservables obj
  for (const entityDefinition of entityDefinitions) {
    if (
      Object.prototype.hasOwnProperty.call(
        entityDefinition.settings,
        'showFunction'
      )
    ) {
      const { formControlName } = entityDefinition.settings;
      displayObservables[formControlName] = new Subject<boolean>();
    }
  }

  // vratim objekt se subjectama, at s nim muze view pracovat
  return displayObservables;
}

/*export function hookFormToFields(entityDefinitions: IEntityDefinition[], form: FormGroup) {
  entityDefinitions.forEach((entityDefinition: IEntityDefinition) => {
    entityDefinition.settings.form = form;
  });
}*/

export const getFirstError = (
  formControlName: string,
  form?: UntypedFormGroup
) => {
  const formControl = form?.get(formControlName);
  return formErrorHandler(formControl);
};

function formErrorHandler(
  formControl?: AbstractControl | null
): string | undefined {
  if (!formControl) {
    return undefined;
  }

  /**
   * This transform array of errors into one error, so we can display it.
   */
  const errors = { ...formControl.errors };
  if (errors && Object.keys(errors).length > 0) {
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== null) {
        formControl.setErrors(errors[key]);
      }
    });
  }

  if (!formControl.errors) {
    return undefined;
  }
  const errorKeys = formControl.errors ? Object.keys(formControl.errors) : [];
  const errorMsg =
    errorKeys.length > 0 ? formControl.errors[errorKeys[0]] : null;
  const errorKey = errorKeys[0];
  const errorVal = formControl.errors[errorKey];

  if (!(errorMsg && errorVal)) {
    return undefined;
  }

  // regex pattern OR date pattern
  // console.log('ERROR KEY', formControl);
  // todo use i18n
  if (errorKey === 'pattern' || errorKeys.includes('matDatetimepickerParse')) {
    return 'Hodnota není ve správném formátu!';
  } else if (errorKey === 'required') {
    return 'Toto pole je povinné!';
  } else if (errorKey === 'minlength') {
    const { requiredLength } = errorVal;
    return `Minimální délka je ${requiredLength} znaků!`;
  } else if (errorKey === 'pkerror') {
    return errorVal;
  } else {
    return 'Toto pole není validní!';
  }
}

export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function hasValue(item: any) {
  return isObject(item) || (Array.isArray(item) && item.length > 0);
}

export const transformSingularId = (record?: MongoID) =>
  typeof record === 'string' ? record : record?._id ?? null;
export const transformMultipleIds = (records?: MongoID[]) =>
  records?.map(({ _id }) => _id) ?? [];

export const getEntityDefinitionLabel = (
  nazev:
    | string
    | ((
        settings: IBaseFormFieldSettings,
        formGroup: UntypedFormGroup
      ) => string),
  formGroup: UntypedFormGroup,
  settings: any
): string => {
  if (typeof nazev === 'string') {
    return nazev;
  } else if (typeof nazev === 'function') {
    // nazev IS function
    return nazev(settings, formGroup);
  }
  return '';
};

function isFormGroup(control: AbstractControl): control is UntypedFormGroup {
  return !!(<UntypedFormGroup>control).controls;
}

export const collectErrors = (
  control: AbstractControl,
  entityDefinition?: IEntityDefinition<IBaseFormFieldSettings>[],
  currentKey: string[] = [],
  currentLabel: string[] = []
): EchoNxFormErrors => {
  if (isFormGroup(control)) {
    return Object.entries(control.controls).reduce(
      (acc: EchoNxFormErrors, [key, childControl]) => {
        const currentEntityDefinition = entityDefinition?.find(
          ({ settings }) => settings.formControlName === key
        );
        const subEntityDefinition =
          (currentEntityDefinition?.settings as IGroupFieldSettings)
            ?.groupDefinition ??
          (currentEntityDefinition?.settings as IRepeaterFieldSettings)
            ?.rowDefinition ??
          (!Number.isNaN(Number(key))
            ? entityDefinition
            : [currentEntityDefinition]);

        const { nazev } = currentEntityDefinition?.settings ?? {};
        const label = nazev
          ? [
              getEntityDefinitionLabel(
                nazev,
                control,
                currentEntityDefinition?.settings
              ),
            ]
          : [key];

        const childErrors = collectErrors(
          childControl,
          subEntityDefinition,
          [...currentKey, key],
          [...currentLabel, ...label]
        );

        if (childErrors.errorTree) {
          acc = {
            errorTree: { ...acc?.errorTree, [key]: childErrors.errorTree },
            errorMessages: [...acc.errorMessages, ...childErrors.errorMessages],
          };
        }
        return acc;
      },
      { errorTree: null, errorMessages: [] } as EchoNxFormErrors
    );
  } else {
    return {
      errorTree: control.errors,
      errorMessages: Object.entries(control.errors ?? {}).map(
        ([errorKey, params]) => ({
          params,
          errorKey,
          key: currentKey,
          label: currentLabel,
        })
      ),
    };
  }
};

/**
 * Injects given token for entityFormService to all subfields recursively
 * @param entityDefinitions - starting entity definitions
 * @param entityFormServiceData - token data to inject
 * @returns entity definition with IInjectableEntityFormServiceData for entityFormService
 */
export const injectEntityFormServiceData = (
  entityDefinitions: IEntityDefinition[],
  entityFormServiceData: IInjectableEntityFormServiceData
): IEntityDefinition[] => {
  return entityDefinitions.map((ed) => {
    const { name } = ed;

    if (name === 'PkInputGroupFieldComponent') {
      return {
        ...ed,
        entityFormServiceData,
        settings: {
          ...ed.settings,
          groupDefinition: injectEntityFormServiceData(
            ed.settings.groupDefinition,
            entityFormServiceData
          ),
        } as IGroupFieldSettings,
      };
    } else if (name === 'PkInputRepeaterFieldComponent') {
      return {
        ...ed,
        entityFormServiceData,
        settings: {
          ...ed.settings,
          rowDefinition: injectEntityFormServiceData(
            ed.settings.rowDefinition,
            entityFormServiceData
          ),
        } as IRepeaterFieldSettings,
      };
    }

    return {
      ...ed,
      entityFormServiceData,
    };
  });
};
