import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GroupFieldComponent} from './group-field.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {PortalModule} from "@angular/cdk/portal";
import {
  DynamicComponentPipeModule,
  DynamicComponentRegistryService
} from "@echo-nx/shared/ng/feature/common";
import {MatButtonModule} from "@angular/material/button";
import { GroupComponentTreeDirective } from './group-component-tree.directive';



@NgModule({
    declarations: [
        GroupFieldComponent,
        GroupComponentTreeDirective,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DynamicComponentPipeModule,
        MatCardModule,
        MatIconModule,
        PortalModule,
        MatButtonModule
    ]
})
export class GroupFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputGroupFieldComponent', GroupFieldComponent);
  }
}
