<div class="flex flex-col justify-around items-center py-4 px-8 col-span-2 items-stretch"
     [ngClass]="[ titleSize | prefix: 'text']">
  <span class="font-bold border-b-8 p-2 px-8 text-center"
        [ngClass]="[startIcon?.bgColor | prefix:'border']">
    {{title}}
  </span>
  <div class="flex items-center justify-center p-2 mt-2">
    <i [ngClass]="[startIcon?.icon, startIcon?.color | prefix:'text']"></i>
  </div>
</div>

<!-- VALUE + SUFFIX -->
<div class="flex flex-col flex-1 justify-center py-4 px-8 col-span-3">
  <div class="flex gap-x-2 justify-center">
    <span class="font-bold h-full" [ngClass]="[valueSize | prefix: 'text']">{{value}}</span>
    <span class="font-light h-full" style="vertical-align: super"
          [ngClass]="[suffixSize | prefix: 'text']">{{suffix}}</span>
  </div>
  <span *ngIf="description" class="text-xs">{{description}}</span>
</div>
