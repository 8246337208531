import { Injectable, InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Apollo, MutationResult } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { addOwner, deleteOwners } from '../mutations';
import {
  CommonEntityTypes,
  FetchAllArgs,
  IBaseOwner,
  ICategory,
  IEntityService,
  IPaginatedResponse,
  IPKApolloResult,
} from '@echo-nx/shared/common';
import { readAllOwners, readSelectedOwner } from '../queries';

@Injectable()
export class BaseOwnerService<T extends IBaseOwner>
  implements IEntityService<T>
{
  constructor(protected apollo: Apollo) {}

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteOwners,
      variables: { input: ids },
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw new Error('executeMutation Not implemented :(');
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<T>> {
    return this.apollo
      .query<IPKApolloResult<IPaginatedResponse<T>>>({
        query: readAllOwners,
        variables: args,
      })
      .pipe(map((result) => result.data.response));
  }

  fetchCategories(): Observable<ICategory<T>[]> {
    return of([]);
  }

  fetchSelected(ids: string[]): Observable<T[]> {
    return this.apollo
      .query<IPKApolloResult<T[]>>({
        query: readSelectedOwner,
        variables: {
          ids: ids,
        },
      })
      .pipe(map((result) => result.data.response));
  }

  fetchSingle(id: string): Observable<T> {
    return this.fetchSelected([id]).pipe(map((owners) => owners[0]));
  }

  getType(): string {
    return CommonEntityTypes.Owner;
  }

  save(owners: T[]): Observable<MutationResult<IPKApolloResult<T[]>>> {
    return this.apollo.mutate<IPKApolloResult<T[]>>({
      mutation: addOwner,
      variables: { input: owners },
    });
  }
}

export const BASE_OWNER_SERVICE_TOKEN = new InjectionToken<
  IEntityService<IBaseOwner>
>('CMS_BASE_OWNER_SERVICE_TOKEN');
