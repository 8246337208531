<div class="flex flex-col flex-1 w-full">
  <div class="relative flex-col flex-1 w-full flex">
    <!-- sm:-mt-20 -->

    <!--IMAGE SLIDER-->
    <div class="w-full h-full object-cover relative">
      <div
        [@sliderAnimator]="{
          value: showFirst ? animationStyle : 'none',
          params: {
            time: transitionDurationInMilis + 'ms',
            distanceTo: distanceToFirst,
            distanceFrom: distanceFromFirst
          }
        }"
        (@sliderAnimator.done)="animationDone()"
      >
        <img
          [src]="data[firstIdx].imgUrl"
          [alt]="data[firstIdx].alt"
          [ngClass]="[imageFit | prefix: 'object']"
          class="absolute inset-0 w-full h-full"
        />
      </div>
      <div
        [@sliderAnimator]="{
          value: showSecond ? animationStyle : 'none',
          params: {
            time: transitionDurationInMilis + 'ms',
            distanceTo: distanceToSecond,
            distanceFrom: distanceFromSecond
          }
        }"
      >
        <img
          [src]="data[secondIdx].imgUrl"
          [alt]="data[secondIdx].alt"
          [ngClass]="[imageFit | prefix: 'object']"
          class="absolute inset-0 w-full h-full"
        />
      </div>
      <div class="absolute inset-0">
        <div class="w-full h-full relative overflow-hidden container mx-auto">
          <!-- DESCRIPTION WRAPPER -->
          <div
            class="description z-5 filter backdrop-blur-lg opacity-75 drop-shadow-xl"
            [ngClass]="[primaryColor | prefix: 'bg']"
          ></div>
          <div
            class="description z-10 flex flex-row overflow-hidden items-center justify-between p-4"
          >
            <div
              class="flex flex-col flex-1 pr-16 items-start"
              id="text"
              [ngClass]="[textColor | prefix: 'text']"
            >
              <!--TITLE-->
              <div id="title" class="text-3xl leading-8 font-display mb-2">
                {{ data[currentIdx$.value].title }}
              </div>
              <div class="text-md line-clamp-3">
                {{ data[currentIdx$.value].description }}
              </div>
            </div>
            <div
              class="flex flex-col items-center justify-center"
              id="indicator"
            >
              <button>
                <i
                  class="fas fa-arrow-up p-3"
                  [ngClass]="[textColor | prefix: 'text']"
                  (click)="previousImage()"
                ></i>
              </button>

              <div
                class="flex flex-col flex-1 my-4 gap-2 justify-center"
                *ngIf="sliderIndexes.length > 1"
              >
                <ng-container *ngIf="sliderIndexes.length < 5">
                  <div
                    *ngFor="let i of sliderIndexes"
                    [ngClass]="
                      i === currentIdx$.value
                        ? [secondaryColor | prefix: 'bg']
                        : [textColor | prefix: 'bg']
                    "
                    class="h-2 w-2"
                  ></div>
                </ng-container>
                <ng-container *ngIf="sliderIndexes.length >= 5">
                  <div
                    class="text-center"
                    [ngClass]="[textColor | prefix: 'text']"
                  >
                    {{ currentIdx$.value + 1 }} z {{ sliderIndexes.length }}
                  </div>
                </ng-container>
              </div>
              <button>
                <i
                  class="fas fa-arrow-down p-3"
                  [ngClass]="[textColor | prefix: 'text']"
                  (click)="nextImage()"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
