import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhotoCardVariantNyrovComponent} from "./photo-card-variant-nyrov.component";
import { PrefixModule } from '../../prefix/prefix.module';
import { ResizeObserverModule } from '@echo-nx/shared/ng/feature/responsive';

@NgModule({
  declarations: [
    PhotoCardVariantNyrovComponent
  ],
  imports: [
    CommonModule,
    PrefixModule,
    ResizeObserverModule
  ],
  exports: [
    PhotoCardVariantNyrovComponent
  ]
})
export class PhotoCardVariantNyrovModule {
}
