import { IEntityDefinition } from '../interfaces';
import { BASE_MEDIUM_SERVICE_TOKEN } from '@echo-nx/shared/ng/data-access';
import {
  IDateTimeFieldSettings,
  IGroupFieldSettings,
  ISelectFieldSettings,
  ITextFieldSettings,
} from '../modules/form-fields';
import { IInjectableServiceData } from '@echo-nx/shared/ng/feature/common';
import { IEntityService, IMedium } from '@echo-nx/shared/common';
import { isObject, transformMultipleIds } from './form-utils';
import { map } from 'rxjs/operators';
import { InjectionToken } from '@angular/core';
import { IDataCardDialogSettings } from '../../data-card';

export const BuildAddressGroupDefinition = (
  cols: number,
  x?: number,
  y?: number
): IEntityDefinition => ({
  name: 'PkInputGroupFieldComponent',
  settings: {
    nazev: 'Adresa',
    color: '#fff',
    formControlName: 'address',
    expandable: true,
    groupDefinition: [
      // state
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'Země',
          povinnost: false,
          isTextArea: false,
          formControlName: 'state',
        },
        data: null,
        validators: [],
        cols: 3,
        x: 0,
        y: 0,
      },

      // city
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'Město',
          povinnost: false,
          isTextArea: false,
          formControlName: 'city',
        },
        data: null,
        validators: [],
        cols: 3,
        x: 3,
        y: 0,
      },

      // street
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'Ulice',
          povinnost: false,
          isTextArea: false,
          formControlName: 'street',
        },
        data: null,
        validators: [],
        cols: 3,
        x: 6,
        y: 0,
      },

      // zip
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'PSČ',
          povinnost: false,
          isTextArea: false,
          formControlName: 'zipcode',
        },
        data: null,
        validators: [],
        cols: 3,
        x: 9,
        y: 0,
      },
    ],
  },
  data: null,
  validators: [],
  cols,
  x,
  y,
});

export const BuildBankInfoGroupDefinition = (
  cols: number,
  x?: number,
  y?: number
): IEntityDefinition => ({
  name: 'PkInputGroupFieldComponent',
  settings: {
    nazev: 'Bankovní informace',
    color: '#fff',
    formControlName: 'bankInfo',
    expandable: true,
    groupDefinition: [
      // bankName
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'Název banky',
          povinnost: false,
          isTextArea: false,
          formControlName: 'bankName',
        },
        data: null,
        validators: [],
        cols: 12,
        x: 0,
        y: 0,
      },

      // bankNumber
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'Číslo účtu',
          povinnost: false,
          isTextArea: false,
          formControlName: 'bankNumber',
        },
        data: null,
        validators: [],
        cols: 8,
        x: 0,
        y: 1,
      },

      // bankCode
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'Kód banky',
          povinnost: false,
          isTextArea: false,
          formControlName: 'bankCode',
        },
        data: null,
        validators: [],
        cols: 4,
        x: 8,
        y: 1,
      },

      // swift
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'SWIFT',
          povinnost: false,
          isTextArea: false,
          formControlName: 'swift',
        },
        data: null,
        validators: [],
        cols: 6,
        x: 0,
        y: 2,
      },
      // iban
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'IBAN',
          povinnost: false,
          isTextArea: false,
          formControlName: 'iban',
        },
        data: null,
        validators: [],
        cols: 6,
        x: 6,
        y: 2,
      },
    ],
  },
  data: null,
  validators: [],
  cols,
  x,
  y,
});

export const buildCategoryDefinition = (
  cols: number,
  x?: number,
  y?: number,
  service?: IInjectableServiceData<any>
): IEntityDefinition => ({
  name: 'PkSelectFieldComponent',
  settings: {
    nazev: 'Kategorie',
    povinnost: false,
    formControlName: 'categories',
    isMultiple: true,
    service: service ?? {
      token: BASE_MEDIUM_SERVICE_TOKEN,
      fetchFunction: (service) => service.fetchCategories(),
    },
    displayTransformFn: (item) => item['name'],
    transformFn: transformMultipleIds,
  } as ISelectFieldSettings,
  data: null,
  validators: [],
  cols,
  x,
  y,
});

export const buildMultilanguageCategoriesFilterEntityDefinition = (
  cols: number = 12,
  token: InjectionToken<IEntityService>
): IEntityDefinition => ({
  name: 'PkSelectFieldComponent',
  settings: {
    nazev: 'Kategorie',
    povinnost: false,
    formControlName: 'categories',
    isMultiple: true,
    service: {
      token,
      fetchFunction: (service) =>
        service.fetchCategories().pipe(
          map((categories) => {
            // todo potentially create readCategoriesLanguageGroups to get rid of this bs
            // group all categories to dictionary by language group id
            const groupedCategories = categories.reduce((acc, curr) => {
              const { languageGroupId, _id, name, language } = curr;
              if (!acc[languageGroupId]) {
                acc[languageGroupId] = [{ _id, name, language }];
              } else {
                acc[languageGroupId].push({ _id, name, language });
              }
              return acc;
            }, {} as Record<string, { _id: string; name: string; language: string }[]>);

            // transform it to [{name: "Something", _id: ["1", "2',]}]
            return Object.entries(groupedCategories).map(
              ([languageGroupId, v]) => {
                return {
                  name: (v.find((x) => x.language === 'cs') ?? v[0])?.name,
                  _id: languageGroupId,
                  ids: v.map(({ _id }) => _id),
                };
              }
            );
          })
        ),
    },
    displayTransformFn: (item) => item['name'],
  } as ISelectFieldSettings,
  data: null,
  validators: [],
  cols,
});

export const buildPublishableEntityDefinition = (): IEntityDefinition[] => [
  {
    name: 'PkInputDateTimeFieldComponent',
    settings: {
      nazev: 'Datum publikování',
      formControlName: 'publishedAt',
    } as IDateTimeFieldSettings,
    data: null,
    validators: [],
    cols: 6,
  },
  {
    name: 'PkInputDateTimeFieldComponent',
    settings: {
      nazev: 'Konec publikování',
      povinnost: false,
      formControlName: 'unpublishedAt',
    } as IDateTimeFieldSettings,
    data: null,
    validators: [],
    cols: 6,
  },
];

export const createIconDefinition = (
  formControlName: string,
  label: string
): IEntityDefinition => ({
  name: 'PkInputGroupFieldComponent',
  settings: {
    nazev: label,
    formControlName,
    expandable: true,
    defaultExpand: false,
    transformFn: (value) => {
      if (isObject(value)) {
        if (Object.values(value).every((v) => !v)) {
          return undefined;
        }
      }
      return value;
    },
    groupDefinition: [
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'Ikonka',
          formControlName: 'icon',
        } as ITextFieldSettings,
        data: null,
        validators: [],
        cols: 12,
      },
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'Barva',
          formControlName: 'color',
        } as ITextFieldSettings,
        data: null,
        validators: [],
        cols: 12,
      },
      {
        name: 'PkInputTextFieldComponent',
        settings: {
          nazev: 'Barva pozadí',
          formControlName: 'bgColor',
        } as ITextFieldSettings,
        data: null,
        validators: [],
        cols: 12,
      },
    ],
  } as IGroupFieldSettings,
  data: null,
  validators: [],
  cols: 12,
});
export const startIconDefinition = createIconDefinition(
  'startIcon',
  'Počáteční ikonka'
);
export const endIconDefinition = createIconDefinition(
  'endIcon',
  'Koncová ikonka'
);
export const routeToEntityDefinition: IEntityDefinition = {
  name: 'PkInputTextFieldComponent',
  settings: {
    nazev: 'Odkaz',
    formControlName: 'to',
    napoveda: {
      text: 'Interní slug obrazovky nebo externí odkaz.',
    },
  } as ITextFieldSettings,
  data: null,
  validators: [],
  cols: 12,
};

export const mediaDialogSettings: IDataCardDialogSettings = {
  title: 'Média',
  entityServiceData: {
    token: BASE_MEDIUM_SERVICE_TOKEN,
  },
  selectOnItemClick: true,
  viewType: 'grid',
  gridItemDefinition: {
    mediaUrl: (entity: IMedium) => {
      const { type, url, thumbnailUrl } = entity;
      if (type === 'Image') {
        return (
          thumbnailUrl ??
          url ??
          'assets/images/placeholders/jpeg-placeholder.png'
        );
      } else if (type === 'Document') {
        return 'assets/images/placeholders/doc-placeholder.png';
      } else if (type === 'Archive') {
        return 'assets/images/placeholders/zip-placeholder.png';
      } else if (type === 'Video') {
        return 'assets/images/placeholders/video-placeholder.png';
      } else {
        return 'assets/images/placeholders/generic-placeholder.png';
      }
    },
    title: ['name'],
    subtitle: ['description'],
    date: ['createdAt'],
  },
};
