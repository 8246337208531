<button class="flex text-lg items-center"
        [attr.aria-label]="title"
        [ngClass]="[bgColor | prefix:'bg', textColor | prefix:'text', dense ? 'p-0' : 'p-2', outlined ? (secondaryColor | prefix:'border') : '']"
        [class.p-1]="prominent && dense"
        [class.flex-row]="orientation === 'row'"
        [class.flex-col]="orientation === 'col'"
        [class.border-2]="outlined"
        [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
        [class.h-full]="fluid === 'vertical' || fluid === 'both'">

  <!-- START ICON ICON -->
  <div
    *ngIf='startIcon'
    class="fas start icon"
    [class.row]="orientation === 'row'"
    [class.dense]="dense"
    [class.offset]="prominent"
    [class.border-2]="outlined"
    [ngClass]="[startIcon.bgColor | prefix:'bg', outlined ? (startIcon.color | prefix:'border'): '', startIcon.color | prefix:'text']">
    <i [ngClass]="startIcon.icon"  class="fa-fw"></i>
  </div>

  <!-- TEXTS -->
  <div class="flex flex-col items-center mx-4">
    <!-- TITLE -->
    <span
      [ngClass]="[fontSize | prefix: 'text']"
      [class.mt-4]="orientation === 'col'"
      [class.mb-2]="orientation === 'col'"
      class="font-medium font-display">{{title}}</span>

    <!-- DESCRIPTION -->
    <div *ngIf="description" class="text-base font-light">
      {{description}}
    </div>
  </div>

  <!-- END ICON -->
  <div
    *ngIf='endIcon'
    class="fas end icon"
    [class.row]="orientation === 'row'"
    [class.dense]="dense"
    [class.offset]="prominent"
    [class.border-2]="outlined"
    [ngClass]="[endIcon.bgColor | prefix:'bg', outlined ? (endIcon.color | prefix:'border'): '', endIcon.color | prefix:'text']">
    <i [ngClass]="endIcon.icon"  class="fa-fw"></i>
  </div>

</button>
