import {IEntityDefinition} from "../../interfaces";
import {
  IEntityPickerFieldSettings,
  IGroupFieldSettings,
  ISelectFieldSettings, ITextFieldSettings,
} from "../../modules/form-fields";
import {of} from "rxjs";
import {Validators} from "@angular/forms";
import {BASE_MEDIUM_SERVICE_TOKEN} from "@echo-nx/shared/ng/data-access";
import {transformSingularId} from "../form-utils";
import {IMedium} from "@echo-nx/shared/common";


export const getNavbarWidgetEntityDefinition = (): IEntityDefinition[] => {
  return [
    {
      name: 'PkInputGroupFieldComponent',
      settings: {
        nazev: 'Nastavení',
        formControlName: 'settings',
        withoutCard: true,
        groupDefinition: [
          // DATA
          {
            name: 'PkInputGroupFieldComponent',
            settings: {
              nazev: 'Obsah',
              formControlName: 'navbarData',
              groupDefinition: [
                {
                  name: 'PkInputTextFieldComponent',
                  settings: {
                    nazev: 'Slug výsledku vyhledávání',
                    formControlName: 'searchRoute',
                    povinnost: true
                  } as ITextFieldSettings,
                  data: null,
                  validators: [Validators.required],
                  cols: 12,
                },
                ...[
                  ['fullLogo', 'Velké logo'],
                  ['smallLogo', 'Malé logo']
                ].map(([formControlName, nazev]) => ({
                  name: 'EntityPickerFieldComponent',
                  settings: {
                    nazev,
                    formControlName,
                    preview: {
                      style: 'media',
                      keys: {
                        name: ['name'],
                        url: ['url']
                      }
                    },
                    dialogSettings: {
                      title: 'Média',
                      entityServiceData: {
                        token: BASE_MEDIUM_SERVICE_TOKEN
                      },
                      selectOnItemClick: true,
                      viewType: 'grid',
                      gridItemDefinition: {
                        mediaUrl: (entity: IMedium) => {
                          const {type, url, thumbnailUrl} = entity;
                          if (type === 'Image') {
                            return thumbnailUrl ?? url ?? 'assets/images/placeholders/jpeg-placeholder.png';
                          } else if (type === 'Document') {
                            return 'assets/images/placeholders/doc-placeholder.png';
                          } else if (type === 'Archive') {
                            return 'assets/images/placeholders/zip-placeholder.png';
                          } else if (type === 'Video') {
                            return 'assets/images/placeholders/video-placeholder.png';
                          } else {
                            return 'assets/images/placeholders/generic-placeholder.png';
                          }
                        },
                        title: ['name'],
                        subtitle: ['description'],
                        date: ['createdAt'],
                      }
                    }
                  } as IEntityPickerFieldSettings,
                  data: null,
                  validators: [],
                  cols: 6,
                }))
              ]
            } as IGroupFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          },

        ]
      } as IGroupFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    },
  ]
}
