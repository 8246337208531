import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateTimeFieldComponent} from './date-time-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {FlexModule} from "@angular/flex-layout";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {
  DatetimeAdapter,
  MAT_DATETIME_FORMATS,
  MatDatetimepickerModule,
  NativeDatetimeAdapter
} from "@mat-datetimepicker/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {MatDatetimeFormats} from "@mat-datetimepicker/core/adapter/datetime-formats";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    DateTimeFieldComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FlexModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDatetimepickerModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule
    ],
  providers: [
    {
      provide: MAT_DATETIME_FORMATS, useValue: {
        parse: {},
        display: {
          dateInput: {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          },
          monthInput: {
            month: "long"
          },
          datetimeInput: {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
          },
          timeInput: {
            hour: "2-digit",
            minute: "2-digit"
          },
          monthYearLabel: {
            year: "numeric",
            month: "short"
          },
          dateA11yLabel: {
            year: "numeric",
            month: "long",
            day: "numeric"
          },
          monthYearA11yLabel: {
            year: "numeric",
            month: "long"
          },
          popupHeaderDateLabel: {
            weekday: "short",
            month: "short",
            day: "2-digit"
          }
        }
      } as MatDatetimeFormats
    },
    {provide: DatetimeAdapter, useClass: NativeDatetimeAdapter},
  ]
})
export class DateTimeFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputDateTimeFieldComponent', DateTimeFieldComponent);
  }
}
