<echo-nx-card [title]="title">
  <!-- ACTIONS -->
  <echo-nx-data-card-filter actions
                            [hideSearch]="hideSearch"
                            [hideFilters]="hideFilters"
                            [filterDefinition]="filterDefinition"
                            [filterServiceData]="_filterServiceData"></echo-nx-data-card-filter>

  <!-- CONTENT -->
  <div class="flex-1 relative">
    <!-- loading -->
    <echo-nx-feature-cms-loading class="absolute top-0 right-0 left-0 bottom-0 echo-nx-table-loading z-10"
                                 *ngIf="isLoading"></echo-nx-feature-cms-loading>

    <!-- TABLE -->
    <echo-nx-table
      *ngIf="viewType === 'table'"
      [defaultSort]="defaultSort"
      [columnDefinition]="tableItemDefinition"
      [data]="data"
      [canSelectMultiple]="canSelectMultiple"
      [selectionModel]="selection"
      [itemsTotal]="total"
      (rowClicked)="onItemClicked($event)"
      (sortChanged)="onSortChanged($event)"
      (toggledSelect)="onItemSelected($event)"
      (toggledMasterSelect)="onMasterSelected()"
    ></echo-nx-table>

    <!-- LIST -->
    <echo-nx-list
      *ngIf="viewType === 'list'"
    ></echo-nx-list>

    <!-- GRID -->
    <echo-nx-grid
      *ngIf="viewType === 'grid'"
      [gridDefinition]="gridItemDefinition"
      [data]="data"
      [canSelectMultiple]="canSelectMultiple"
      [selectionModel]="selection"
      (itemClicked)="onItemClicked($event)"
      (toggledSelect)="onItemSelected($event)"
    ></echo-nx-grid>
  </div>

  <!-- FOOTER -->
  <div footer class="flex justify-between items-center">
    <!-- DISPLAY TYPE -->
    <div class="flex gap-x-2">
      <ng-container *ngIf="tableViewDefinitionCounter > 1">
        <!-- DISPLAY TABLE -->
        <button
          *ngIf="tableItemDefinition"
          matTooltip="Zobrazit položky v tabulce"
          mat-icon-button
          [color]="viewType === 'table' ? 'primary': null"
          (click)="onViewTypeChanged('table')">
          <mat-icon svgIcon="heroicons_outline:table"></mat-icon>
        </button>

        <!-- DISPLAY LIST -->
        <button
          *ngIf="listItemDefinition"
          matTooltip="Zobrazit položky v seznamu"
          mat-icon-button
          [color]="viewType === 'list' ? 'primary' : null"
          (click)="onViewTypeChanged('list')">
          <mat-icon svgIcon="heroicons_outline:view-list"></mat-icon>
        </button>

        <!-- DISPLAY GRID -->
        <button
          *ngIf="gridItemDefinition"
          matTooltip="Zobrazit položky v mřížce"
          mat-icon-button
          [color]="viewType === 'grid' ? 'primary' : null"
          (click)="onViewTypeChanged('grid')">
          <mat-icon svgIcon="heroicons_outline:view-grid"></mat-icon>
        </button>
      </ng-container>
    </div>

    <!-- PAGINATOR -->
    <mat-paginator
      (page)="onPageChanged($event)"
      [length]="total"
      [pageSizeOptions]="[5, 10, 25, 50]">
    </mat-paginator>
  </div>
</echo-nx-card>
