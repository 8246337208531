import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISearchBarDesign, SearchBarWidgetSettings } from '@echo-nx/shared/common';
import { PrefixModule } from '../../prefix/prefix.module';

@Component({
  selector: 'echo-nx-search-bar-variant-nyrov',
  standalone: true,
  imports: [CommonModule, PrefixModule],
  templateUrl: './search-bar-variant-nyrov.component.html',
  styleUrl: './search-bar-variant-nyrov.component.scss',
})
export class SearchBarVariantNyrovComponent {
  @Input()
  design: ISearchBarDesign;

  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();


  public onSearchSubmit(event: SubmitEvent, text: string) {
    event.preventDefault();
    this.search.next(text);
  }
}
