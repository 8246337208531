import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ItemCardVariantCComponent} from "./item-card-variant-c.component";
import {PrefixModule} from "../../prefix/prefix.module";
import {ResizeObserverModule} from "@echo-nx/shared/ng/feature/responsive";
import {SanitizerModule} from "@echo-nx/shared/ng/feature/common";


@NgModule({
  declarations: [
    ItemCardVariantCComponent
  ],
  imports: [
    CommonModule,
    PrefixModule,
    ResizeObserverModule,
    SanitizerModule
  ],
  exports: [
    ItemCardVariantCComponent
  ]
})
export class ItemCardVariantCModule {
}
