import gql from "graphql-tag";

export const deleteAuth = gql`
  mutation deleteAuth($input: [String!]!, $forceDelete: Boolean){
    response: deleteAuth(ids: $input, forceDelete: $forceDelete){
      description
      error
      userMessage
    }
  }
`;
