<div
  class="flex flex-1 overflow-hidden"
  [class.prominent]="prominent"
  [ngClass]="[
    baseOrientation | prefix: 'base-orientation',
    primaryColor | prefix: 'bg'
  ]"
  #responsive="echoNxResponsive"
  [class.h-full]="fluid === 'vertical' || fluid === 'both'"
  [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
  [echoNxResponsive]="breakpointSettings"
>
  <!-- https://xd.adobe.com/view/47b69b98-5d83-4f1f-b47d-9b338d8bd296-12e5/screen/3fa257ce-55c7-4fee-8ec0-5a2a5876f2af?fullscreen -->

  <!-- IMAGE? -->
  <div class="img-wrapper flex relative" *ngIf="image">
    <img
      class="object-cover"
      [ngClass]="isVertical ? 'flex-1 max-h-64' : 'max-w-sm'"
      [src]="image.url"
      [alt]="image.alt"
    />

    <!-- date -->
    <div
      class="rounded-r-full p-1 px-4 absolute left-0 top-5 font-medium"
      [ngClass]="[
        dateText?.bgColor | prefix: 'bg',
        dateText?.color | prefix: 'text'
      ]"
    >
      {{ date }}
    </div>
  </div>

  <div class="flex flex-col flex-1">
    <div class="mt-4 flex flex-row" *ngIf="categories?.length || footerText">
      <div *ngIf="!image;else catz" class="p-1 px-4 font-medium rounded-r-full"
           [ngClass]="[dateText?.bgColor | prefix:'bg', dateText?.color | prefix:'text']">{{date}}</div>
      <div [ngClass]="footerText?.bgColor | prefix: 'bg'" class="flex ml-auto px-4 gap-x-2 items-center" *ngIf="footer">
        <i [ngClass]="[footerText?.icon, footerText?.color | prefix: 'text']"></i>
        <span class="font font-medium text-sm underline">{{footer}}</span>
      </div>
    </div>

    <!-- TOP HEADER -->
    <div class="mt-8" *ngIf="!image">
      <ng-container *ngTemplateOutlet="catz"></ng-container>
    </div>

    <div class="mt-4 mx-4">
      <!-- title -->
      <span class="text-2xl font-medium pb-0">{{ title }}</span>

      <!-- ATTRIBUTES -->
      <div
        [ngClass]="[baseOrientation ? ('flex-' + baseOrientation) : '']"
        class="mt-1 flex flex-wrap gap-x-4 gap-y-1" *ngIf="attributes">
        <div class="flex gap-x-1 items-center font-base text-sm"
             [ngClass]="[secondaryColor | prefix:'text']"
             *ngFor="let attribute of attributes">
          <i *ngIf="attribute.startIcon" [ngClass]="attribute.startIcon"></i>
          <span>{{ attribute.text }}</span>
          <i *ngIf="attribute.endIcon" [ngClass]="attribute.endIcon"></i>
        </div>
      </div>

      <!-- perex -->
      <div class="mt-2 mb-4 font-base line-clamp-3" [innerHTML]="perex"></div>
    </div>
  </div>
</div>

<ng-template #catz>
  <div class="flex flex-wrap flex-1 mx-4 gap-2">
    <div
      class="px-4 py-1 flex items-center justify-center rounded-full border"
      [ngClass]="[cat.color | prefix: 'border']"
      *ngFor="let cat of categories"
    >
      <span class="font-semibold text-base">{{ cat.name }}</span>
    </div>
  </div>
</ng-template>
