<ng-container
  *ngIf="
    orientation === 'row' || orientation === 'row-reverse';
    else colTemplate
  "
>
  <!-- rounded-tr-3xl keep this here pls-->
  <button
    class="flex items-stretch justify-center overflow-hidden tracking-wider"
    [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
    [class.h-full]="fluid === 'vertical' || fluid === 'both'"
    [ngClass]="[
      outlined ? 'border-2' : 'border-0',
      prominent
        ? 'rounded-tl-3xl rounded-tr-sm rounded-br-3xl rounded-bl-sm'
        : 'rounded-full',
      bgColor | prefix: 'bg',
      accentColor | prefix: 'border',
      fontSize | prefix: 'text',
      panelClass ?? ''
    ]"
  >
    <!-- START ICON -->
    <ng-container *ngIf="startIcon">
      <div
        class="flex items-center justify-center pr-1 p-2"
        [ngClass]="[
          prominent
            ? 'rounded-tl-3xl rounded-tr-sm rounded-br-3xl rounded-bl-sm'
            : '',
          startIcon.bgColor | prefix: 'bg',
          startIcon.color | prefix: 'text',
          dense ? 'py-2 px-4' : 'py-4 px-6',
          fontSize | prefix: 'text'
        ]"
      >
        <i [ngClass]="startIcon.icon" class="fa-fw"></i>
        <div
          *ngIf="outlined || !prominent"
          [ngClass]="[
            accentColor | prefix: 'border',
            accentColor ? 'border-l-4' : 'border-0',
            dense ? 'ml-3' : 'ml-6'
          ]"
          class="rounded-full my-2 h-full w-px"
        ></div>
      </div>
    </ng-container>

    <!-- TEXT -->
    <div
      class="flex-1 flex flex-col items-center justify-center"
      [ngClass]="[dense ? 'px-3' : 'px-6']"
    >
      <div [ngClass]="[textColor | prefix: 'text']" class="font-semibold">
        {{ title }}
      </div>

      <div
        *ngIf="description"
        class="font-light text-xs"
        [ngClass]="[
          descriptionColorful?.color | prefix: 'text',
          descriptionColorful?.bgColor | prefix: 'bg'
        ]"
      >
        {{ description }}
      </div>
    </div>
    <!-- END ICON -->
    <ng-container *ngIf="endIcon">
      <div
        *ngIf="endIcon"
        class="flex flex-row items-center justify-center pl-1 p-2"
        [ngClass]="[
          prominent
            ? 'rounded-tl-3xl rounded-tr-sm rounded-br-3xl rounded-bl-sm'
            : '',
          endIcon.bgColor | prefix: 'bg',
          endIcon.color | prefix: 'text',
          dense ? 'py-2 px-4' : 'py-4 px-6',
          fontSize | prefix: 'text'
        ]"
      >
        <div
          *ngIf="outlined || !prominent"
          [ngClass]="[
            accentColor | prefix: 'border',
            accentColor ? 'border-l-4' : 'border-0',
            dense ? 'mr-3' : 'mr-6'
          ]"
          class="rounded-full my-2 h-full w-px"
        ></div>
        <i [ngClass]="endIcon.icon" class="fa-fw"></i>
      </div>
    </ng-container>
  </button>
</ng-container>

<ng-template #colTemplate>
  <button
    class="flex flex-col justify-center overflow-hidden items-stretch border-collapse"
    [ngClass]="[
      outlined ? 'border-2' : 'border-0',
      accentColor | prefix: 'border',
      bgColor | prefix: 'bg',
      textColor | prefix: 'text',
      panelClass ?? '',
      fontSize | prefix: 'text',
      prominent
        ? 'rounded-tl-3xl rounded-tr-lg rounded-br-3xl rounded-bl-lg'
        : 'rounded-xl'
    ]"
    [class.h-full]="fluid === 'vertical' || fluid === 'both'"
    [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
  >
    <!-- START ICON -->
    <div
      *ngIf="startIcon"
      class="flex items-center justify-center"
      [class.w-full]="!!description"
      [ngClass]="[
        outlined ? 'border-b-2' : 'border-0',
        accentColor | prefix: 'border',
        startIcon.bgColor | prefix: 'bg',
        startIcon.color | prefix: 'text',
        (dense ? '1' : '4') | prefix: 'p',
        fontSize | prefix: 'text'
      ]"
    >
      <i [ngClass]="startIcon.icon" class="fa-fw"></i>
    </div>

    <!-- TEXT -->
    <div
      class="flex flex-col flex-1 items-center"
      [ngClass]="[dense ? 'p-1' : 'px-4 py-6', dense ? 'gap-2' : 'gap-4']"
    >
      <span
        [ngClass]="[textColor | prefix: 'text']"
        class="text-center font-medium"
        >{{ title }}</span
      >
      <ng-container *ngIf="description">
        <div
          [ngClass]="[accentColor | prefix: 'bg']"
          class="h-0.5 w-60 mx-8"
        ></div>
        <span
          class="font-light text-center text-sm"
          [ngClass]="[
            descriptionColorful?.color | prefix: 'text',
            descriptionColorful?.bgColor | prefix: 'bg'
          ]"
        >
          {{ description }}
        </span>
      </ng-container>
    </div>

    <!-- END ICON -->
    <div
      *ngIf="endIcon"
      class="flex items-center justify-center p-4 border-collapse"
      [ngClass]="[
        outlined ? 'border-t-2' : 'border-0',
        accentColor | prefix: 'border',
        endIcon.bgColor | prefix: 'bg',
        endIcon.color | prefix: 'text',
        (dense ? '1' : '4') | prefix: 'p',
        fontSize | prefix: 'text'
      ]"
    >
      <i [ngClass]="endIcon.icon" class="fa-fw"></i>
    </div>
  </button>
</ng-template>
