import {Pipe, PipeTransform} from '@angular/core';
import {DateTime, DurationUnit} from 'luxon';

@Pipe({
  name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {

  transform(date1: Date, date2: Date, unit: DurationUnit = 'minutes', absolute = false): number {
    const d1 = DateTime.fromJSDate(date1);
    const result = Math.ceil(d1.diff(DateTime.fromJSDate(date2), unit).toMillis());
    return absolute ? Math.abs(result) : result;
  }

}
