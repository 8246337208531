import { gql } from 'apollo-angular';

//todo tohle by melo byt genericke
export const createSimpleScreen = gql`
  mutation addSimpleScreen($screen: JSONObject!) {
    response: addSimpleScreen(screen: $screen) {
      _id
      slug
      name
    }
  }
`;
