import {IMarlenkaAppEnvironment} from "./i-marlenka-app-environment";


export const environment = {
  production: true,
  gTagId: 'GTM-WR4L297',
  apiSettings: {
    protocol: 'https',
    apiUrl: 'marlenka.echopix.cz/api',
    graphqlSuffix: '/graphql',
  }
} as IMarlenkaAppEnvironment;
