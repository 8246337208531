<div class="flex flex-col flex-1 w-full">
  <div class="relative flex-col flex-1 w-full flex">
    <!--IMAGE SLIDER-->
    <div class="w-full h-full object-cover relative">
      <div
        [@sliderAnimator]="{
          value: showFirst ? animationStyle : 'none',
          params: {
            time: transitionDurationInMilis + 'ms',
            distanceTo: distanceToFirst,
            distanceFrom: distanceFromFirst
          }
        }"
        (@sliderAnimator.done)="animationDone()"
      >
        <img
          [src]="data[firstIdx].imgUrl"
          [alt]="data[firstIdx].alt"
          [ngClass]="[imageFit | prefix: 'object']"
          class="absolute inset-0 w-full h-full"
        />
      </div>
      <div
        [@sliderAnimator]="{
          value: showSecond ? animationStyle : 'none',
          params: {
            time: transitionDurationInMilis + 'ms',
            distanceTo: distanceToSecond,
            distanceFrom: distanceFromSecond
          }
        }"
      >
        <img
          [src]="data[secondIdx].imgUrl"
          [alt]="data[secondIdx].alt"
          [ngClass]="[imageFit | prefix: 'object']"
          class="absolute inset-0 w-full h-full"
        />
      </div>
      <div class="absolute inset-0 w-full h-full overflow-hidden">
        <!-- BACKGROUND -->
        <div
          class="absolute w-full h-full inset-0 opacity-75"
          [ngClass]="[primaryColor | prefix: 'bg']"
        ></div>
        <!-- FOREGROUND -->
        <div
          class="absolute inset-0 container mx-auto flex flex-1 flex-col items-center justify-center p-4"
        >
          <!--TEXTS-->
          <div
            class="flex flex-col items-center"
            [ngClass]="[textColor | prefix: 'text']"
          >
            <!--TITLE-->
            <div class="text-3xl text-center leading-8 font-display">
              {{ data[currentIdx$.value].title }}
            </div>
            <div class="text-md text-center m-2">
              {{ data[currentIdx$.value].description }}
            </div>
          </div>
        </div>
        <!--INDICATORS-->
        <div *ngIf="!hideIndicators" class="absolute left-0 right-0 bottom-4">
          <div class="flex flex-1 flex-row mx-auto justify-center">
            <button *ngIf="!hideIndicatorButtons">
              <i
                class="fas fa-arrow-left p-3"
                [ngClass]="[textColor | prefix: 'text']"
                (click)="previousImage()"
              ></i>
            </button>

            <div
              class="flex flex-row my-4 gap-2 justify-center"
              *ngIf="sliderIndexes.length > 1"
            >
              <ng-container *ngIf="sliderIndexes.length < 5">
                <div
                  *ngFor="let i of sliderIndexes"
                  [ngClass]="
                    i === currentIdx$.value
                      ? [textColor | prefix: 'bg']
                      : [secondaryColor | prefix: 'bg']
                  "
                  class="h-2 w-2 rounded-full ring-offset-2 ring-1"
                ></div>
              </ng-container>
              <ng-container *ngIf="sliderIndexes.length >= 5">
                <div
                  class="text-center"
                  [ngClass]="[textColor | prefix: 'text']"
                >
                  {{ currentIdx$.value + 1 }} z {{ sliderIndexes.length }}
                </div>
              </ng-container>
            </div>
            <button *ngIf="!hideIndicatorButtons">
              <i
                class="fas fa-arrow-right p-3"
                [ngClass]="[textColor | prefix: 'text']"
                (click)="nextImage()"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
