import { Inject, Injectable } from '@angular/core';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions } from '@apollo/client';
import {
  URL_SETTINGS_TOKEN,
  UrlSettings,
} from '@echo-nx/shared/ng/feature/common';
import { InMemoryCache } from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';
import { SsrAuthService } from './ssr-auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApolloOptionsService {
  private apolloOptions!: ApolloClientOptions<any>;

  constructor(
    private httpLink: HttpLink,
    private authService: SsrAuthService,
    @Inject(URL_SETTINGS_TOKEN) private urlSettings: UrlSettings
  ) {}

  public async createApolloOptions() {
    //const graphqlUri = `${apiSettings.protocol}://${apiSettings.apiUrl}${apiSettings?.graphqlSuffix ?? ''}`;
    const { fullGraphqlPath } = this.urlSettings;

    const { data } = await this.authService.login();
    const token = data?.token;

    //console.log("creating default link with token", token);
    this.apolloOptions = {
      cache: new InMemoryCache({
        addTypename: false,
        // better persistence https://github.com/apollographql/apollo-cache-persist
      }),
      link: this.httpLink.create({
        uri: fullGraphqlPath,
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
        }),
      }),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
        },
      },
    };
    return this.apolloOptions;
  }

  public getApolloOptions() {
    return this.apolloOptions;
  }
}
