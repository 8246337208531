import {
  Component,
  forwardRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {IEntityDefinition} from "../../../interfaces";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {ITextFieldSettings} from "./i-text-field.settings";
import { FormGroupDirective} from "@angular/forms";
import {filter, pairwise, startWith, takeUntil} from "rxjs/operators";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'echo-nx-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
})
export class TextFieldComponent extends BaseFormFieldComponent<ITextFieldSettings> implements OnInit, OnDestroy {
  public isHidden = true;
  public isPassword = false;
  public firstError?: string;

  @ViewChild(MatInput)
  public inputElement!: MatInput;


  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<ITextFieldSettings>, formGroupDirective: FormGroupDirective,injector: Injector) {
    super(def,formGroupDirective, injector);
  }


  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.isPassword = this.settings.type === 'password';

    this.watchNumericFromControlsAndCastValue();

    if (this.settings.isJson) {
      const {value} = this.formControl;
      const uglyValue = JSON.parse(value);
      const prettyValue = JSON.stringify(uglyValue, undefined,4);
      this.formControl.patchValue(prettyValue)
    }
  }

  override async ngOnDestroy(): Promise<void> {
    await super.ngOnDestroy();
  }

  public genPassword() {
    const random = (Math.random() + 1).toString(36).substr(2, 5) + (Math.random() + 1).toString(36).substr(2, 5);
    this.formControl.patchValue(random);
  }

  private hideText() {
    this.isHidden = !this.isHidden;
    this.settings.type = this.isHidden ? 'password' : 'text';
  }

  private watchNumericFromControlsAndCastValue() {
    // hook up to each of them and cast the value from string to number
    if (this.settings.type === 'number') {
      this.formControl.valueChanges.pipe(
        startWith([null]),
        takeUntil(this.isDestroyed$),
        pairwise(),
        filter(([prev, curr]) => Number(prev) !== curr) //prev is either null (startwith) or string (from the control). if you use just distinct() it works but procs twice for no reason
      ).subscribe(([_, value]) => {
        this.formControl.setValue(Number(value));
      });
    }
  }

  override focusField() {
    super.focusField();
    this.inputElement.focus({preventScroll: true})
  }


}
