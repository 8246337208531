<echo-nx-map-view
  [settings]="mapViewOptions"
  [data]="mapViewOptions.data"
  (boundsChanged)="boundsChanged.emit($event)"
  (pointSelected)="pointSelected($event)"
>
  <ng-container [ngSwitch]="mapInfoOptions.variant">
    <echo-nx-map-info-theme-rsd
      [settings]="mapInfoOptions.data"
      (detailClicked)="infoWindowClicked.emit($event)"
      *ngSwitchCase="'variant-rsd'"
    ></echo-nx-map-info-theme-rsd>

    <echo-nx-map-info-map-info-variant-a
      [settings]="mapInfoOptions.data"
      (detailClicked)="infoWindowClicked.emit($event)"
      *ngSwitchDefault
    ></echo-nx-map-info-map-info-variant-a>
  </ng-container>
</echo-nx-map-view>
