import { Component, Input } from '@angular/core';

@Component({
  selector: 'marlenka-wysiwyg-view',
  templateUrl: './marlenka-wysiwyg-view.component.html',
  styleUrls: ['./marlenka-wysiwyg-view.component.scss'],
})
export class MarlenkaWysiwygViewComponent {
  @Input()
  html!: string;
}
