<mat-form-field
  appearance="outline"
  class="relative"
  fxFlex
  [floatLabel]="hasValue ? 'always' : 'auto'"
  [formGroup]="formGroup"
  (click)="openEntityPicker()"
>
  <mat-label>{{ settings.nazev }}</mat-label>

  <!-- obsah pole / preview -->
  <ng-container *ngIf="hasValue">
    <div *ngIf="settings.displayTransformFn; else noDisplayFn">
      {{settings.displayTransformFn | functionPipe:formControl.value}}
    </div>
    <ng-template #noDisplayFn>
      <div *ngIf="settings.dialogSettings.isMultiple">
        Počet vybraných položek: {{formControl.value?.length}}
      </div>
      <div *ngIf="!settings.dialogSettings.isMultiple">
        {{formControl.value['name'] ?? "Vybrána 1 položka"}}
      </div>
    </ng-template>
  </ng-container>


  <input
    readonly
    hidden
    matInput
    [style]="hasValue ? 'display:none' : 'display:block'"
    [required]="settings.povinnost"
    [formControlName]="settings.formControlName"/>


  <!-- HINTS -->
  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info
  </mat-icon>

  <!-- REMOVE ICON -->
  <button *ngIf="hasValue" [disabled]="settings.disableRemoveAll" matSuffix mat-icon-button (click)="removeAllEntities($event)">
    <mat-icon matTooltip="Odstranit všechny položky">close</mat-icon>
  </button>

  <!-- ERROR -->
  <mat-error *ngIf="firstErrorKey$ | async as firstErrorKey" >
    {{firstErrorKey.key | translate: firstErrorKey.params}}
  </mat-error>
</mat-form-field>

<!-- PREVIEW -->
<div class="preview" *ngIf="settings.preview">
  <ng-container [ngSwitch]="settings.preview.style">
    <!-- media -->
    <ng-container *ngSwitchCase="'media'">
      <!--TODO REFACTOR THIS-->
      <div class="mt-4 media-wrapper bg-gray-800 rounded-md" *ngFor="let media of (formControl.value | isArray) ? formControl.value : formControl.value ? [formControl.value] : []">
        <ng-container
          *ngIf="(settings.preview.keys.url | isArray) ? (media | resolveKey:settings.preview.keys.url) : (settings.preview.keys.url | functionPipe:media) as imgSrc">
          <img *ngIf="imgSrc" [src]="imgSrc"/>
          <div *ngIf="!imgSrc"
               class="media-text">{{(settings.preview.keys.name | isArray) ? (media | resolveKey:settings.preview.keys.name) : (settings.preview.keys.name | functionPipe:media)}}</div>
        </ng-container>
        <button class="media-delete" mat-icon-button (click)="removeEntity(media)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </ng-container>
    <!-- chips -->
    <ng-container *ngSwitchCase="'chips'">
      <mat-chip-grid
        style="grid-column-end: 5; grid-column-start: 1">
        <mat-chip *ngFor="let item of (formControl.value | isArray) ? formControl.value : formControl.value ? [formControl.value] : []" (removed)="removeEntity(item)">
          {{(settings.preview.keys.name | isArray) ? (item | resolveKey:settings.preview.keys.name) : (settings.preview.keys.name | functionPipe:item)}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-grid>
    </ng-container>
    <!-- portal -->
    <ng-container *ngSwitchCase="'portal'">
      <span *ngFor="let item of (formControl.value | isArray) ? formControl.value : [formControl.value]">
        {{item | json}}
        <!--<ng-template
          [cdkPortalOutlet]="{...item, settings.preview.portalSettings.name} | dynamicComponent"
        >-->
      </span>
    </ng-container>
  </ng-container>

</div>

