<div class="flex flex-col flex-1 w-full">
  <div class="relative flex-col flex-1 w-full flex">
    <div
      class="absolute inset-0 z-10 flex flex-row flex-1 items-center justify-between"
      *ngIf="
        !hideIndicators && !hideIndicatorButtons && sliderIndexes.length > 1
      "
    >
      <button>
        <i
          class="fas fa-arrow-left p-3"
          [ngClass]="[
            primaryColor | prefix: 'bg',
            onPrimaryColor | prefix: 'text'
          ]"
          (click)="previousImage()"
        ></i>
      </button>
      <span class="opacity-40">
        <button
          class="fas p-3"
          [ngClass]="
            isTimerPaused
              ? [
                  'fa-play',
                  primaryColor | prefix: 'bg',
                  onPrimaryColor | prefix: 'text'
                ]
              : [
                  'fa-pause',
                  primaryColor | prefix: 'bg',
                  onPrimaryColor | prefix: 'text'
                ]
          "
          (click)="toggleTimer()"
        ></button>
      </span>
      <button>
        <i
          class="fas fa-arrow-right p-3"
          [ngClass]="[
            primaryColor | prefix: 'bg',
            onPrimaryColor | prefix: 'text'
          ]"
          (click)="nextImage()"
        ></i>
      </button>
    </div>

    <div class="relative w-full h-full overflow-hidden">
      <div
        [@sliderAnimator]="{
          value: showFirst ? animationStyle : 'none',
          params: {
            time: transitionDurationInMilis + 'ms',
            distanceTo: distanceToFirst,
            distanceFrom: distanceFromFirst
          }
        }"
        (@sliderAnimator.done)="animationDone()"
        class="absolute inset-0"
      >
        <ng-container
          [ngTemplateOutlet]="
            data[firstIdx]?.imgUrl ? imageWrapper : textWrapper
          "
          [ngTemplateOutletContext]="{ data: data[firstIdx] }"
        ></ng-container>
      </div>
      <div
        [@sliderAnimator]="{
          value: showSecond ? animationStyle : 'none',
          params: {
            time: transitionDurationInMilis + 'ms',
            distanceTo: distanceToSecond,
            distanceFrom: distanceFromSecond
          }
        }"
        class="absolute inset-0"
      >
        <ng-container
          [ngTemplateOutlet]="
            data[secondIdx]?.imgUrl ? imageWrapper : textWrapper
          "
          [ngTemplateOutletContext]="{ data: data[secondIdx] }"
        ></ng-container>
      </div>
    </div>

    <!--Current index indicator-->
    <div
      class="flex flex-row flex-1 inset-x-0 bottom-0 mb-4 mt-8 gap-x-2 justify-center max-w-full"
      *ngIf="!hideIndicators && sliderIndexes.length > 1"
    >
      <ng-container *ngIf="sliderIndexes.length < 20">
        <div
          *ngFor="let i of sliderIndexes"
          [ngClass]="
            i === currentIdx$.value
              ? [primaryColor | prefix: 'bg']
              : [secondaryColor | prefix: 'bg']
          "
          class="h-2 w-2"
        ></div>
      </ng-container>
      <ng-container *ngIf="sliderIndexes.length >= 20">
        <div class="text-center" [ngClass]="[primaryColor | prefix: 'text']">
          {{ currentIdx$.value + 1 }} z {{ sliderIndexes.length }}
        </div>
      </ng-container>
    </div>
  </div>

  <!--Progress BAR-->
  <div class="relative h-1" *ngIf="!hideIndicators && sliderIndexes.length > 1">
    <div
      class="absolute inset-0 overflow-hidden opacity-20"
      [ngClass]="[primaryColor | prefix: 'bg', onPrimaryColor | prefix: 'text']"
    ></div>
    <div class="overflow-hidden h-1 flex">
      <div
        [style.width.%]="progressBarValue"
        [ngClass]="[
          primaryColor | prefix: 'bg',
          onPrimaryColor | prefix: 'text'
        ]"
        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center transition-all duration-100 ease-in-out"
      ></div>
    </div>
  </div>
  <!--  <div class="flex flex-row inset-x-0 bottom-0 mt-2 gap-x-2 justify-center">
      <button [class.bg-primary-500]="animationStyle==='fade'" (click)="changeAnimation('fade')">Fade</button>
      <button [class.bg-primary-500]="animationStyle==='slide'" (click)="changeAnimation('slide')">Slide</button>
      <button [class.bg-primary-500]="animationStyle==='scale'" (click)="changeAnimation('scale')">Scale</button>
    </div>-->
</div>

<ng-template #imageWrapper let-data="data">
  <img
    [src]="data.imgUrl"
    [alt]="data.alt"
    [ngClass]="[imageFit | prefix: 'object']"
    class="img-absolute filter drop-shadow-xl"
  />
</ng-template>

<ng-template #textWrapper let-data="data">
  <div class="h-full flex flex-row justify-center items-center">
    <div
      class="text-wrapper border flex flex-col"
      [ngClass]="[primaryColor | prefix: 'border']"
    >
      <div
        [ngClass]="[
          primaryColor | prefix: 'bg',
          onPrimaryColor | prefix: 'text'
        ]"
        class="text-2xl p-2"
      >
        {{ data?.title }}
      </div>
      <div [ngClass]="[primaryColor | prefix: 'text']" class="mb-2 px-2">
        {{ data?.subtitle }}
      </div>
      <div class="px-2">{{ data?.description }}</div>
    </div>
  </div>
</ng-template>
