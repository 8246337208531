<div class="flex flex-col flex-1 w-full">
  <div class="relative flex-col flex-1 w-full flex">
    <!-- sm:-mt-20 -->

    <!--IMAGE SLIDER-->
    <div class="w-full h-full object-cover relative">
      <div
        [@sliderAnimator]="{
          value: showFirst ? animationStyle : 'none',
          params: {
            time: transitionDurationInMilis + 'ms',
            distanceTo: distanceToFirst,
            distanceFrom: distanceFromFirst
          }
        }"
        (@sliderAnimator.done)="animationDone()"
      >
        <div
          [ngStyle]="{
            'background-position': 'center',
            'background-size': 'cover',
            'background-image': 'url(' + data[firstIdx].imgUrl + ')'
          }"
          class="absolute inset-0 w-full h-full"
        ></div>
      </div>
      <div
        [@sliderAnimator]="{
          value: showSecond ? animationStyle : 'none',
          params: {
            time: transitionDurationInMilis + 'ms',
            distanceTo: distanceToSecond,
            distanceFrom: distanceFromSecond
          }
        }"
      >
        <div
          [ngStyle]="{
            'background-position': 'center',
            'background-size': 'cover',
            'background-image': 'url(' + data[secondIdx].imgUrl + ')'
          }"
          class="absolute inset-0 w-full h-full"
        ></div>
      </div>
    </div>

    <!-- CONTROLS (currIdx + 1 because 0 == false => nothing is shown) -->
    <div
      *ngIf="(currentIdx$ | async) + 1; let currentIdx"
      class="absolute h-full"
    >
      <div
        class="slider-controls-bg absolute bg-primary-500 w-full h-full z-0"
      ></div>
      <div
        class="slider-controls relative flex flex-col justify-center gap-2 p-3 h-full z-10"
      >
        <button
          *ngFor="let _ of sliderIndexes; index as idx"
          (click)="selectImage(idx)"
        >
          <div
            class="p-2 rounded-full"
            [ngClass]="
              idx === currentIdx - 1 ? 'bg-secondary-100' : 'bg-primary-500'
            "
          ></div>
        </button>
      </div>
    </div>
  </div>
</div>
