import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteFieldComponent } from './autocomplete-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {FlexModule} from "@angular/flex-layout";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";



@NgModule({
  declarations: [
    AutocompleteFieldComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FlexModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatIconModule
  ]
})
export class AutocompleteFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputAutocompleteComponent', AutocompleteFieldComponent);
  }
}
