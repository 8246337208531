import gql from 'graphql-tag';

export const readSelectedContact = gql`
  query ReadSelectedContact($ids: [String!]!) {
    response: readSelectedContact(ids: $ids) {
      _id
      name
      title
      firstName
      lastName
      birthday
      birthnumber
      telephones
      emails
      profession
      ico
      dic
      dataBox
      websites
      companyName
      tradeRegisterText
      bankInfo {
        bankCode
        bankName
        bankNumber
        iban
        swift
      }
      priority
      categories {
        _id
        name
        icon
        color
      }
      avatar {
        _id
        url
        thumbnailUrl
        type
        name
      }
      owner {
        _id
        name
      }
      address {
        city
        province
        state
        street
        zipcode
      }
      createdAt
    }
  }
`;
