import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {map, startWith, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ISO_3166_N_3} from '../../../utils/iso3166';
import {Observable} from 'rxjs';
import {ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {AresICO} from "@echo-nx/shared/common";
import {createCustomSnackbar} from "@echo-nx/shared/ng/feature/snackbar";
import {EchoNxValidator} from "@echo-nx/shared/ng/feature/entity-management";
import {ValidateDIC, ValidateEmail} from "./custom-validators";


@Component({
  selector: 'marlenka-contact-step',
  templateUrl: './contact-step.component.html',
  styleUrls: ['./contact-step.component.scss']
})
export class ContactStepComponent implements OnInit, OnDestroy {
  private readonly isDestroyed$ = new Subject<boolean>();
  public contactForm!: UntypedFormGroup;
  public fetchingAres = false;
  public states: Record<string, string> = ISO_3166_N_3;
  filteredStates?: Observable<any[]>;

  constructor(private fb: UntypedFormBuilder, public reservationService: ReservationService, private httpClient: HttpClient, private snackBar: MatSnackBar) {
  }


  ngOnInit(): void {
    // warn if you cannot go to next step
    this.reservationService.onNextFailed$.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
      createCustomSnackbar('Zkontrolujte správnost všech polí a souhlaste s podmínkami', this.snackBar);
      this.contactForm.updateValueAndValidity();
    });

    // create root form group
    this.contactForm = this.fb.group({
      person: this.fb.group({
        name: this.fb.control('', Validators.required),
        surname: this.fb.control('', Validators.required),
        email: this.fb.control('', [Validators.required, ValidateEmail]),
        tel: this.fb.control('', [Validators.required, EchoNxValidator.validatorForIntPhone()]),
        note: this.fb.control('', []),
        gdpr: this.fb.control(false, Validators.requiredTrue),
        tos: this.fb.control(false, Validators.requiredTrue),
      }),
      isCompany: this.fb.control(false),
      company: this.fb.group({
        ICO: this.fb.control('', [Validators.required, Validators.minLength(8)]),
        DIC: this.fb.control('', ValidateDIC),
        name: this.fb.control('', Validators.required),
        state: this.fb.control('', Validators.required),
        PSC: this.fb.control('', Validators.required),
        city: this.fb.control('', Validators.required),
        street: this.fb.control('', Validators.required),
        paysTaxes: this.fb.control(false, Validators.required),
      })
    });

    this.filteredStates = this.contactForm.get('company.state')?.valueChanges
      .pipe(
        startWith(''),
        map(name => {
          return name ? this._filter(name) : Object.values(this.states)
        })
      );

    this.contactForm.patchValue(this.reservationService.selectedPerson.formData || {});

    // watch changes to shouldRegister and create/remove password fields
    this.reservationService.shouldRegister$.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(val => {
      if (!val) {
        this.contactForm.get('person.password')?.disable();
        this.contactForm.get('person.confirmPassword')?.disable();
      } else {
        this.contactForm.get('person.password')?.enable();
        this.contactForm.get('person.confirmPassword')?.enable();
      }
    });

    // watch changes to isCompany and create/remove company fields
    this.contactForm.get('isCompany')?.valueChanges
      .pipe(
        takeUntil(this.isDestroyed$),
        startWith(this.contactForm.get('isCompany')?.value as boolean)
      )
      .subscribe(val => {
        if (!val) {
          this.contactForm.get('company')?.disable();
        } else {
          this.contactForm.get('company')?.enable();
        }
      });

    this.contactForm.get('company.paysTaxes')?.valueChanges
      .pipe(
        takeUntil(this.isDestroyed$)
      ).subscribe(val => {
      const dicField = this.contactForm.get('company.DIC')
      dicField?.updateValueAndValidity();

      if (!val) {
        dicField?.disable();
      } else {
        dicField?.enable();
      }
    })

    // subscribe to the form status changes and set values in reservationService
    this.contactForm.statusChanges
      .pipe(
        takeUntil(this.isDestroyed$),
        map(status => status === 'VALID'),
      )
      .subscribe(valid => {
        this.reservationService.selectedPerson = {
          formValid: valid,
          formData: {
            ...this.contactForm.value
          }
        };
      })
  }

  private _filter(name: string): any[] {
    return Object.values(this.states).filter(item => item.toLowerCase().startsWith(name.toLowerCase()));
  }

  public stopPropagationAndOpen(event: Event, link: string) {
    event.stopPropagation();
    event.preventDefault();
    window.open(link);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }

  public async fetchFromAres() {
    const ico = this.contactForm.get('company.ICO')?.value;
    this.fetchingAres = true;

    this.httpClient.get<AresICO>(`/api/ares/${ico}`).toPromise()
      .then(res => {
        const selectedState = Object.entries(this.states).find(([key]) => key === res?.stat);
        const state = selectedState ? selectedState[1] : undefined;

        this.contactForm.patchValue({
          company: {
            DIC: res?.DIC,
            name: res?.nazev,
            street: `${res?.ulice} ${res?.cisloPopisne}`,
            city: res?.mesto,
            PSC: res?.PSC,
            state,
            paysTaxes: !!res?.DIC,
          }
        });
        console.log(this.contactForm.value)
      })
      .catch(err => {
        this.snackBar.open(err.message, 'OK');
      })
      .finally(() => {
        this.fetchingAres = false;
      })
  }

}
