import {SnackbarData, SnackbarType} from "./types";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarComponent} from "./snackbar.component";

export const createSnackbarConfig = (text: string, type: SnackbarType, hideButton: boolean = false) => ({
  data: {
    text,
    type,
    hideButton
  } as SnackbarData,
  panelClass: [type.toLowerCase(), 'custom-snackbar', 'flex-1']
});



export const createCustomSnackbar = (text: string, snackBar: MatSnackBar, type: SnackbarType = 'WARNING') => {
  return snackBar.openFromComponent(SnackbarComponent,{
    data: {text, type},
    panelClass: [type.toLowerCase(),'custom-snackbar'],
    verticalPosition: "top",
    horizontalPosition: "center"
  });
}
