<div
  class="flex flex-col rounded-tl-3xl"
  [ngClass]="[
    contentColorful?.bgColor | prefix: 'bg',
    contentColorful?.color | prefix: 'color'
  ]"
>
  <!-- HEADER -->
  <div
    class="rounded-tl-3xl rounded-br-3xl flex flex-row filter drop-shadow-md"
    [class.p-4]="!!image"
    [ngClass]="[primaryColor | prefix: 'bg']"
  >
    <!-- feature image -->
    <div *ngIf="image" class="relative mr-4">
      <img
        class="max-w-lg max-h-64 rounded-tl-3xl rounded-br-3xl"
        [src]="image.url"
        [alt]="image.alt"
      />
      <div
        class="absolute flex font-medium py-1 px-4 pr-4 rounded-l-full top-4 right-0"
        [ngClass]="[
          dateText?.bgColor | prefix: 'bg',
          dateText?.color | prefix: 'text'
        ]"
      >
        {{ date }}
      </div>
    </div>

    <!-- (date), categories, title, perex -->
    <div class="flex flex-col gap-y-4">
      <!-- date when no image -->
      <div
        *ngIf="!image"
        class="flex flex-shrink font-medium py-1 px-4 pr-4 mx-4 mt-8 ml-0 rounded-r-full self-start"
        [ngClass]="[
          dateText?.bgColor | prefix: 'bg',
          dateText?.color | prefix: 'text'
        ]"
      >
        {{ date }}
      </div>

      <!-- categories, title, perex -->
      <div class="flex flex-col gap-y-4 p-4">
        <!-- categories -->
        <div *ngIf="categories" class="flex flex-row flex-wrap gap-2">
          <div
            class="px-4 py-1 flex items-center justify-center border rounded-full"
            [ngClass]="[cat?.color | prefix: 'border']"
            *ngFor="let cat of categories"
          >
            <span class="font-semibold text-base">{{ cat.name }}</span>
          </div>
        </div>

        <!-- title -->
        <div class="text-2xl font-medium">{{ title }}</div>

        <!-- attributes -->
        <div
          *ngIf="attributes"
          class="flex flex-col gap-y-2 gap-x-0 sm:gap-x-4 sm:gap-y-0 sm:flex-row"
          [ngClass]="[secondaryColor | prefix: 'text']"
        >
          <div
            class="gap-x-2 flex items-center font-base text-sm font-medium"
            *ngFor="let attribute of attributes"
          >
            <i *ngIf="attribute.startIcon" [ngClass]="attribute.startIcon"></i>
            <span>{{ attribute.text }}</span>
            <i *ngIf="attribute.endIcon" [ngClass]="attribute.endIcon"></i>
          </div>
        </div>

        <!-- perex -->
        <div
          *ngIf="perex"
          class="flex-1 prose max-w-none"
          style="color: inherit"
          [innerHTML]="perex"
        ></div>
      </div>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="flex flex-col gap-y-4">
    <!-- MAIN TEXT -->
    <div
      class="prose max-w-none p-4"
      style="color: inherit"
      [innerHtml]="content"
    ></div>

    <!-- IMAGES -->
    <div *ngIf="images" class="m-4 grid gap-4 grid-cols-5">
      <div
        class="relative"
        *ngFor="let image of images"
        (click)="attachmentClick.emit(image._id)"
      >
        <img
          class="rounded-tl-3xl rounded-br-3xl"
          [src]="image.url"
          [alt]="image.description"
        />
      </div>
    </div>

    <!-- DOCUMENTS -->
    <div *ngIf="documents.length > 0" class="flex flex-col m-2 p-4 pt-0">
      <span class="text-2xl font-medium">Přílohy</span>
      <div class="flex flex-wrap gap-2">
        <div
          *ngFor="let doc of documents"
          [ngClass]="[secondaryColor | prefix: 'text']"
          class="rounded-tl-3xl rounded-br-3xl flex flex-row items-center text-xl p-2 cursor-pointer"
          (click)="attachmentClick.emit(doc._id)"
        >
          <i class="fas fa-paperclip px-2"></i>
          <div class="flex flex-1 flex-col">
            <span class="font-medium font-display">{{ doc.name }}</span>
            <span class="font-body text-sm">{{ doc.originalFileName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
