import {IEntityDefinition} from "../../interfaces";
import {
  IGroupFieldSettings,
  ISelectFieldSettings, ITextFieldSettings,
} from "../../modules/form-fields";
import {of} from "rxjs";
import {Validators} from "@angular/forms";


export const getNavigationWidgetEntityDefinition = (): IEntityDefinition[] => {
  return [
    {
      name: 'PkInputGroupFieldComponent',
      settings: {
        nazev: 'Nastavení',
        formControlName: 'settings',
        withoutCard: true,
        groupDefinition: [
          {
            name: 'PkSelectFieldComponent',
            settings: {
              nazev: 'Typ navigace',
              formControlName: 'navigationType',
              transformFn: arg => typeof arg === "string" ? arg : arg?._id,
            } as ISelectFieldSettings,
            data: of([
              {
                displayValue: 'Boční',
                _id: 'hamburger'
              },
              {
                displayValue: 'Hamburger',
                _id: 'side'
              }
            ]),
            validators: [],
            cols: 12,
          },
          {
            name: 'PkInputTextFieldComponent',
            settings: {
              nazev: 'Navigace',
              formControlName: 'subtreeId',
              povinnost: true
            } as ITextFieldSettings,
            data: null,
            validators: [Validators.required],
            cols: 12,
          }
        ]
      } as IGroupFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    },
  ]
}
