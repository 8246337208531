<marlenka-section-header
  *ngIf="reservationService.selectedEvent"
  name="Datum a čas návštěvy"
  class="padded-header"
>
  <div content class="fx-col">
    <div class="fx-row event-summary">
      <mat-icon color="primary">event</mat-icon>
      <span
        >Dne
        <strong>{{
          reservationService.selectedEvent.start | date: 'dd.MM.yyyy'
        }}</strong></span
      >
    </div>

    <div class="fx-row event-summary">
      <mat-icon color="primary">schedule</mat-icon>
      <span
        >v
        <strong>{{
          reservationService.selectedEvent.start | date: 'HH:mm'
        }}</strong></span
      >
    </div>
  </div>
</marlenka-section-header>

<echo-nx-table-item-list
  [fields]="summaryTableFields"
  [items]="summaryItems$ | async"
  [selectable]="false"
  [capitalizeBody]="true"
  [headerBackgroundColor]="null"
  [bodyBackgroundColor]="null"
  [dividerColor]="'#A37D2C'"
>
  <ng-template echoNxTableKey="nazev" let-item>
    <div class="nazev-cell">
      <mat-icon class="red-icon">{{ item.icon }}</mat-icon>
      <div class="texts">
        <span
          >{{ item.name
          }}<ng-container *ngIf="item.type === 'discount-coupon'"
            >(uplatněný voucher)</ng-container
          ></span
        >
        <span class="code" *ngIf="item.type === 'discount-coupon'">{{
          item.uniqueId
        }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template echoNxTableKey="itemCount" let-item>
    <echo-nx-counter
      *ngIf="item.itemType; else coupon"
      [value]="item.unitCount"
      (add)="addItem(item)"
      (remove)="removeItem(item)"
      panelClass="marlenka-counter"
    >
    </echo-nx-counter>
    <ng-template #coupon>
      {{ item.unitCount }}
    </ng-template>
  </ng-template>

  <ng-template echoNxTableKey="delete-cell" let-item>
    <button mat-icon-button (click)="deleteItem(item)">
      <mat-icon class="red-icon">delete_forever</mat-icon>
    </button>
  </ng-template>
</echo-nx-table-item-list>

<div class="glute-free-summary" *ngIf="reservationService.glutenFreeCount > 0">
  <img src="/assets/gf-logo.svg" alt="Gluten Free" />
  <div class="texts">
    <div class="title">Celkový počet bezlepkových degustací v rezervaci</div>
    <div class="divider"></div>
    <div class="count">{{ reservationService.glutenFreeCount }}</div>
  </div>
</div>

<marlenka-step-summary [top]="false">
  <div bottom-row-additional>
    Celková cena za vstupné včetně doplňkových služeb a slev
  </div>
  <div bottom-row-main>
    {{ (reservationService.totals$ | async).total.cost }} Kč
  </div>
</marlenka-step-summary>
