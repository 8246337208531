<div
  class='flex flex-col'
  [ngClass]="[
    contentColorful?.color | prefix: 'color'
  ]"
>
  <div class='flex flex-col space-y-6 justify-center items-center py-4'>
    <!-- title -->
    <div
      class='text-2xl uppercase font-semibold text-center p-4 py-4 text-white w-full'
      [ngClass]="[secondaryColor | prefix: 'bg']"
    >{{ title }}</div>

    <!-- Category, Date -->
    <div class='flex flex-row justify-between w-full'>
      <!-- categories-->
      <div class='flex'>
        <!-- categories -->
        <div *ngIf='categories' class='flex flex-row flex-wrap gap-2'>
          <div
            class='px-4 py-1 flex items-center justify-center rounded-full drop-shadow-md text-white'
            [ngClass]="[
            cat?.color | prefix: 'border',
            cat?.color | prefix: 'bg']"
            *ngFor='let cat of categories'
          >
            <span class='font-semibold text-base'>{{ cat.name }}</span>
          </div>
        </div>
      </div>

      <!-- Date -->
      <div
        class='flex text-lg font-semibold items-center justify-self-center'
      >
        {{ date }}
      </div>
    </div>
  </div>

  <!-- CONTENT -->
  <div class='flex flex-col space-y-4 mt-4'>
    <div class=''>
      <!-- feature image -->
      <div *ngIf='image' class='mr-4'>
        <img
          class='max-w-lg max-h-64 float-left mr-4'
          [src]='image.url'
          [alt]='image.alt'
        />
      </div>

      <!-- MAIN TEXT -->
      <div
        class='max-w-none text-justify'
        style='color: inherit'
        [innerHtml]='content'
      ></div>
    </div>

    <!-- IMAGES -->
    <div *ngIf='images' class='m-4 grid gap-4 grid-cols-5'>
      <div
        class='relative'
        *ngFor='let image of images'
        (click)='attachmentClick.emit(image._id)'
      >
        <img
          [src]='image.url'
          [alt]='image.description'
        />
      </div>
    </div>

    <!-- DOCUMENTS -->
    <div *ngIf='documents.length > 0' class='flex flex-col m-2 p-4 pt-0'>
      <span class='text-lg font-medium p-4'>Počet příloh: {{documents.length}}</span>
      <div
        class='flex flex-wrap gap-2'
        [ngClass]='[primaryColor | prefix: "bg"]'
      >
        <div
          *ngFor='let doc of documents'
          [ngClass]="[secondaryColor | prefix: 'text']"
          class='flex flex-row items-center text-lg p-4 cursor-pointer'
          (click)='attachmentClick.emit(doc._id)'
        >
          <i class='fas fa-paperclip px-2'></i>
          <div class='flex flex-1 flex-col'>
            <span class='font-medium font-display'>{{ doc.name }}</span>
            <span class='font-body text-sm'>{{ doc.originalFileName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
