import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";
import {MarlenkaRouteData} from "@echo-nx/marlenka/common";
import {setAll, convertChoicesToResult} from "../utils";
import {Cookie, CookieConsentManager} from "@echo-nx/shared/ng/feature/cookies";
import {CookiesLangTexts, CookiesTexts} from "../i-cookie-texts";

@Component({
  selector: 'marlenka-app-customize-cookies',
  templateUrl: './customize-cookies.component.html',
  styleUrls: ['./customize-cookies.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomizeCookiesComponent{

  public data!: CookiesTexts;

  constructor(@Inject(MAT_DIALOG_DATA) public langData: CookiesLangTexts,
              public mrdService: LanguageRouteDataService<MarlenkaRouteData>,
              public cookieManager: CookieConsentManager) {

    this.data = this.langData[this.mrdService.currentLanguage];
    console.log("HELO", this.data);
    const cookie = cookieManager.getCookie();
    if(cookie && typeof cookie === 'object'){
      this.formValues = {...cookie, functionality_storage: true} as Cookie;
    } else {
      this.formValues = {...convertChoicesToResult(Object.keys(langData).reduce((acc,key)=> langData[key].customizer.choices,[])), functionality_storage: true};
    }
    console.log(this.formValues);

  }

  public formValues = {};



  allowAll(){
    return setAll(this.formValues, true);
  }

}
