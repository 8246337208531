import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApolloOptionsService } from './apollo-options-service.service';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { BaseAuthService } from '@echo-nx/shared/ng/feature/common';
import { SsrAuthService } from './ssr-auth.service';

export function createApolloOptions(
  apolloOptionsService: ApolloOptionsService
) {
  return async () => apolloOptionsService.createApolloOptions();
}

export function getApolloOptions(apolloOptionsService: ApolloOptionsService) {
  return apolloOptionsService.getApolloOptions();
}

@NgModule({
  imports: [CommonModule],
  providers: [
    //ApolloOptionsService,
    {
      provide: BaseAuthService,
      useExisting: SsrAuthService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: createApolloOptions,
      deps: [ApolloOptionsService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: getApolloOptions,
      deps: [ApolloOptionsService],
    },
  ],
})
export class SharedNgFeatureSsrModule {}
