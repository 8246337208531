import gql from 'graphql-tag';

export const readSelectedGallery = gql`
  query ReadSelectedGallery($ids: [String!]!) {
    response: readSelectedGallery(ids: $ids) {
      _id
      name
      categories {
        _id
        name
      }
      language
      languageGroupId
      description
      slug
      color
      createdAt
      featuredImage {
        _id
        name
        mime
        type
        url
        thumbnailUrl
        alt
      }
      media {
        _id
        name
        mime
        type
        url
        thumbnailUrl
      }
      owner {
        _id
        name
      }
      createdAt
      publishedAt
      unpublishedAt
    }
  }
`;
