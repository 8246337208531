import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderVariantNyrovComponent } from './slider-variant-nyrov.component';
import { PrefixModule } from '../../prefix/prefix.module';


@NgModule({
  declarations: [
    SliderVariantNyrovComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    SliderVariantNyrovComponent,

  ]
})
export class SliderVariantNyrovModule {
}
