import {
  Component,
  ElementRef,
  Injector,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MarlenkaBasePageComponent } from '..';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  GridMenuItem,
  IGridMenuItem,
} from '../../components/menu-grid-banner/menu-grid-banner.component';
import { Router } from '@angular/router';
import {
  ButtonTexts,
  CommonpageTexts,
  ExcursionPageTexts,
  IMarlenkaOwner,
  MarlenkaPageIdentifier,
} from '@echo-nx/marlenka/common';
import { IGallery, IMedium } from '@echo-nx/shared/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'marlenka-excursion-page',
  templateUrl: './excursion-page.component.html',
  styleUrls: ['./excursion-page.component.scss'],
})
export class ExcursionPageComponent extends MarlenkaBasePageComponent<ExcursionPageTexts> {
  public menuBanner: ExcursionMenuBanner;

  public kavarnaPhotos$: Observable<IMedium<any>[]>;
  public prodejnaPhotos$: Observable<IMedium<any>[]>;
  public excursionPhotos$: Observable<IMedium<any>[]>;
  public buttonsText$: Observable<ButtonTexts>;
  private _reservationStepper: ElementRef<HTMLDivElement>;

  @ViewChild('reservationStepper', { static: false })
  set reservationStepper(divElement) {
    if (divElement) {
      this._reservationStepper = divElement;
      this.scrollStepperIntoView();
    }
  }

  constructor(
    injector: Injector,
    private router: Router,
    private thisEl: ElementRef
  ) {
    super(injector);
    this.pageSettings$ = this.pageSettings$.pipe(
      takeUntil(this.isDestroyed$),
      tap(({ texts }) => {
        const { menuBanner } = texts;
        const { main, info, reservation, virtualTour } = menuBanner;
        this.menuBanner = {
          mainItem: new GridMenuItem(
            {
              title: main.title,
              subtitle: main.subtitle,
              foregroundColor: '#F3DCAA',
              //backgroundColor: '#000000',
              backgroundImg:
                (main.backgroundImg as IMedium<IMarlenkaOwner>)?.url ??
                'https://i.imgur.com/j1r734U.jpg',
            },
            this.mrdService
          ),
          bottomItems: [
            new GridMenuItem(
              {
                title: info.title,
                backgroundColor: '#750000',
                foregroundColor: '#F3DCAA',
                backgroundImg:
                  (info.backgroundImg as IMedium<IMarlenkaOwner>)?.url ??
                  'https://i.imgur.com/qWLCCvG.jpg',
                routeTo: info.link,
              },
              this.mrdService
            ),
            new GridMenuItem(
              {
                title: reservation.title,
                backgroundColor: '#F3DCAA',
                foregroundColor: '#750000',
                backgroundImg:
                  (reservation.backgroundImg as IMedium<IMarlenkaOwner>)?.url ??
                  'https://i.imgur.com/IyZJ6nZ.jpg',
                routeTo: reservation.link,
              },
              this.mrdService
            ),
            new GridMenuItem(
              {
                title: virtualTour.title,
                backgroundColor: '#750000',
                foregroundColor: '#F3DCAA',
                backgroundImg:
                  (virtualTour.backgroundImg as IMedium<IMarlenkaOwner>)?.url ??
                  'https://i.imgur.com/cXhC1Bz.jpg',
                routeTo: virtualTour.link,
              },
              this.mrdService
            ),
          ],
        };
      })
    );

    this.excursionPhotos$ = this.pageSettings$.pipe(
      map(({ excursionGallery }) => excursionGallery as IGallery),
      map((gallery) => [gallery?.featuredImage, ...(gallery?.media ?? [])])
    );

    this.kavarnaPhotos$ = this.pageSettings$.pipe(
      map(({ cafeGallery }) => cafeGallery as IGallery),
      map((gallery) => [gallery?.featuredImage, ...(gallery?.media ?? [])])
    );

    this.prodejnaPhotos$ = this.pageSettings$.pipe(
      map(({ storeGallery }) => storeGallery as IGallery),
      map((gallery) => [gallery?.featuredImage, ...(gallery?.media ?? [])])
    );

    this.buttonsText$ = this.mrdService.currentLanguage$.pipe(
      switchMap((language) =>
        this.pageSettingsService.getPageSettingsByIdentifier<CommonpageTexts>(
          MarlenkaPageIdentifier.COMMON,
          language
        )
      ),
      map((pageSettings) => pageSettings.texts.buttons)
    );
  }

  goToReservation() {
    const mains = document.getElementsByName('main');
    if (mains.length > 0) {
      mains[0].scrollTo(0, 0);
    }
    this.router.navigateByUrl(
      '/' + this.mrdService.currentLanguage.toLowerCase() + '/create'
    );
  }

  private scrollStepperIntoView() {
    this._reservationStepper?.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
}

interface ExcursionMenuBanner {
  mainItem: GridMenuItem;
  bottomItems: GridMenuItem[];
}
