import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationListComponent} from "./navigation-list.component";
import {PrefixModule} from "../../prefix/prefix.module";

@NgModule({
  declarations: [NavigationListComponent],
  imports: [CommonModule, PrefixModule],
  exports: [NavigationListComponent]
})
export class NavigationListModule {
}
