import { Component, Injector } from '@angular/core';
import { MarlenkaBasePageComponent } from '..';
import { Observable, switchMap } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  ICompanyContact,
  IContact,
  IMultilanguageObject,
} from '@echo-nx/shared/common';
import { ContactPageTexts, IMDepartment } from '@echo-nx/marlenka/common';
import { BaseDepartmentService } from '@echo-nx/marlenka/ng/data-access';

type ContactAndCompanyContact = IContact & ICompanyContact;

@Component({
  selector: 'marlenka-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
  providers: [BaseDepartmentService],
})
export class ContactPageComponent extends MarlenkaBasePageComponent<ContactPageTexts> {
  public departments$: Observable<IMDepartment<ContactAndCompanyContact[]>[]>;

  constructor(
    injector: Injector,
    private departmentService: BaseDepartmentService
  ) {
    super(injector);
    this.departments$ = this.mrdService.currentLanguage$.pipe(
      takeUntil(this.isDestroyed$),
      switchMap((language) =>
        this.departmentService.fetchAll({
          filter: JSON.stringify({
            language,
          }),
          sortColumn: 'publishedAt',
          sortDirection: 'DESC',
        })
      ),
      map((res) => res.items)
    );
  }
}
