import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarVariantNyrovComponent } from "./calendar-variant-nyrov.component";
import { DateModule, FirstDayOfMonthModule } from '@echo-nx/shared/ng/feature/common';
import { PrefixModule } from '../../prefix/prefix.module';

@NgModule({
  declarations: [
    CalendarVariantNyrovComponent
  ],
  imports: [
    CommonModule,
    DateModule,
    PrefixModule,
    FirstDayOfMonthModule
  ],
  exports: [
    CalendarVariantNyrovComponent
  ]
})
export class CalendarVariantNyrovModule {
}
