import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileFieldComponent } from './file-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {FlexModule} from "@angular/flex-layout";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";



@NgModule({
  declarations: [
    FileFieldComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FlexModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule
  ]
})
export class FileFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputFileFieldComponent', FileFieldComponent);
  }
}
