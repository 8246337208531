<div [ngClass]="[bgColor ? (bgColor | prefix: 'bg') : 'dark:bg-gray-800 bg-white']" class="flex p-2 flex-col rounded-lg border shadow-md border-gray-700">
  <div class="flex flex-1 flex-row justify-between items-center mb-2">
    <i [ngClass]="[primaryColor | prefix:'text']" class="flex text-2xl justify-center items-center p-2 w-12 h-12 rounded-full bg-gray-200 dark:text-gray-400 dark:bg-gray-700 fas fa-bus-alt"></i>
    <div class="flex-col flex text-center">
      <div class="text-lg font-semibold tracking-tight dark:text-white" [ngClass]="[primaryColor | prefix:'text']">{{stationName}}</div>
      <div *ngIf="stationName" class="text-sm tracking-tight text-gray-900 dark:text-gray-300">{{mode === 'Departures' ? 'ODJEZDY' : 'PŘÍJEZDY'}}</div>
    </div>
    <div class="text-lg font-semibold tracking-tight text-gray-900 dark:text-white text-right">{{syncTime}}</div>
  </div>

  <div *ngIf="(table$ | async) as table">
    <div class="flex flex-1 flex-col text-center justify-between items-center">
      <div class="text-primary-50 prose dark:prose-invert flex flex-col p-4 my-0 rounded-lg">
        <table class="w-full">
          <thead class="[bgColor ? (bgColor | prefix: 'bg') : 'dark:bg-gray-700 bg-white']">
          <tr class="table-rows">
            <th>Linka</th>
            <th>Směr</th>
            <th>Odjezd</th>
            <th>Zpoždění</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let row of table">
            <tr [ngClass]="[bgColor ? (bgColor | prefix: 'bg') : 'dark:bg-gray-700 bg-white']">
              <td class="text-left">{{ row.line }}</td>
              <td class="text-left">{{ row.direction }}</td>
              <td class="text-left">{{ row.departure }}</td>
              <ng-container *ngIf="row.delay; else onTimeTpl">
                <td class="text-left text-red-600">{{ row.delay }}</td>
              </ng-container>
              <ng-template #onTimeTpl>
                <td class="text-left">Včas</td>
              </ng-template>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

