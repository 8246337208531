import { WidgetName, WidgetTemplate } from "@echo-nx/shared/common";
import { IEntityDefinition } from "../interfaces";
import {
  getButtonWidgetEntityDefinition,
  getHtmlViewerWidgetEntityDefinition,
  getImageWidgetEntityDefinition, getItemDetailWidgetEntityDefinition,
  getItemListWidgetEntityDefinition,
  getTextWidgetEntityDefinition,
  getNavigationWidgetEntityDefinition, getNavbarWidgetEntityDefinition,
  getSearchBarWidgetEntityDefinition
} from "./widget-settings-entity-definitions";
import { ITextFieldSettings } from "../modules/form-fields";


export const isWidgetTemplate = (template: Record<string, any>): template is WidgetTemplate => {
  return (template as WidgetTemplate).name !== undefined;
};

export const getEntityDefinitionForWidget = (widgetName: WidgetName, ...args: any): IEntityDefinition[] => {
  switch (widgetName) {
    case 'ImageComponent':
      return getImageWidgetEntityDefinition();
    case 'TextComponent':
      return getTextWidgetEntityDefinition();
    case 'ButtonComponent':
      return getButtonWidgetEntityDefinition();
    case 'ItemListComponent':
      return getItemListWidgetEntityDefinition();
    case 'HtmlViewerComponent':
      return getHtmlViewerWidgetEntityDefinition();
    case 'ItemDetailComponent':
      return getItemDetailWidgetEntityDefinition(args);
    case 'NavigationComponent':
      return getNavigationWidgetEntityDefinition();
    case 'NavbarComponent':
      return getNavbarWidgetEntityDefinition();
    case 'SearchBarComponent':
      return getSearchBarWidgetEntityDefinition();
    default:
      return [
        {
          name: 'PkInputTextFieldComponent',
          disabled: true,
          settings: {
            nazev: 'Info',
            formControlName: 'info',
            defaultValue: 'Tento widget nelze editovat.'
          } as ITextFieldSettings,
          data: null,
          validators: [],
          cols: 12,
        },
      ];
  }
}
