import {
  Component,
  Input,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  OnInit,
  InjectionToken,
  Injector,
  OnChanges,
  SimpleChanges,
  ComponentRef,
  ChangeDetectorRef
} from '@angular/core';
import { EchoNxFormErrorMessage, EchoNxFormErrors, IEntityDefinition } from "../../interfaces";
import { collectErrors, createFormWatcher, injectEntityFormServiceData } from "../../utils/form-utils";
import { Observable, Subject, switchMap, tap } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { EntityFormService } from "./entity-form.service";
import { IInjectableEntityFormServiceData, resolveKey } from "@echo-nx/shared/ng/feature/common";
import { UntypedFormGroup } from "@angular/forms";
import { BaseFormFieldComponent, GroupFieldComponent, IBaseFormFieldSettings } from "../form-fields";
import { CdkPortalOutletAttachedRef } from "@angular/cdk/portal";

const ENTITY_FORM_CARD_TOKEN = new InjectionToken('ENTITY_FORM_CARD_TOKEN');

@Component({
  selector: 'echo-nx-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.scss'],
  providers: [
    {
      provide: ENTITY_FORM_CARD_TOKEN,
      useClass: EntityFormService
    }
  ]
})
export class EntityFormComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private isDestroyed$ = new Subject<boolean>();

  public displayedFields!: { [key: string]: Subject<boolean> };

  public entityFormService!: EntityFormService;

  @Input()
  form?: UntypedFormGroup; // in case you provide ur own formgroup. for example data-card-filter

  @Input()
  entityFormServiceData?: IInjectableEntityFormServiceData;

  @Input()
  public entityDefinitions!: IEntityDefinition[];

  public entityDefinitionsWithToken!: IEntityDefinition<IBaseFormFieldSettings>[];

  @Input()
  // public entity$?: Observable<any>;
  entity?: Record<string, any>;

  public errors$!: Observable<EchoNxFormErrors>;

  /**
   * TODO MORE DEHOVNIFY/QOL:
   *  - include entityService in entity-form-cards (modify-entity-card?)
   *    - create two components:
   *      - nx-entity-form-card: with entityService, uses nx-form-card internally
   *      - nx-form-card without entityService, in ng common somewhere
   *
   * TODO:
   *   Multilanguage in service/here
   *   entityService in service/here
   */

  @Input()
  showValidation = true;

  public hasFormPublishedAtField = false;
  public isFormNotPublished?: boolean;

  constructor(
    public element: ElementRef,
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.displayedFields = createFormWatcher(this.entityDefinitions);
    this.hasFormPublishedAtField = this.entityDefinitions.filter(definition => definition.settings.formControlName === 'publishedAt').length > 0;
    this.entityFormService = this.injector.get<EntityFormService>(this.entityFormServiceData?.token ?? ENTITY_FORM_CARD_TOKEN);

    const entityDefinitionsForInit = this.getInitEntityDefinitions();
    this.errors$ = this.entityFormService.onValidation$.pipe(
      tap(v => console.log('on validation', v)),
      switchMap(() => this.entityFormService.form.valueChanges.pipe(startWith({}))),
      map(_ => collectErrors(this.entityFormService.form, entityDefinitionsForInit)),
    );

    if (!this.entityFormService.hasControls) {
      if (this.form) {
        this.entityFormService.form = this.form;
        this.entityFormService.entityDefinitions = entityDefinitionsForInit;
        return;
      }

      // init the form
      this.entityFormService.initEntityForm(entityDefinitionsForInit, this.entity);
      if (this.entity && this.entityFormService.form) {
        (this.entityFormService.form as UntypedFormGroup)?.patchValue(this.entity);
      }
    }

    /**
     * TODO:
     *  - ui/ux concept vs error vs valid,...
     */
  }

  ngOnChanges(changes: SimpleChanges) {
    const { entityDefinitions, entity } = changes;
    if (entityDefinitions) {
      const { currentValue }: { currentValue: IEntityDefinition[] } = entityDefinitions;
      const entityFormServiceData = {
        token: this.entityFormServiceData?.token ?? ENTITY_FORM_CARD_TOKEN,
        getEntityDefinition: this.entityFormServiceData?.token
      }
      this.entityDefinitionsWithToken = injectEntityFormServiceData(currentValue, entityFormServiceData);
    }
    if (entity && this.entityFormService?.form) {
      this.entityFormService.form.patchValue(entity.currentValue);
    }
  }

  public onErrorMessageClick(error: EchoNxFormErrorMessage) {
    const { key } = error;
    if (key) {
      const portal = resolveKey(key, this.entityFormService.componentTree) as ComponentRef<BaseFormFieldComponent>;
      const { instance } = portal;
      instance.focusField();
    }

  }

  public onPortalAttached(formControlName: string, portal: CdkPortalOutletAttachedRef) {
    const { instance } = portal as ComponentRef<BaseFormFieldComponent>;
    if (instance instanceof GroupFieldComponent) {
      return;
    }
    this.entityFormService.componentTree[formControlName] = portal;
  }

  private getInitEntityDefinitions(): IEntityDefinition[] {
    const { getEntityDefinition } = this.entityFormServiceData ?? {};
    if (!getEntityDefinition) {
      return this.entityDefinitions;
    } else if (typeof getEntityDefinition === 'function') {
      // (entity?: any, lang?: string, service?: IEntityService, injector?: Injector) => any /*IEntityDefinition[]
      // todo add language
      // todo add entityService if we decide to use it here??
      return getEntityDefinition(this.entity, undefined, undefined, this.injector);
    } else {
      return getEntityDefinition;
    }
  }

  ngAfterViewInit(): void {
    // watch the form value changes
    this.watchFormForShowFunctionAndPublishedChanges();
  }

  public resetForm() {
    this.entityFormService.form?.reset();
  }

  public validateForm(): boolean | undefined {
    return this.entityFormService.validateForm();
  }

  public getFormData(): Record<string, any> {
    return this.entityFormService.getFormData();
  }

  public isDirty(): boolean | undefined {
    return this.entityFormService.form?.dirty;
  }

  /**
   * This function hooks up on the valueChanges observable of the form and every time a value changes it
   * reevaluates the show functions in order to show, hide, enable or disable fields that are affected by it.
   *
   * Some fields are dependant on other fields, so this is the purpose of the function.
   */
  private watchFormForShowFunctionAndPublishedChanges() {
    this.entityFormService.valueReplay$.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(() => {

      /* PUBLISHED AT*/
      if (this.hasFormPublishedAtField) {
        this.isFormNotPublished = !this.entityFormService.form?.get('publishedAt')?.value;
      }

      // vyfiltrujeme definice, ktere maji showFunction
      const entitiesWithShowFunction = this.entityDefinitions.filter(definition => Object.prototype.hasOwnProperty.call(definition.settings, 'showFunction'));


      // pro kazde z nich vyhodnotime vysledek showFunction
      for (const definition of entitiesWithShowFunction) {
        // spustim funkci, ktera rozhodne, jestli se ma zobrazovat nebo ne
        const shouldShow = definition.settings.showFunction(this.entityFormService.form);

        // emitnu jeji vysledek, at se muze prerenderovat view
        this.displayedFields[definition.settings.formControlName].next(shouldShow);

        // na zaklade vysledku jeste disable/enable control - emitEvent:false je dulezite proti zacykleni
        if (!shouldShow) {
          this.entityFormService.form?.get(definition.settings.formControlName)?.disable({ emitEvent: false });
        } else {
          this.entityFormService.form?.get(definition.settings.formControlName)?.enable({ emitEvent: false });
        }
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
    Object.keys(this.displayedFields).forEach(key => this.displayedFields[key].complete());
  }

  public scrollToErrors() {
    setTimeout(() => {
      document.getElementById('errors')?.scrollIntoView({ behavior: 'smooth' })
    })
  }
}
