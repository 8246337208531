import {
  AfterViewInit,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { IRepeaterFieldSettings } from './i-repeater-field.settings';
import { BaseFormFieldComponent } from '../base-form-field.component';
import { IEntityDefinition } from '../../../interfaces';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  FormArray,
} from '@angular/forms';

import { DYNAMIC_COMPONENT_INPUT } from '@echo-nx/shared/ng/feature/common';
import { buildEntityForm } from '../../../utils/form-utils';
import { CdkPortalOutletAttachedRef } from '@angular/cdk/portal';
import { RepeaterComponentTreeDirective } from './repeater-component-tree.directive';
import { CdkDragSortEvent } from '@angular/cdk/drag-drop';

@Component({
  selector: 'echo-nx-repeater-field',
  templateUrl: './repeater-field.component.html',
  styleUrls: ['./repeater-field.component.scss'],
})
export class RepeaterFieldComponent
  extends BaseFormFieldComponent<IRepeaterFieldSettings>
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(RepeaterComponentTreeDirective, {
    read: RepeaterComponentTreeDirective,
  })
  public repeaterComponentTreeDirective!: RepeaterComponentTreeDirective;

  public expanded = true;
  public color = '#81d4fa';
  private componentTree: Record<string, any>[] = [];

  get getFormGroupForRow() {
    return (index: number) => {
      return (this.formControl as UntypedFormArray).at(
        index
      ) as UntypedFormGroup;
    };
  }

  constructor(
    @Optional()
    @Inject(DYNAMIC_COMPONENT_INPUT)
    entityDefinition: IEntityDefinition<IRepeaterFieldSettings>,
    private formBuilder: UntypedFormBuilder,
    formGroupDirective: FormGroupDirective,
    injector: Injector
  ) {
    super(entityDefinition, formGroupDirective, injector);
    const { defaultValue } = this.settings;
    if (Array.isArray(defaultValue)) {
      for (const item of defaultValue) {
        const formGroup = this.createItem();
        formGroup.patchValue(item);
      }
    }
  }

  public createItem() {
    //if its collapsed and user click New item, we expand it - otherwise he wouldnt see new lines
    this.expanded = true;

    // create item based on row definition
    const { rowDefinition } = this.settings;
    const formGroup = buildEntityForm(rowDefinition);
    this.pushItem(formGroup);
    return formGroup;
  }

  private pushItem(formGroup: UntypedFormGroup) {
    // get the array from form
    const items = this.formGroup.get(
      this.settings.formControlName
    ) as UntypedFormArray;

    // push it
    items.push(formGroup);
  }

  public deleteItem(itemIndex: number): void {
    const items = this.formGroup.get(
      this.settings.formControlName
    ) as UntypedFormArray;
    items.removeAt(itemIndex);
    this.repeaterComponentTreeDirective.removeComponentAtIndex(itemIndex);
  }

  public onPortalAttached(
    formControlName: string,
    idx: number,
    portal: CdkPortalOutletAttachedRef
  ) {
    if (this.componentTree[idx]) {
      this.componentTree[idx] = {
        ...this.componentTree[idx],
        [formControlName]: portal,
      };
    } else {
      this.componentTree.push({ [formControlName]: portal });
    }

    //this can happen before or after ngOnInit
    if (this.repeaterComponentTreeDirective) {
      //added by user

      //add it to the "global" component tree
      this.repeaterComponentTreeDirective.addComponentAtIndex(
        formControlName,
        idx,
        portal
      );
    }
  }

  override async ngOnDestroy(): Promise<void> {
    await super.ngOnDestroy();
    // const items = this.settings.form?.get(this.settings.formControlName) as FormArray;
    // items.clear();
  }

  ngAfterViewInit(): void {
    if (this.settings.color) {
      this.color = this.settings.color;
    }
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    console.log('component tree at init', this.componentTree);
    for (const [idx, group] of this.componentTree.entries()) {
      this.repeaterComponentTreeDirective.pushGroup(idx, group);
    }
  }

  getMaxY(components: IEntityDefinition<any>[], modify: number) {
    return Math.max(...components.map(x => x.y)) + modify;
  }

  onReorder(event: CdkDragSortEvent) {
    const formGroup = (this.formControl as FormArray);
    formGroup.controls.sort((a, b) => a.value.order - b.value.order).forEach((ctrl, i) => ctrl['controls']['order'].setValue(i));
    console.log(formGroup.value);
    formGroup.controls[event.previousIndex]['controls']['order'].setValue(event.currentIndex - 0.5);
    formGroup.controls.sort((a, b) => a.value.order - b.value.order).forEach((ctrl, i) => (ctrl as any).controls.order.setValue(i));
    console.log(formGroup.value);

    this.formControl = formGroup;

    const temp2 = this.componentTree[event.previousIndex];
    this.componentTree[event.previousIndex] = this.componentTree[event.currentIndex];
    this.componentTree[event.currentIndex] = temp2;
  }
}
