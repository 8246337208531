import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentResultQueryParams } from '../../../types';
import {
  initiateMarlenkaPayment,
  MarlenkaRouteData,
} from '@echo-nx/marlenka/common';
import { LanguageRouteDataService } from '@echo-nx/shared/ng/feature/language';

@Component({
  selector: 'marlenka-reservation-result-page',
  templateUrl: './reservation-result-page.component.html',
  styleUrls: ['./reservation-result-page.component.scss'],
})
export class ReservationResultPageComponent {
  public message: string;
  public isSuccessful: boolean;
  public errorCode: string;
  public uuid?: string;

  public actionTaken = false;

  constructor(
    private activeRoute: ActivatedRoute,
    public mrdService: LanguageRouteDataService<MarlenkaRouteData>
  ) {
    const { message, errorCode, uuid } = activeRoute.snapshot
      .queryParams as PaymentResultQueryParams;
    this.message = message;
    this.errorCode = errorCode;
    this.uuid = uuid;
    this.isSuccessful = errorCode === '0';
  }

  public async retryPayment() {
    if (this.actionTaken) {
      return;
    }
    this.actionTaken = true;
    await initiateMarlenkaPayment(
      this.activeRoute.snapshot.queryParams.ID,
      'reservation'
    );
  }
}
