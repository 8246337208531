<ng-container *ngIf="pageSettings$ | async as pageSettings">
  <!-- MAIN BANNER -->
  <marlenka-menu-grid-banner
    *ngIf="menuBanner"
    [mainItem]="menuBanner.mainItem"
    [bottomItems]="menuBanner.bottomItems"
    [topWave]="true"
    [firstColBigger]="false"
  ></marlenka-menu-grid-banner>

  <!-- WHAT AWAITS -->
  <div class="fx-row info-jumbotron excursion-jumbotron" id="section-1">
    <div class="col image">
      <img
        [src]="
          pageSettings.texts.infoJumbotron.medium?.url ??
          'https://marlenka.s3.eu-central-1.amazonaws.com/foto_1.jpg'
        "
        [alt]="
          pageSettings.texts.infoJumbotron.medium?.description ??
          'Ukázkové foto'
        "
      />
    </div>
    <div class="col content-col">
      <div class="content-wrap">
        <h3 class="title">
          {{ pageSettings.texts.infoJumbotron.title }}
        </h3>
        <marlenka-wysiwyg-view
          [html]="pageSettings.texts.infoJumbotron.content"
        >
        </marlenka-wysiwyg-view>
      </div>
    </div>
  </div>

  <!-- PROGRAM -->
  <div class="fx-row content-jumbotron excursion-jumbotron" id="section-2">
    <div class="col content-col">
      <div class="content-wrap">
        <h3 class="title">
          {{ pageSettings.texts.tourProgramJumbotron.title }}
        </h3>
        <marlenka-wysiwyg-view
          [html]="pageSettings.texts.tourProgramJumbotron.content"
        >
        </marlenka-wysiwyg-view>
      </div>
    </div>
    <div
      class="gallery-col col image"
      style="background: #fff6e6"
      *ngIf="excursionPhotos$ | async as excursionPhotos"
      echoNxLightboxGalleryGroup
      [withThumbnails]="true"
    >
      <!--<img [src]="pageSettings.texts.reservationJumbotron.medium?.url ?? 'https://marlenka.s3.eu-central-1.amazonaws.com/foto_4.jpg'"
           [alt]="pageSettings.texts.reservationJumbotron.medium?.description ?? 'Ukázkové foto'"/>-->

      <!-- featured image -->
      <echo-nx-medium-item
        [index]="0"
        [medium]="excursionPhotos[0]"
      ></echo-nx-medium-item>

      <!-- rest of the images -->
      <div class="photo-gallery">
        <ng-container
          *ngFor="
            let attachment of excursionPhotos;
            let i = index;
            let first = first
          "
        >
          <div
            class="aspect-ratio"
            *ngIf="!first"
            [class.hidden]="i > 6 || (i > 5 && excursionPhotos.length - 7 > 0)"
          >
            <div class="image">
              <echo-nx-medium-item
                [index]="i"
                [medium]="attachment"
              ></echo-nx-medium-item>
            </div>
          </div>
        </ng-container>
        <div class="aspect-ratio" *ngIf="excursionPhotos.length - 7 > 0">
          <div class="image">
            <echo-nx-medium-item
              [index]="6"
              style="position: absolute; width: 100%; height: 100%"
            ></echo-nx-medium-item>
            <div class="additional-text">
              <div class="big">+ {{ excursionPhotos.length - 6 }}</div>
              <div class="smol">{{ (buttonsText$ | async)?.otherPhotos }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="col image">
      <img
        [src]="pageSettings.texts.tourProgramJumbotron.medium?.url ?? 'https://marlenka.s3.eu-central-1.amazonaws.com/foto_2.jpg'"
        [alt]="pageSettings.texts.tourProgramJumbotron.medium?.description ?? 'Ukázkové foto'"
      />
    </div>-->
  </div>

  <!-- VISITORS CENTER
  <div class="fx-row info-jumbotron full-width-jumbotron" id="visitors-center">
    <div class="col content-col container medium">
      <div class="content-wrap">
        <h3 class="title">
          {{
          pageSettings.texts.visitorCentrumJumbotron.title | multilang: mrdService.currentLanguage
          }}
        </h3>
        <marlenka-wysiwyg-view
          [html]="pageSettings.texts.visitorCentrumJumbotron.content | multilang: mrdService.currentLanguage">
        </marlenka-wysiwyg-view>
      </div>
    </div>
  </div>-->

  <!-- PRESENTATION CONTENT -->
  <div class="fx-row content-jumbotron excursion-jumbotron" id="section-3">
    <div class="col image">
      <img
        [src]="
          pageSettings.texts.contentJumbotron.medium?.url ??
          'https://marlenka.s3.eu-central-1.amazonaws.com/foto_3.jpg'
        "
        [alt]="
          pageSettings.texts.contentJumbotron.medium?.description ??
          'Ukázkové foto'
        "
      />
    </div>
    <div class="col content-col">
      <div class="content-wrap">
        <h3 class="title">
          {{ pageSettings.texts.contentJumbotron.title }}
        </h3>
        <marlenka-wysiwyg-view
          [html]="pageSettings.texts.contentJumbotron.content"
        >
        </marlenka-wysiwyg-view>

        <div class="reservation-buttons">
          <button
            mat-button
            class="reservation-button ticket-button enter-reservation-system"
            (click)="goToReservation()"
          >
            <span class="title">
              {{
                pageSettings.texts.reservationJumbotron.enterReservationSystem
                  .title
              }}
            </span>
            <hr />
            <span class="subtitle">
              <marlenka-wysiwyg-view
                [html]="
                  pageSettings.texts.reservationJumbotron.enterReservationSystem
                    .subtitle
                "
              >
              </marlenka-wysiwyg-view>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- PRODEJNA -->
  <div class="fx-row content-jumbotron excursion-jumbotron" id="section-4">
    <div class="col content-col">
      <div class="content-wrap">
        <h3 class="title">
          {{ pageSettings.texts.reservationJumbotron.title }}
        </h3>
        <marlenka-wysiwyg-view
          [html]="pageSettings.texts.reservationJumbotron.content"
        >
        </marlenka-wysiwyg-view>
      </div>
    </div>
    <div
      class="gallery-col col image"
      style="background: #fff6e6"
      *ngIf="prodejnaPhotos$ | async as prodejnaPhotos"
      echoNxLightboxGalleryGroup
      [withThumbnails]="true"
    >
      <!--<img [src]="pageSettings.texts.reservationJumbotron.medium?.url ?? 'https://marlenka.s3.eu-central-1.amazonaws.com/foto_4.jpg'"
           [alt]="pageSettings.texts.reservationJumbotron.medium?.description ?? 'Ukázkové foto'"/>-->

      <!-- featured image -->
      <echo-nx-medium-item
        [index]="0"
        [medium]="prodejnaPhotos[0]"
      ></echo-nx-medium-item>

      <!-- rest of the images -->
      <div class="photo-gallery">
        <ng-container
          *ngFor="
            let attachment of prodejnaPhotos;
            let i = index;
            let first = first
          "
        >
          <div
            class="aspect-ratio"
            *ngIf="!first"
            [class.hidden]="i > 6 || (i > 5 && prodejnaPhotos.length - 7 > 0)"
          >
            <div class="image">
              <echo-nx-medium-item
                [index]="i"
                [medium]="attachment"
              ></echo-nx-medium-item>
            </div>
          </div>
        </ng-container>
        <div class="aspect-ratio" *ngIf="prodejnaPhotos.length - 7 > 0">
          <div class="image">
            <echo-nx-medium-item
              [index]="6"
              style="position: absolute; width: 100%; height: 100%"
            ></echo-nx-medium-item>
            <div class="additional-text">
              <div class="big">+ {{ prodejnaPhotos.length - 6 }}</div>
              <div class="smol">{{ (buttonsText$ | async)?.otherPhotos }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- PRESENTATION KAVARNA -->
  <div class="fx-row content-jumbotron excursion-jumbotron" id="section-5">
    <div
      class="gallery-col col image"
      *ngIf="kavarnaPhotos$ | async as kavarnaPhotos"
      echoNxLightboxGalleryGroup
      [withThumbnails]="true"
    >
      <!--<img [src]="pageSettings.texts.presentationJumbotron.medium?.url ?? 'https://marlenka.s3.eu-central-1.amazonaws.com/foto_5.jpg'"
           [alt]="pageSettings.texts.presentationJumbotron.medium?.description ?? 'Ukázkové foto'"/>-->
      <!-- featured image -->
      <echo-nx-medium-item
        [index]="0"
        [medium]="kavarnaPhotos[0]"
      ></echo-nx-medium-item>

      <!-- rest of the images -->
      <div class="photo-gallery">
        <ng-container
          *ngFor="
            let attachment of kavarnaPhotos;
            let i = index;
            let first = first
          "
        >
          <div
            class="aspect-ratio"
            *ngIf="!first"
            [class.hidden]="i > 6 || (i > 5 && kavarnaPhotos.length - 7 > 0)"
          >
            <div class="image">
              <echo-nx-medium-item
                [index]="i"
                [medium]="attachment"
              ></echo-nx-medium-item>
            </div>
          </div>
        </ng-container>
        <div class="aspect-ratio" *ngIf="kavarnaPhotos.length - 7 > 0">
          <div class="image">
            <echo-nx-medium-item
              [index]="6"
              style="position: absolute; width: 100%; height: 100%"
            ></echo-nx-medium-item>
            <div class="additional-text">
              <div class="big">+ {{ kavarnaPhotos.length - 6 }}</div>
              <div class="smol">{{ (buttonsText$ | async)?.otherPhotos }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col content-col">
      <div class="content-wrap">
        <h3 class="title">
          {{ pageSettings.texts.presentationJumbotron.title }}
        </h3>
        <marlenka-wysiwyg-view
          [html]="pageSettings.texts.presentationJumbotron.content"
        >
        </marlenka-wysiwyg-view>
      </div>
    </div>
  </div>

  <!-- TASTING -->
  <div class="fx-row content-jumbotron excursion-jumbotron" id="section-6">
    <div class="col content-col">
      <div class="content-wrap">
        <h3 class="title">
          {{ pageSettings.texts.virtualTourJumbotron.title }}
        </h3>
        <marlenka-wysiwyg-view
          [html]="pageSettings.texts.virtualTourJumbotron.content"
        >
        </marlenka-wysiwyg-view>
      </div>
    </div>
    <div class="col image">
      <img
        [src]="
          pageSettings.texts.virtualTourJumbotron.medium?.url ??
          'https://i.imgur.com/nocCs1q.jpg'
        "
        [alt]="
          pageSettings.texts.virtualTourJumbotron.medium?.description ??
          'Ukázkové foto'
        "
      />
    </div>
  </div>

  <!-- PRODUCTION INSPECTION -->
  <!--  <div class="fx-row content-jumbotron excursion-jumbotron"
         id="section-7"
    >
      <div class="col image">
        <img src="https://i.imgur.com/nocCs1q.jpg" alt=""/>
      </div>
      <div class="col content-col">
        <div class="content-wrap">
          <h3 class="title">
            {{
            pageSettings.texts.productionInspectionJumbotron.title | multilang: mrdService.currentLanguage
            }}
          </h3>
          <marlenka-wysiwyg-view
            [html]="pageSettings.texts.productionInspectionJumbotron.content | multilang: mrdService.currentLanguage">
          </marlenka-wysiwyg-view>
        </div>
      </div>
    </div>-->

  <!-- PURCHASE -->
  <!--  <div class="fx-row content-jumbotron excursion-jumbotron" id="purchase">
      &lt;!&ndash; https://i.ibb.co/DzzXzzR/cake-2.png &ndash;&gt;
      <div class="col content-col">
        <div class="content-wrap">
          <h3 class="title">
            {{
            pageSettings.texts.purchaseInspectionJumbotron.title | multilang: mrdService.currentLanguage
            }}
          </h3>
          <marlenka-wysiwyg-view
            [html]="pageSettings.texts.purchaseInspectionJumbotron.content | multilang: mrdService.currentLanguage">
          </marlenka-wysiwyg-view>
        </div>
      </div>
      <div class="col image">
        <img src="https://i.imgur.com/WodcVlU.jpg" alt=""/>
      </div>
    </div>-->
</ng-container>
