import { NativeDateAdapter } from '@angular/material/core';
import { DateTime } from 'luxon';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // upravuje mat-datetimepicker tak, aby pondelok bol prvy den v tyzdni a upravuje input datetime
  getFirstDayOfWeek(): number {
    return 1;
  }

  parse(value: any): Date | null {
    if (typeof value === 'string' && value.includes('T')) {
      return DateTime.fromISO(value).toJSDate();
    }
    return DateTime.fromFormat(value, "dd.MM.yyyy HH:mm").toJSDate();
  }


}
