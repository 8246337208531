<nav class="w-full" [ngClass]="[bgColor | prefix: 'bg']">
  <div
    class="flex justify-center lg:justify-between gap-8"
    [ngClass]="[primaryColor | prefix: 'bg']"
  >
    <echo-nx-nav-dropdown-item
      (itemClicked)="itemClicked.next($event)"
      [item]="navigation"
      [textColor]="textColor"
      [iconsVisible]="showIcons"
      [upperCase]="true"
    ></echo-nx-nav-dropdown-item>
  </div>
</nav>
