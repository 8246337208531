import gql from 'graphql-tag';
import {navigationItemFragment} from "./navigation-item.fragment";

export const readAllNavigations = gql`
  query ReadAllNavigation($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeNotPublished: Boolean){
    response: readAllNavigation(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeNotPublished: $includeNotPublished
    ){
      total
      page
      take
      hasMore
      items{
        createdAt
        updatedAt
        owner {
          _id
          mainNavigation {
            _id
          }
          name
        }
        categories{
          _id
          name
        }
        publishedAt
        unpublishedAt
        ...NavigationItemFragment
        children {
          ...NavigationItemFragment
          children {
            ...NavigationItemFragment
            children {
              ...NavigationItemFragment
              children {
                ...NavigationItemFragment
                children {
                  ...NavigationItemFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${navigationItemFragment}
`
