<ng-container *ngIf="buttons$ | async as buttons">
  <div
    class="p-2 grid grid-flow-col auto-cols-fr gap-2"
    [ngClass]="[bgColor | prefix: 'bg', textSize | prefix: 'text']"
  >
    <button
      class="button"
      [disabled]="activeIndex === 0"
      [ngClass]="
        activeIndex === 0
          ? [
              disabledColorful.color | prefix: 'text',
              disabledColorful.bgColor | prefix: 'bg',
              disabledColorful.color | prefix: 'border'
            ]
          : [
              normalColorful.color | prefix: 'text',
              normalColorful.bgColor | prefix: 'bg',
              normalColorful.color | prefix: 'border'
            ]
      "
      (click)="previousClicked()"
    >
      <i class="fas fa-chevron-left fa-fw"></i>
    </button>

    <button
      class="button text-lg"
      *ngFor="let button of buttons"
      (click)="pageClicked(button.index)"
      [disabled]="button.isDisabled || button.isActive"
      [ngClass]="
        button.isDisabled
          ? [
              disabledColorful.color | prefix: 'text',
              disabledColorful.bgColor | prefix: 'bg',
              disabledColorful.color | prefix: 'border'
            ]
          : button.isActive
          ? [
              activeColorful.color | prefix: 'text',
              activeColorful.bgColor | prefix: 'bg',
              activeColorful.color | prefix: 'border'
            ]
          : [
              normalColorful.color | prefix: 'text',
              normalColorful.bgColor | prefix: 'bg',
              normalColorful.color | prefix: 'border'
            ]
      "
    >
      {{ button.label }}
    </button>

    <button
      class="button"
      *ngIf="buttons"
      [disabled]="!hasNext()"
      [ngClass]="
        !hasNext()
          ? [
              disabledColorful.color | prefix: 'text',
              disabledColorful.bgColor | prefix: 'bg',
              disabledColorful.color | prefix: 'border'
            ]
          : [
              normalColorful.color | prefix: 'text',
              normalColorful.bgColor | prefix: 'bg',
              normalColorful.color | prefix: 'border'
            ]
      "
      (click)="nextClicked()"
    >
      <i class="fas fa-chevron-right fa-fw"></i>
    </button>
  </div>

  <div>
    Zobrazuji {{ showingFrom }} - {{ showingTo }} z celkem {{ total }} záznamů
  </div>
</ng-container>
