import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseReservationService } from './base-reservation.service';
import { Apollo } from 'apollo-angular';
import {
  IDiscountCoupon,
  IMarlenkaOwner,
  IMContactPersonForm,
  IMDialogCommodity,
  IMEvent,
  IMItemType,
  IMProductType,
  IMReservation,
  IMReservationVoucher,
  IMReservedItem,
  IMReservedProduct,
  IMReservedTicket,
  IMTicketType,
} from '@echo-nx/marlenka/common';
import { ICategory } from '@echo-nx/shared/common';

interface VoucherCustomizationSettings {
  voucherTypeId?: string;
  text: string;
  title: string;
  signature: string;
}

@Injectable()
export class ReservationService
  extends BaseReservationService
  implements OnDestroy
{
  private _currentReservationUniqueId?: string;

  private readonly isDestroyed$ = new Subject<boolean>();

  private readonly _selectedEvent: BehaviorSubject<IMEvent<
    ICategory<IMarlenkaOwner>
  > | null>;
  public readonly selectedEvent$: Observable<IMEvent<
    ICategory<IMarlenkaOwner>
  > | null>;

  private readonly _selectedTickets: BehaviorSubject<
    IMReservedTicket<IMTicketType<IMDialogCommodity>>[]
  >;
  public readonly selectedTickets$: Observable<
    IMReservedTicket<IMTicketType<IMDialogCommodity>>[]
  >;

  private readonly _selectedProducts: BehaviorSubject<
    IMReservedProduct<IMProductType<IMDialogCommodity>>[]
  >;
  public readonly selectedProducts$: Observable<
    IMReservedProduct<IMProductType<IMDialogCommodity>>[]
  >;

  private readonly _selectedPerson: BehaviorSubject<IMContactPersonForm>;
  public readonly selectedPerson$: Observable<IMContactPersonForm>;

  private readonly _selectedCoupons: BehaviorSubject<IDiscountCoupon[]>;
  public readonly selectedCoupons$: Observable<IDiscountCoupon[]>;

  private readonly _voucherCustomization: BehaviorSubject<VoucherCustomizationSettings>;
  public readonly voucherCustomization$: Observable<VoucherCustomizationSettings>;

  //private readonly _totals: BehaviorSubject<MTotals>;
  public readonly totals$: Observable<MTotals>;

  public requiresGlutenFree = false;
  public glutenFreeCount = 0;

  private _shouldRegister: BehaviorSubject<boolean>;
  public shouldRegister$: Observable<boolean>;

  public onNextFailed: Subject<void>;
  public onNextFailed$: Observable<void>;

  private getReservationItemTotals = (
    reservedItems: IMReservedItem<IMItemType<IMDialogCommodity>>[]
  ): Total => {
    const count = reservedItems.length;
    const cost = reservedItems.reduce((sum, item) => {
      sum += item.itemType.commodityType.price;
      return sum;
    }, 0);
    return {
      cost,
      count,
    } as Total;
  };

  constructor(apollo: Apollo) {
    super(apollo);

    this._shouldRegister = new BehaviorSubject<boolean>(false);
    this.shouldRegister$ = this._shouldRegister.asObservable();

    this._selectedEvent = new BehaviorSubject<IMEvent<
      ICategory<IMarlenkaOwner>
    > | null>(null);
    this.selectedEvent$ = this._selectedEvent.asObservable();

    this._selectedTickets = new BehaviorSubject<
      IMReservedTicket<IMTicketType<IMDialogCommodity>>[]
    >([]);
    this.selectedTickets$ = this._selectedTickets.asObservable();

    this._selectedProducts = new BehaviorSubject<
      IMReservedProduct<IMProductType<IMDialogCommodity>>[]
    >([]);
    this.selectedProducts$ = this._selectedProducts.asObservable();

    this._selectedPerson = new BehaviorSubject<IMContactPersonForm>({
      formValid: false,
    });
    this.selectedPerson$ = this._selectedPerson.asObservable();

    this._selectedCoupons = new BehaviorSubject<IDiscountCoupon[]>([]);
    this.selectedCoupons$ = this._selectedCoupons.asObservable();

    this._voucherCustomization = new BehaviorSubject({
      text: '',
      title: '',
      signature: '',
    });
    this.voucherCustomization$ = this._voucherCustomization.asObservable();

    this.onNextFailed = new Subject();
    this.onNextFailed$ = this.onNextFailed.asObservable();

    //this.itemDictionary$ = combineLatest([this.])

    this.totals$ = combineLatest([
      this.selectedTickets$,
      this.selectedProducts$,
      this.selectedCoupons$,
    ]).pipe(
      map(([tickets, products, coupons]) => {
        // calculate the totals
        const ticketsTotal = this.getReservationItemTotals(tickets);
        const productsTotal = this.getReservationItemTotals(products);
        const couponsTotal = {
          cost: coupons.reduce((acc, curr) => acc + curr.price, 0),
          count: coupons.length,
        };

        // console.log(ticketsTotal, productsTotal, couponsTotal);

        // return the object total
        return {
          tickets: ticketsTotal,
          products: productsTotal,
          coupons: couponsTotal,
          total: {
            count:
              ticketsTotal.count + productsTotal.count + couponsTotal.count,
            cost: ticketsTotal.cost + productsTotal.cost + couponsTotal.cost,
          },
        } as MTotals;
      })
    );
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }

  set selectedEvent(event: IMEvent<ICategory<IMarlenkaOwner>> | null) {
    this._selectedEvent.next(event);
  }

  get selectedEvent(): IMEvent<ICategory<IMarlenkaOwner>> | null {
    return this._selectedEvent.getValue();
  }

  set selectedTickets(tickets) {
    this._selectedTickets.next(tickets);
  }

  get selectedTickets() {
    return this._selectedTickets.getValue();
  }

  set selectedProducts(products) {
    this._selectedProducts.next(products);
  }

  get selectedProducts() {
    return this._selectedProducts.getValue();
  }

  set selectedPerson(contactPerson: IMContactPersonForm) {
    this._selectedPerson.next(contactPerson);
  }

  get selectedPerson() {
    return this._selectedPerson.getValue();
  }

  get selectedCoupons() {
    return this._selectedCoupons.getValue();
  }

  set selectedCoupons(coupons: IDiscountCoupon[]) {
    this._selectedCoupons.next(coupons);
  }

  get shouldRegister(): boolean {
    return this._shouldRegister.getValue();
  }

  set shouldRegister(val: boolean) {
    this._shouldRegister.next(val);
  }

  set voucherCustomization(data: VoucherCustomizationSettings) {
    this._voucherCustomization.next(data);
  }

  get voucherCustomization() {
    return this._voucherCustomization.getValue();
  }

  public addProduct(productType: IMProductType<IMDialogCommodity>) {
    this.selectedProducts = [
      ...this.selectedProducts,
      {
        itemType: productType,
        //price: productType.commodityType.JEDNOTKOVA_CENA
      },
    ];
  }

  public removeProduct(productType: IMProductType<IMDialogCommodity>) {
    this.selectedProducts = removeReservedItem(
      productType,
      this.selectedProducts
    );
  }

  public addTicket(ticketType: IMTicketType<IMDialogCommodity>) {
    this.selectedTickets = [
      ...this.selectedTickets,
      {
        itemType: ticketType,
        //price: ticketType.commodityType.JEDNOTKOVA_CENA
      },
    ];
  }

  public removeTicket(ticketType: IMTicketType<IMDialogCommodity>) {
    this.selectedTickets = removeReservedItem(ticketType, this.selectedTickets);
  }

  /**
   *
   * @param currentLanguage - in what language was the reservation done (for communication reasons)
   */
  saveReservation(currentLanguage: string) {
    const reservationRequest: IMReservation<any, any> = {
      uniqueId: this._currentReservationUniqueId,
      language: currentLanguage,
      event: this.selectedEvent?._id,
      glutenFreeCount: this.glutenFreeCount,
      tickets: this.selectedTickets.map((t) => ({
        ...t,
        itemType: t.itemType._id,
      })),
      products: this.selectedProducts.map((p) => ({
        ...p,
        itemType: p.itemType._id,
      })),
      coupons: this.selectedCoupons.map((c) => ({ code: c.code })),
      contact: (this.selectedPerson.formValid
        ? {
            ...this.selectedPerson.formData?.person,
            company: this.selectedPerson.formData?.company,
          }
        : undefined) as any,
    };

    return this.save([reservationRequest]).pipe(
      tap((reservations: any) => {
        this._currentReservationUniqueId =
          reservations.data?.response[0].uniqueId;
        return reservations;
      })
    );
  }

  public saveVoucher() {
    const voucherRequest: IMReservationVoucher<any, any, string> = {
      uniqueId: this._currentReservationUniqueId,
      text: this.voucherCustomization?.text,
      title: this.voucherCustomization?.title,
      signature: this.voucherCustomization?.signature,
      voucherType: this.voucherCustomization?.voucherTypeId,
      tickets: this.selectedTickets.map((t) => ({
        ...t,
        itemType: t.itemType._id,
      })),
      products: this.selectedProducts.map((p) => ({
        ...p,
        itemType: p.itemType._id,
      })),
      contact: (this.selectedPerson.formValid
        ? {
            ...this.selectedPerson.formData?.person,
            company: this.selectedPerson.formData?.company,
          }
        : undefined) as any,
    };
    return this.saveAsVoucher([voucherRequest]).pipe(
      tap((reservations: any) => {
        this._currentReservationUniqueId =
          reservations.data?.response[0].uniqueId;
        return reservations;
      })
    );
  }
}

// todo move this somewhere
interface MTotals {
  tickets: Total;
  products: Total;
  coupons: Total;
  total: Total;
}

export interface Total {
  count: number;
  cost: number;
}

const removeReservedItem = <T extends IMItemType>(
  reservedItem: T,
  items: IMReservedItem<T>[]
) => {
  // find the ticket with given type
  const reservedItemIdx = items.findIndex(
    (product) => product.itemType._id === reservedItem._id
  );
  if (reservedItemIdx >= 0) {
    // copy the array and splice it
    const newArray = [...items];
    newArray.splice(reservedItemIdx, 1);
    return newArray;
  } else {
    return items;
  }
};
