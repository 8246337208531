import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import {
  TableField,
  TableKeyDirective,
} from '@echo-nx/shared/ng/feature/common';

@Component({
  selector: 'echo-nx-table-item-list',
  templateUrl: './table-item-list.component.html',
  styleUrls: ['./table-item-list.component.scss'],
})
export class TableItemListComponent<T> implements OnInit {
  @Input() fields: TableField[] = [];
  @Input() emptyMessage?: string;
  @Input() items: T[] = [];
  @Input() selectable = true;
  @Output() selected = new EventEmitter<T>();

  // styles
  @Input() capitalizeHeader = true;
  @Input() capitalizeBody = false;
  @Input() rounded = true;
  @Input() selectedBackgroundColor = '#AB0E0E';
  @Input() selectedColor = '#FFFFFF';
  @Input() headerBackgroundColor = '#DCD5C8';
  @Input() headerColor = '#A37D2C';
  @Input() bodyBackgroundColor = '#F3EFE6';
  @Input() bodyColor = '#6E4B00';
  @Input() dividerColor = '#6E4B00';

  @ContentChildren(TableKeyDirective)
  itemTemplates!: QueryList<TableKeyDirective>;

  @Input()
  public selectedItem?: T;

  ngOnInit(): void {
    document.documentElement.style.setProperty(
      '--divider-color',
      this.dividerColor
    );
  }

  public getTemplate(key: string): TemplateRef<any> {
    return this.itemTemplates.find(
      (directive) => directive.echoNxTableKey === key
    )?.templateRef as TemplateRef<any>;
  }

  setSelected(item: T) {
    //this.selectedItem = item;
    this.selected.emit(item);
  }
}
