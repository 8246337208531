import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrefixModule} from "../../prefix/prefix.module";
import {ItemCardVariantNyrovComponent} from "./item-card-variant-nyrov.component";
import {ResizeObserverModule} from "@echo-nx/shared/ng/feature/responsive";
import {SanitizerModule} from "@echo-nx/shared/ng/feature/common";


@NgModule({
  declarations: [
    ItemCardVariantNyrovComponent
  ],
  imports: [
    CommonModule,
    ResizeObserverModule,
    PrefixModule,
    SanitizerModule
  ],
  exports:[
    ItemCardVariantNyrovComponent
  ]
})
export class ItemCardVariantNyrovModule {
}
