import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ItemCardVariantBComponent} from './item-card-variant-b.component';
import {PrefixModule} from "../../prefix/prefix.module";
import {ResizeObserverModule} from "@echo-nx/shared/ng/feature/responsive";


@NgModule({
  declarations: [
    ItemCardVariantBComponent
  ],
  imports: [
    CommonModule,
    PrefixModule,
    ResizeObserverModule
  ],
  exports: [
    ItemCardVariantBComponent
  ]
})
export class ItemCardVariantBModule {
}
