import { DateTime } from "luxon";

export const remapItems = <T>(
  remoteItems: Record<string, unknown>[],
  mapping: string
): T[] => {
  return remoteItems.map((rItem) => remapItem<T>(rItem, mapping));
};

const htmlEntities = {
  // add whatever you feel is necessary https://www.freeformatter.com/html-entities.html
  nbsp: ' ',
  cent: '¢',
  pound: '£',
  yen: '¥',
  euro: '€',
  copy: '©',
  reg: '®',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: "'",
};

const unescapeHTML = (str: string) => str.replace(/\&([^;]+);/g, function (entity, entityCode) {
  let match;
  if (entityCode in htmlEntities) {
    return htmlEntities[entityCode];
    /*eslint no-cond-assign: 0*/
  } else if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
    return String.fromCharCode(parseInt(match[1], 16));
    /*eslint no-cond-assign: 0*/
  } else if (match = entityCode.match(/^#(\d+)$/)) {
    return String.fromCharCode(~~match[1]);
  } else {
    return entity;
  }
});


const transformFunctions: Record<string, (value: string, dbItem?: any) => any> = {
  'subtractMonth': value => {
    return value ? DateTime.fromMillis(Date.parse(value)).minus({ months: 1 }) : ''
  },
  'timestamp': (value: string) => {
    return DateTime.fromMillis(Date.parse(value)).toUnixInteger();
  },
  'time': (value: string) => {
    return DateTime.fromMillis(Date.parse(value)).toFormat("HH:mm");
  },
  'date': (value: string) => {
    return value ? DateTime.fromMillis(Date.parse(value)).toFormat("dd.MM.yyyy") : '';
  },
  'datetime': (value: string) => {
    return DateTime.fromMillis(Date.parse(value)).toFormat("HH:mm dd.MM.yyyy");
  },
  'dateRange': (value: string, item: any) => {
    const [start, end] = value.split("-");
    const startDate = start && item[start] ? DateTime.fromMillis(Date.parse(item[start])).toFormat("dd.MM.yyyy") : null;
    const endDate = end && item[end] ? DateTime.fromMillis(Date.parse(item[end])).toFormat("dd.MM.yyyy") : null;
    return `${startDate} ${endDate ? `- ${endDate}` : ''}`;
  },
  'timeRange': (value: string, item: any) => {
    const [start, end] = value.split("-");
    const startDate = start && item[start] ? DateTime.fromMillis(Date.parse(item[start])).toFormat("HH:mm") : null;
    const endDate = end && item[end] ? DateTime.fromMillis(Date.parse(item[end])).toFormat("HH:mm") : null;
    return `${startDate} ${endDate ? `- ${endDate}` : ''}`;
  },
  'key': (key: string, dbItem: any) => key.split('.').reduce((accu: Record<string, Record<string, any> | any>, currentKey: string, index: number) => {
    // todo implement array: key[i]
    // pokud existuje hodnota nebo jsme na posledni iteraci (tam uz muzeme vratit undefined)
    if (accu[currentKey] || index === key.split('.').length - 1) {
      return accu[currentKey];
    } else {
      // pokud nejsme na posledni iteraci a neni hodnota, vratime prazdny objekt at nespadne dalsi cyklus na tom, ze nemuze precist undef
      return {};
    }
  }, dbItem),
  'static': (value: any) => value,
  /*  'default': (key: any, item: any, defaultValue: any) =>  item[key] ?? defaultValue,*/
  'first': (key: any, item: any) => item?.[key]?.[0] ?? '',
  'join': (key: any, item: any) => item?.[key]?.join(", "),
  'DateTime.fromJSDate': (value: string) => value ? DateTime.fromMillis(Date.parse(value)) : value,
  'unescape': unescapeHTML,
  'czechLang': (key: string, item: any) => item[key].find(({ lang }) => lang === 'cs')?.value, //todo deprecate once multilang is converted fully
  'numeric': value => value ? Number(value) : 0,
  'count': (value) => value?.length ?? 0,
  'icon': value => `fas ${value}`,
  'values': value => Object.values(value),
  'address': value => {
    console.log("ADDRESS MAPPING", value);
    return `${value}`;
  },
  'round': value => Number(value).toFixed(2)
}

// REGEX TO SLIT STRING TO FUNCTION AND ITS ARGS
// todo vysrat se na regex a projit to sekvencne
const createTransformRegex = (): RegExp =>
  new RegExp(/(?<fn>\w+)\((?<args>[^\s]+)\)/, 'g');

const evaluateTransformFunctions = (dbItemKey: string, dbItem: any) => {
  const transformRegex = createTransformRegex();
  const valueTransformFns = transformRegex.exec(dbItemKey);
  if (!valueTransformFns) {
    return dbItemKey;
  } else {
    const { fn, args } = valueTransformFns.groups;
    const res = evaluateTransformFunctions(args, dbItem);
    // console.log(`Running transform function "${fn}" with arguments "${args}" = ${res}`,transformFunctions[fn](res, dbItem));
    return transformFunctions[fn](res, dbItem);
  }
}

export const readValueAtPath = (obj: any, path: string) => {
  let index = 0, current = obj;
  const segments = path.split('.');
  for (const segment of segments) {
    if (!current) return current;
    if (Array.isArray(current) && segment === '[]') { // unknown index
      for (const item of current) { // try all indexes
        try {
          const subPath = segments.slice(index + 1).join('.');
          return readValueAtPath(item, subPath)
        }
        catch {
          // skip wrong index
        }
      }
    }
    current = current[segment];
    index++;
  }
  return current;
}

export const getValuesAtAllPaths = (obj: any, path: string, pathPrefix: string = ''): { path: string, value: any }[] => {
  let index = 0, current = obj, currentPath = pathPrefix;
  const results = [];
  const segments = path.split('.');
  for (const segment of segments) {
    if (!current) {
      return results;
    }
    else if (Array.isArray(current) && segment === '[]') { // unknown index
      current.forEach((item, i) => {// try all indexes
        try {
          const subPath = segments.slice(index + 1).join('.');
          results.push(...getValuesAtAllPaths(item, subPath, `${currentPath}[${i}]`))
        }
        catch {
          // skip wrong index
        }
      })
      return results;
    }
    else {
      current = current[segment];
      currentPath = `${currentPath}${currentPath.length ? '.' : ''}${segment}`;
    }
    index++;
  }
  results.push({ path: currentPath, value: current });
  return results;
}

export const remapItem = <T = Record<string, any>>(dbItem: Record<string, unknown>, _mapping: string): T => {
  // z gql to chodi jako string
  const mapping: Record<string, any> = JSON.parse(_mapping); // val can be string | Record | Array<any>
  return Object.entries(mapping).reduce((acc, current) => {
    const [targetKey, mapperKey] = current;

    if (typeof mapperKey === 'string') {
      // its a key or fn
      const transformRegex = createTransformRegex();
      let value;
      if (!transformRegex.exec(mapperKey)) {
        value = transformFunctions['key'](mapperKey, dbItem);
      } else {
        value = mapperKey.replace(transformRegex, (res) =>
          evaluateTransformFunctions(res, dbItem)
        );
      }
      acc[targetKey] = value;
    } else if (Array.isArray(mapperKey)) {
      // its an array yay
      // console.log('its an array', mapperKey);
      acc[targetKey] = mapperKey.map((k) => {
        // array string klicu/funkci k remapu
        if (typeof k === 'string') {
          const tempMapping = { res: k };
          return remapItem(dbItem, JSON.stringify(tempMapping))['res'];
        } else {
          // array objektu k remapingu
          return remapItem(dbItem, JSON.stringify(k));
        }
      });
    } else {
      // its an object
      acc[targetKey] = remapItem(dbItem, JSON.stringify(mapperKey));
    }

    return acc;
  }, {} as T);
};
