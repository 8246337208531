import { FroalaOptions } from 'froala-editor';

function getToolbarButtons(moreTextButtonsNumber: number, moreParagraphButtonsNumber: number, moreRichButtonsNumber: number, moreMiscButtonsNumber: number): any {
  return {
    'moreText': {
      'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'inlineClass', 'fontSize', 'clearFormatting'],
      'buttonsVisible': moreTextButtonsNumber
    },
    'moreParagraph': {
      'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOLSimple', 'formatUL', 'outdent', 'indent', 'paragraphFormat', 'paragraphStyle', 'quote'],
      'buttonsVisible': moreParagraphButtonsNumber
    },
    'moreRich': {
      'buttons': ['insertLink', 'insertTable', 'insertVideo', 'openPicker', 'specialCharacters', 'insertHR'],
      'buttonsVisible': moreRichButtonsNumber
    },
    'moreMisc': {
      'buttons': ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
      'align': 'right',
      'buttonsVisible': moreMiscButtonsNumber
    }
  };
}

export const defaultFroalaOptions: Partial<FroalaOptions> = {
  key: 'qc1H2pH2A1A1E6A5B5oCe1ZSc2XHe1Cd1f1KIWCWMJHXCLSwD1D1D1E1F1H4A11B1D6F6==',
  charCounterCount: true,
  language: 'cs',
  listAdvancedTypes: false,
  editorClass: 'prose max-w-none',
  toolbarButtons: getToolbarButtons(3, 4, 4, 3),
  toolbarButtonsMD: getToolbarButtons(3, 4, 2, 2),
  toolbarButtonsSM: getToolbarButtons(3, 2, 0, 2),
  toolbarButtonsXS: getToolbarButtons(0, 0, 0, 2),
  videoResize: true,
  videoUpload: false,
  pastePlain: true,
  //theme: "dark", //activate this for new immersive experience
  pluginsEnabled: [
    'specialCharacters',
    'draggable',
    'help',
    'print',
    'fontFamily',
    'fontSize',
    'align',
    'lists',
    'link',
    'table',
    'colors',
    'image',
    'quote',
    'paragraphFormat',
    'paragraphStyle',
    'inlineClass',
    'fullscreen',
    'charCounter',
    'draggable',
    'url',
    'video',
    'codeView',
  ],
  fontSize: ['10', '14', '18', '24', '40', '70'],
  fontSizeSelection: true,
  tableStyles: {
    'fr-dashed-borders': 'Přerušovaný rámeček',
    'fr-alternate-rows': 'Střídavé řádky',
    'table-wo-borders': 'Bez rámečku',
  },
  paragraphFormatSelection: true,
};
