import gql from 'graphql-tag';

export const readSelectedOwner = gql`
  query ReadSelectedOwner($ids: [String!]!) {
    response: readSelectedOwner(ids: $ids) {
      _id
      name
      createdAt
      domains
      homepage {
        _id
      }
      mainNavigation {
        _id
      }
      favicon {
        _id
        url
      }
      webTitle
    }
  }
`;
