import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationVariantNyrovComponent } from './pagination-variant-nyrov.component';
import { PrefixModule } from '../../prefix/prefix.module';



@NgModule({
  declarations: [
    PaginationVariantNyrovComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    PaginationVariantNyrovComponent
  ]
})
export class PaginationVariantNyrovModule { }
