import gql from 'graphql-tag';
import {navigationItemFragment} from "./navigation-item.fragment";

export const readSelectedNavigations = gql`
  query ReadSelectedNavigations($ids: [String!]){
    response: readSelectedNavigation(ids: $ids){
      createdAt
      updatedAt
      owner {
        _id
        mainNavigation{
          _id
        }
        name
      }
      categories{
        _id
        name
      }
      publishedAt
      unpublishedAt
      ...NavigationItemFragment
      children {
        ...NavigationItemFragment
        children {
          ...NavigationItemFragment
          children {
            ...NavigationItemFragment
            children {
              ...NavigationItemFragment
              children {
                ...NavigationItemFragment
              }
            }
          }
        }
      }

    }
  }
  ${navigationItemFragment}
`;
