import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { PageSettingService } from '../../services/page-setting.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  CommonPageButtonItem,
  CommonpageTexts,
  MarlenkaPageIdentifier,
  MarlenkaRouteData,
} from '@echo-nx/marlenka/common';
import { LanguageRouteDataService } from '@echo-nx/shared/ng/feature/language';

@Component({
  selector: 'marlenka-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'true',
        style({
          overflow: 'hidden',
          height: '*',
          width: '100%',
        })
      ),
      state(
        'false',
        style({
          opacity: '1',
          overflow: 'hidden',
          height: '75px',
          width: '*',
        })
      ),
      transition('true => false', animate('400ms ease-in-out')),
      transition('false => true', animate('400ms ease-in-out')),
    ]),
  ],
})
export class ReadMoreComponent implements OnInit {
  @Input() content = '';
  @Input() limit = 0;
  @Input() completeWords!: boolean;
  @Input() link?: string;

  public texts$: Observable<CommonPageButtonItem>;

  isContentToggled = false;
  nonEditedContent!: string;
  initialTextLength: number;

  constructor(
    private pageSettingService: PageSettingService,
    public mrdService: LanguageRouteDataService<MarlenkaRouteData>
  ) {
    this.texts$ = this.mrdService.currentLanguage$.pipe(
      switchMap((language) =>
        this.pageSettingService
          .getPageSettingsByIdentifier<CommonpageTexts>(
            MarlenkaPageIdentifier.COMMON,
            language
          )
          .pipe(
            map((res) => {
              return res.texts.buttons;
            })
          )
      )
    );
  }

  ngOnInit() {
    this.nonEditedContent = this.content;
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.nonEditedContent, 'text/html');
    this.initialTextLength = this.contentLength(doc.body);
    this.truncateContents(this.limit);

    console.log(this.limit, this.initialTextLength);
  }

  /**
   * Get the original text's truncated version. If the text really needed to
   * be truncated, this.ellipsisCharacters will be appended.
   * @param max the maximum length the text may have
   * @returns the text node that has been truncated or null if truncating wasn't required
   */
  private truncateContents(max: number) {
    if (max > this.initialTextLength) {
      return;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.nonEditedContent, 'text/html');
    const nodes = <(HTMLElement | CharacterData)[]>(
      this.flattenTextAndElementNodes(doc.body)
      .filter((node) => node.nodeType == Node.TEXT_NODE || node.nodeType == Node.ELEMENT_NODE)
    );
    let foundIndex = -1;
    let foundNode: Node;
    let offset = this.initialTextLength;
    for (let i = nodes.length - 1; i >= 0; i--) {
      const node = nodes[i];

      //console.log(node, offset, max);
      if (node instanceof CharacterData) {
        offset -= node.data.length;
      } else {
        offset--;
      }

      if (offset <= max) {
        if (node instanceof CharacterData) {
          if (!this.completeWords) {
            node.data = node.data.substring(0, max - offset);
          } else if (max - offset !== node.data.length) {
            let j = max - offset - 1;
            while (j > 0 && !node.data.charAt(j).match(' ')) {
              j--;
            }
            if (offset > 0 && j === 0) {
              continue;
            }
            node.data = node.data.substring(0, j) + '...';
          }
        }
        foundIndex = i;
        foundNode = node;
        break;
      }
    }

    for (let i = foundIndex + 1; i < nodes.length; i++) {
      const node = nodes[i];
      if (
        node.textContent !==
          '' /*&& node.parentNode !== this.elem.nativeElement*/ &&
        node.parentNode?.childNodes.length === 1
      ) {
        node.parentNode.parentNode?.removeChild(node.parentNode);
      } else {
        node.parentNode?.removeChild(node);
      }
    }

    //console.log(doc.body);
    this.content = doc.body.innerHTML;
    //return (this.contentLength(doc.body) !== this.initialTextLength) ? doc.body : null;
  }

  private flattenTextAndElementNodes(
    element: HTMLElement
  ): (CharacterData | HTMLElement)[] {
    const nodes: (CharacterData | HTMLElement)[] = [];
    for (let i = 0; i < element.childNodes.length; i++) {
      const child = element.childNodes.item(i);
      if (child instanceof HTMLElement || child instanceof CharacterData) {
        nodes.push(child);

        if (child instanceof HTMLElement) {
          nodes.push(...this.flattenTextAndElementNodes(child));
        }
      }
    }

    return nodes;
  }

  private contentLength(elem: HTMLElement): number {
    return this.flattenTextAndElementNodes(elem)
      .filter((node) => node.nodeType == Node.TEXT_NODE || node.nodeType == Node.ELEMENT_NODE)
      .map((node) => (node instanceof CharacterData ? node.data.length : 1))
      .reduce((sum, length) => sum + length, 0);
  }

  toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    if (this.isContentToggled) {
      this.content = this.nonEditedContent;
    } else {
      this.truncateContents(this.limit);
    }
  }
}
