import FroalaEditor from "froala-editor"

export function onCopyCut(this: FroalaEditor) {
  const e = window.getSelection();
  if (e.rangeCount) {
    // save copied text and html for pasting
    const container = document.createElement('div');
    for (let i = 0; i < e.rangeCount; i++) {
      container.appendChild(e.getRangeAt(i).cloneContents())
    }
    this['copiedText'] = e.toString();
    this['copiedHtml'] = container.innerHTML;
  }
}

export function onPaste(this: FroalaEditor, e) {
  const div = document.createElement('div');
  div.innerHTML = this['copiedHtml'];
  // check if copied text comes from this editor
  if (this['copiedText'].replace(/\s/g, '').trim() === div.innerText.replace(/\s/g, '')) {
    const fixNode = (node: Node): Node => {
      if (node.nodeName === 'A') {
        const linkElement = node as HTMLLinkElement;
        linkElement.removeAttribute('style');
        return linkElement;
      }
      if (node.hasChildNodes()) {
        node.childNodes.forEach(child => node.replaceChild(child, fixNode(child)));
        return node;
      }
      return node;
    }
    div.replaceChildren(...Array.from(div.childNodes).map(x => fixNode(x)));
    this.html.insert(div.innerHTML);
    this.undo.saveStep();
    return false;
  }
  return true;
}