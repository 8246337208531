<div class="flex flex-col flex-1 w-full h-full">
  <!--IMAGE SLIDER-->
  <div class="w-full h-full object-cover relative">
    <div
      class="w-full h-full"
      [@sliderAnimator]="{
        value: showFirst ? animationStyle : 'none',
        params: {
          time: transitionDurationInMilis + 'ms',
          distanceTo: distanceToFirst,
          distanceFrom: distanceFromFirst
        }
      }"
      (@sliderAnimator.done)="animationDone()"
    >
      <img
        [src]="data[firstIdx].imgUrl"
        [alt]="data[firstIdx].alt"
        [ngClass]="[imageFit | prefix: 'object']"
        class="absolute inset-0 w-full h-full"
      />
    </div>
    <div
      class="w-full h-full"
      [@sliderAnimator]="{
        value: showSecond ? animationStyle : 'none',
        params: {
          time: transitionDurationInMilis + 'ms',
          distanceTo: distanceToSecond,
          distanceFrom: distanceFromSecond
        }
      }"
    >
      <img
        [src]="data[secondIdx].imgUrl"
        [alt]="data[secondIdx].alt"
        [ngClass]="[imageFit | prefix: 'object']"
        class="absolute inset-0 w-full h-full"
      />
    </div>
    <div
      class="absolute flex inset-0 w-full h-full items-center justify-center overflow-hidden"
    >
      <div class="container">
        <!-- DESCRIPTION WRAPPER -->
        <div class="mx-auto h-64 w-3/4 relative">
          <div
            class="absolute inset-0 filter backdrop-blur-2xl opacity-75 drop-shadow-xl rounded-tl-3xl rounded-tr-sm rounded-br-3xl rounded-bl-sm"
            [ngClass]="[primaryColor | prefix: 'bg']"
          ></div>
          <div
            class="absolute inset-0 flex flex-col overflow-hidden items-center justify-between px-8 py-4 container rounded-tl-3xl rounded-tr-sm rounded-br-3xl rounded-bl-sm"
          >
            <div
              class="flex flex-col flex-1 gap-2"
              id="text"
              [ngClass]="[textColor | prefix: 'text']"
            >
              <!--TITLE-->
              <div id="title" class="text-3xl font-display">
                {{ data[currentIdx$.value].title }}
              </div>
              <div class="text-md line-clamp-3">
                {{ data[currentIdx$.value].description }}
              </div>
            </div>
            <!--INDICATORS-->
            <div *ngIf="!hideIndicators">
              <div class="flex flex-1 flex-row mx-auto justify-center">
                <button *ngIf="!hideIndicatorButtons">
                  <i
                    class="fas fa-arrow-left p-3"
                    [ngClass]="[textColor | prefix: 'text']"
                    (click)="previousImage()"
                  ></i>
                </button>

                <div
                  class="flex flex-row my-4 gap-2 justify-center"
                  *ngIf="sliderIndexes.length > 1"
                >
                  <ng-container *ngIf="sliderIndexes.length < 5">
                    <div
                      *ngFor="let i of sliderIndexes"
                      [ngClass]="
                        i === currentIdx$.value
                          ? [
                              secondaryColor | prefix: 'bg',
                              secondaryColor | prefix: 'ring',
                              'ring-1',
                              primaryColor | prefix: 'ring-offset'
                            ]
                          : [textColor | prefix: 'bg']
                      "
                      class="h-2 w-2 rounded-full ring-offset-2"
                    ></div>
                  </ng-container>
                  <ng-container *ngIf="sliderIndexes.length >= 5">
                    <div
                      class="text-center"
                      [ngClass]="[textColor | prefix: 'text']"
                    >
                      {{ currentIdx$.value + 1 }} z {{ sliderIndexes.length }}
                    </div>
                  </ng-container>
                </div>
                <button *ngIf="!hideIndicatorButtons">
                  <i
                    class="fas fa-arrow-right p-3"
                    [ngClass]="[textColor | prefix: 'text']"
                    (click)="nextImage()"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
