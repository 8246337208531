<div class="flex flex-row justify-center items-center p-2 py-4 mx-8"
     [ngClass]="[primaryColor | prefix:'bg', titleSize | prefix: 'text']">
  <div class="flex items-center justify-center pr-2">
    <i [ngClass]="[startIcon?.icon, startIcon?.color | prefix:'text']"></i>
  </div>
  <span class="font-display pl-2 text-center" [ngClass]="[accentColor | prefix:'text']" >
    {{title}}
  </span>
</div>

<!-- VALUE + SUFFIX -->
<div class="flex flex-col flex-1 justify-center py-4 px-8 col-span-3">
  <div class="flex gap-x-2 justify-center">
    <span class="font-bold h-full" [ngClass]="[valueSize | prefix: 'text']">{{value}}</span>
    <span class="font-light h-full" style="vertical-align: super" [ngClass]="[suffixSize | prefix: 'text']">{{suffix}}</span>
  </div>
  <span *ngIf="description" class="text-xs mt-2 text-center text-bold">{{description}}</span>
</div>

