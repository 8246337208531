import {
  canChangeReservationDate, getChildrenAndAdultCount,
  getSummaryForItemType,
  IMarlenkaOwner, IMDialogCommodity,
  IMEvent,
  IMProductType,
  IMReservation,
  IMTicketType, priceDescComparator, SummaryItem
} from "@echo-nx/marlenka/common";
import {ICategory, pluralize} from "@echo-nx/shared/common";
import {Component, ElementRef, Inject, Injector, ViewChild} from "@angular/core";
import {PaymentResultQueryParams} from "../../../types";
import {EventsService, ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {MarlenkaBasePageComponent} from "../../marlenka-base-page/marlenka-base-page.component";
import {ActivatedRoute} from "@angular/router";
import {SnackbarComponent, SnackbarData} from "@echo-nx/shared/ng/feature/snackbar";
import {OwnerService} from "../../../services/owner.service";
import {firstValueFrom, Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {ScrollableService, TableField, URL_SETTINGS_TOKEN, UrlSettings} from "@echo-nx/shared/ng/feature/common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DateTime} from 'luxon';
import {environment} from "../../../../environments/environment";


@Component({
  selector: 'marlenka-reservation-edit-page',
  templateUrl: './reservation-edit-page.component.html',
  styleUrls: ['./reservation-edit-page.component.scss'],
  providers: [
    ReservationService,
    EventsService
  ]
})
export class ReservationEditPageComponent extends MarlenkaBasePageComponent<any> { // todo typings

  public toggleReservationChange = false;
  public showChangeReservationButton = false;
  public reservation$: Observable<IMReservation<IMTicketType<IMDialogCommodity>, IMProductType<IMDialogCommodity>, IMEvent>>;
  public previousEvent?: IMEvent; // we set this when successful change occurs
  public isLoading = false;

  public limitDaysBefore = 3;

  public voucherUrl = `${environment.apiSettings.protocol}://${environment.apiSettings.apiUrl}/voucher/`;

  public pageLengthLimiter = 30;
  public urls: string[] = [];
  public numberOfPplInReservation = 0;

  @ViewChild('scrollTarget') set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.scrollableService.scrollToElement(content.nativeElement);
    }
  }

  public readonly summaryItems$: Observable<SummaryItem[]>;
  public readonly summaryTableFields: TableField<SummaryItem>[] = [
    {
      key: 'nazev',
      label: 'Název zboží'
    },
    {
      key: 'unitCount',
      label: 'Počet kusů',
      formatter: item => `${item.unitCount}`
    },
    /*{
      key: 'unitPrice',
      label: 'Cena za ks',
      formatter: item => `${item.unitPrice} Kč`
    },
    {
      key: 'totalPrice',
      label: 'Cena celkem',
      //formatter: item => isCoupon(item) ? `-${item.totalCost} Kč` : `${item.totalCost} Kč`
      formatter: item => `${item.totalPrice} Kč`
    }*/
  ];

  constructor(
    injector: Injector,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    public reservationService: ReservationService,
    private ownerService: OwnerService,
    private eventService: EventsService,
    @Inject(URL_SETTINGS_TOKEN) protected urlSettings: UrlSettings,
    private scrollableService: ScrollableService) {
    super(injector);
    const {uuid} = activeRoute.snapshot.params as PaymentResultQueryParams;
    this.voucherUrl += uuid;

    // todo create query
    this.reservation$ = this.reservationService.fetchByUniqueId(uuid).pipe(
      map(reservation => {
        // get reservation and event
        const event = reservation.event as IMEvent<ICategory<IMarlenkaOwner>>;

        // set defaults
        if (event) {
          this.reservationService.selectedEvent = event;
          this.eventService.selectedDate = new Date(event.start);
          firstValueFrom(this.ownerService.fetchSettingsForFrontend())
            .then(ownerSettings => {
              this.limitDaysBefore = ownerSettings.daysBeforeReservationCanBeChanged;
              this.showChangeReservationButton = canChangeReservationDate(reservation, this.limitDaysBefore);
            });
        } else {
          this.showChangeReservationButton = false;
        }
        return reservation as any;
      })
    );

    this.summaryItems$ = this.reservation$.pipe(
      switchMap(reservation => {
        const {tickets, products} = reservation;
        const summaryProducts = getSummaryForItemType(products, 'local_dining', 'product').sort(priceDescComparator);
        const summaryTickets = getSummaryForItemType(tickets, 'style', 'ticket').sort(priceDescComparator);

        //byproducts
        this.numberOfPplInReservation = getChildrenAndAdultCount(reservation).reduce((previousValue, currentValue) => previousValue += currentValue, 0);
        const sum = products.length + tickets.length;
        this.urls = [];
        const {apiSettings} = this.urlSettings;
        const apiUrl = `${apiSettings.protocol}://${apiSettings.apiUrl}`;
        for (let i = 0; i < Math.ceil(sum / this.pageLengthLimiter); i++) {
          this.urls.push(`${apiUrl}/pdf-ticket/${uuid}?limit=${this.pageLengthLimiter}&page=${i}`);
        }
        return of([...summaryTickets, ...summaryProducts]);
      })
    );
  }

  public async toggleChangeReservation(reservation: IMReservation) {
    // validate that we can change the date
    const ownerSettings = await firstValueFrom(this.ownerService.fetchSettingsForFrontend());
    const canChangeDate = canChangeReservationDate(reservation, ownerSettings.daysBeforeReservationCanBeChanged);

    if (canChangeDate) {
      this.toggleReservationChange = true;
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          text: `Termín rezervace lze změnit pouze jednou a nejpozději ${pluralize(ownerSettings.daysBeforeReservationCanBeChanged, ['den', 'dny', 'dní'])} před datem rezervované exkurze.` //todo INTL
        } as SnackbarData,
        panelClass: 'custom-snackbar',
        verticalPosition: 'top',
        horizontalPosition: 'end'
      });
    }
  }

  public changeReservationDate(reservation: IMReservation): void {
    // bail out if user selects same event
    const previousEventId = (reservation.event as IMEvent)._id;
    const currentEventId = this.reservationService.selectedEvent._id;
    if (previousEventId === currentEventId) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          text: 'Zvolte prosím nový termín prohlídky.' //todo INTL
        } as SnackbarData,
        panelClass: 'custom-snackbar',
        verticalPosition: 'top',
        horizontalPosition: 'end'
      });
      return;
    }

    // bail out when user selects old event
    const isFutureEvent = DateTime.fromJSDate(this.reservationService.selectedEvent.start) > DateTime.now();
    if (!isFutureEvent) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          text: 'Zvolená prohlídka již proběhla.' //todo INTL
        } as SnackbarData,
        panelClass: 'custom-snackbar',
        verticalPosition: 'top',
        horizontalPosition: 'end'
      });
      return;
    }

    // send the new event to the backend
    this.isLoading = true;
    firstValueFrom(this.reservationService.changeReservationDate(reservation.uniqueId, currentEventId))
      .then(_ => {
        this.previousEvent = reservation.event as IMEvent;
      })
      .catch(e => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: {
            text: e.message //todo INTL
          } as SnackbarData,
          panelClass: 'custom-snackbar',
          verticalPosition: 'top',
          horizontalPosition: 'end'
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

}
