import {
  animate,
  animation,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

const resetRoute = [
  style({ position: 'relative' }),
  query(
    ':leave',
    [
      style({
        position: 'absolute', // using absolute makes the scroll get stuck in the previous page's scroll position on the new page
        top: 0, // adjust this if you have a header so it factors in the height and not cause the router outlet to jump as it animates
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        opacity: 1,
      }),
    ],
    { optional: true }
  ),
];

export const routerAnimations = [
  trigger('routeAnimations', [
    transition('* <=> *', [
      /*        query(':enter, :leave', style({position: 'absolute', width: '100%'}),{optional: true}),*/
      group([
        query(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.5s ease-in-out', style({ opacity: 1 })),
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in-out', style({ opacity: 0 })),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ]),
];

export const fadeIn = animation([
  style({ opacity: 0 }), // start state
  animate('{{time}}', style({ opacity: 1 })),
]);

export const fadeOut = animation([animate('{{time}}', style({ opacity: 0 }))]);

export const slide = animation([
  style({ transform: 'translateX({{distanceFrom}})' }),
  animate('{{time}}', style({ transform: 'translateX({{distanceTo}})' })),
]);

export const slideLeftIn = animation([
  style({ opacity: 0, transform: 'translateX(100%)' }),
  animate('{{time}}', style({ opacity: 1, transform: 'translateX(0%)' })),
]);

export const slideLeftOut = animation([
  animate('{{time}}', style({ transform: 'translateX(-100%)', opacity: 0 })),
]);

export const slideRightIn = animation([
  style({ opacity: 0, transform: 'translateX(-100%)' }),
  animate('{{time}}', style({ opacity: 1, transform: 'translateX(0%)' })),
]);

export const slideRightOut = animation([
  animate('{{time}}', style({ opacity: 0, transform: 'translateX(100%)' })),
]);

export const scaleIn = animation([
  style({ opacity: 0, transform: 'scale(0.5)' }), // start state
  animate(
    '{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)',
    style({ opacity: 1, transform: 'scale(1)' })
  ),
]);

export const scaleOut = animation([
  animate(
    '{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)',
    style({ opacity: 0, transform: 'scale(0.5)' })
  ),
]);
