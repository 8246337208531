<!-- MOBILE SIDEBAR -->
<div
  class="side-bar mobile"
  *ngIf="isMobileSidebarOpen"
  @inOutAnimation
  #mobileNav
>
  <div style="display: flex; align-items: center">
    <div class="top-bar">
      <div class="logo">
        <a
          [routerLink]="
            '/' + mrdService.currentLanguage.toLowerCase() + '/home'
          "
          style="display: flex"
        >
          <img [src]="logoSrc$ | async" class="logo" />
        </a>
      </div>
      <div class="item fixed-size">
        <button mat-icon-button (click)="isMobileSidebarOpen = false">
          <mat-icon class="red-icon">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="navigation"></ng-container>
  <div class="languages">
    <marlenka-language-selector></marlenka-language-selector>
  </div>
</div>

<!-- MAIN CONTAINER -->
<div class="main-bar">
  <!-- TOOLBAR -->
  <div class="toolbar" [class]="headerClassList$ | async">
    <div class="container flex">
      <!-- logo + hamburger on sm -->
      <div fxShow fxHide.gt-xs class="menu">
        <button mat-icon-button (click)="isMobileSidebarOpen = true">
          <mat-icon class="red-icon">menu</mat-icon>
        </button>
      </div>

            <div fxShow fxHide.lt-sm class="menu menu-left">
                <a [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/home'" style="display: flex">
                    <img [src]="logoSrc$ | async" class="logo"/>
                </a>
                <ng-container *ngTemplateOutlet="navigation"></ng-container>

            </div>

            <div fxShow fxHide.lt-sm class="menu menu-right">

                <div class="fx-col">
                    <marlenka-language-selector></marlenka-language-selector>
                    <!--
                  dw i get paid for this
                  <button id="partner-button" mat-stroked-button>
                    <span class="text">Pro partnery</span>
                    <mat-icon>person</mat-icon>
                  </button>
                  -->
        </div>
      </div>
    </div>
  </div>

  <!-- CONTENT -->
  <main
    class="content router-wrapper"
    [@routeAnimations]="prepareRoute(outlet)"
    echoNxScrollable
  >
    <router-outlet #outlet="outlet"></router-outlet>

    <!-- FOOTER   -->
    <marlenka-footer
      *ngIf="!(hideFooter$ | async)"
      [rows]="footerRows$ | async"
      [classList]="footerClassList$ | async"
    ></marlenka-footer>
  </main>
</div>

<ng-template #navigation>
  <div class="navigation-wrapper">
    <div class="navigation" *ngIf="navigation$ | async as navigation">
      <div class="main">
        <a
          *ngFor="let page of navigation.pages"
          routerLinkActive="active"
          [routerLink]="page.identifier | marlenkaRouterLink"
        >
          <mat-icon *ngIf="page.icon; else title">{{ page.icon }}</mat-icon>
          <ng-template #title> {{ page.title }}</ng-template>
        </a>
      </div>

      <div class="anchors">
        <!-- Dynamically added subnav items have preference before defaults in navigation -->
        <ng-container *ngIf="subnavigation$ | async as subnav">
          <div
            echoNxHorizontalScrollable
            #echoNxHorizontalScrollable="echoNxHorizontalScrollable"
            class="anchor fx-row active"
            [class.overflowed]="echoNxHorizontalScrollable.isOverflow"
          >
            <button
              class="scroll-button left"
              *ngIf="echoNxHorizontalScrollable.isOverflow"
              [class.disable]="!echoNxHorizontalScrollable.canScrollStart"
              (click)="echoNxHorizontalScrollable.scroll(-1)"
            >
              <
            </button>
            <button
              class="scroll-button right"
              *ngIf="echoNxHorizontalScrollable.isOverflow"
              [class.disable]="!echoNxHorizontalScrollable.canScrollStart"
              (click)="echoNxHorizontalScrollable.scroll(1)"
            >
              >
            </button>
            <a *ngFor="let item of subnav" [routerLink]="item.link">
              {{ item.title }}
            </a>
          </div>
        </ng-container>
        <ng-container
          *ngIf="!(subnavigation$ | async)"
          echoNxHorizontalScrollable
          #echoNxHorizontalScrollable="echoNxHorizontalScrollable"
        >
          <button
            class="scroll-button"
            *ngIf="echoNxHorizontalScrollable.isOverflow"
            [class.disable]="!echoNxHorizontalScrollable.canScrollStart"
            (click)="echoNxHorizontalScrollable.scroll(-1)"
            id="scroll-left"
          >
            &#8678;
          </button>
          <div
            *ngFor="let page of navigation.pages"
            class="anchor fx-row"
            [class.active]="
              page.identifier === (mrdService.routeData$ | async)?.identifier
            "
          >
            <a
              *ngFor="let anchor of page.anchors"
              [routerLink]="page.identifier | marlenkaRouterLink"
              [fragment]="anchor.link"
            >
              {{ anchor.title }}
            </a>
          </div>
          <button
            class="scroll-button"
            *ngIf="echoNxHorizontalScrollable.isOverflow"
            [class.disable]="!echoNxHorizontalScrollable.canScrollStart"
            (click)="echoNxHorizontalScrollable.scroll(1)"
            id="scroll-right"
          >
            &#8670;
          </button>
        </ng-container>
      </div>
    </div>

    <div class="eshop-button">
      <a
        href="https://www.emarlenka.cz/"
        target="_blank"
        mat-button
        class="button primary"
        >E-shop</a
      >
      <a
        href="https://marlenka.us/"
        target="_blank"
        mat-button
        class="button primary"
        >E-shop USA</a
      >
    </div>
  </div>
</ng-template>
