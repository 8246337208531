<mat-form-field appearance="outline" fxFlex [formGroup]="formGroup">
  <mat-label>{{ settings.nazev }}</mat-label>

  <mat-chip-grid #chipList [disabled]="disabled">
    <mat-chip *ngFor="let item of innerData" (removed)="remove(item)">
      {{ item.label }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input placeholder="Nová položka..."
           [matChipInputFor]="chipList"
           [matChipInputAddOnBlur]="true"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-grid>

  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>
  <!-- MAT-ERROR NOT WORKING WITH MAT CHIP LIST SO WE HAVE OUR OWN -->
  <!-- ERROR -->
  <mat-error *ngIf="firstErrorKey$ | async as firstErrorKey" >
    {{firstErrorKey.key | translate: firstErrorKey.params}}
  </mat-error>
</mat-form-field>
