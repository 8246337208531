export interface ISliderDesign {
  primaryColor?: string;
  secondaryColor?: string;
  textColor?: string;

  hideIndicators?: boolean;
  hideIndicatorButtons?: boolean;
  animationStyle?: AnimationStyle;
  imageFit?: ImageFit;
}

export interface ISliderData {
  title?: string;
  subtitle?: string;
  description?: string;
  imgUrl?: string;
  alt?: string;
}

export enum AnimationStyle {
  none = 'none',
  slide = 'slide',
  fade = 'fade',
  scale = 'scale',
}

export enum ImageFit {
  none = 'none',
  fill = 'fill',
  cover = 'cover',
  scaleDown = 'scale-down',
  contain = 'contain',
}
