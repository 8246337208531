import gql from 'graphql-tag';

export const deleteContact = gql`
  mutation deleteContact($input: [String!]!) {
    response: deleteContact(ids: $input) {
      error
      description
      userMessage
    }
  }
`;
