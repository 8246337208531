import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {
  IBaseNavigationListData,
  IBaseNavigationListDesign,
  IEpxColorful,
  IEpxIcon,
  INavigationItem,
} from '@echo-nx/shared/common';

@Component({
  selector: 'echo-nx-navigation-list',
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.scss'],
})
export class NavigationListComponent
  implements IBaseNavigationListData, IBaseNavigationListDesign
{
  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal' || this.fluid === 'both',
      flex: this.fluid === 'horizontal' || this.fluid === 'both',
      'h-full': this.fluid === 'vertical' || this.fluid === 'both',
    };
  }

  @Output()
  itemClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  hideTitle?: boolean;

  @Input()
  applyBorder?: boolean;

  @Input()
  bgColor: string;

  @Input()
  hoverColorful: IEpxColorful;

  @Input()
  navigation: INavigationItem;

  @Input()
  activeColor: IEpxColorful;

  @Input()
  divideSize: 'small' | 'medium' | 'large';

  @Input()
  dividerColor: string;

  @Input()
  startIcon: IEpxIcon;

  @Input()
  titleColor: string;

  @Input()
  textColor: string;

  @Input()
  orientation: 'col' | 'row' | 'col-reverse' | 'row-reverse' = 'col';

  @Input()
  fluid;

  @Input()
  set design({
    hoverColorful,
    textColor,
    activeColor,
    dividerColor,
    titleColor,
    hideTitle,
    divideSize,
    startIcon,
    orientation,
    fluid,
    bgColor,
    applyBorder,
  }: IBaseNavigationListDesign) {
    this.hoverColorful = hoverColorful;
    this.textColor = textColor;
    this.activeColor = activeColor;
    this.dividerColor = dividerColor;
    this.bgColor = bgColor;
    this.applyBorder = applyBorder;
    this.titleColor = titleColor;
    this.hideTitle = hideTitle;
    this.divideSize = divideSize;
    this.startIcon = startIcon;
    this.fluid = fluid;
    this.orientation = orientation || 'col';
  }
}
