import {Component} from '@angular/core';
import {BasePhotoCardComponent} from "../base-photo-card.component";
import { BaseItemCardComponent } from '../../item-cards';

@Component({
  selector: 'echo-nx-photo-card-variant-nyrov',
  templateUrl: './photo-card-variant-nyrov.component.html',
  styleUrls: ['./photo-card-variant-nyrov.component.scss']
})
export class PhotoCardVariantNyrovComponent extends BaseItemCardComponent {


}
