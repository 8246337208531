import { Component, Inject, Injector, NgZone, Optional, ViewEncapsulation } from '@angular/core';
import { DYNAMIC_COMPONENT_INPUT } from "@echo-nx/shared/ng/feature/common";
import FroalaEditor, { FroalaOptions } from "froala-editor"
import { defaultFroalaOptions } from "../wysiwyg.constants";
import { take, } from "rxjs/operators";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/cs.js';
import { BASE_MEDIUM_SERVICE_TOKEN } from "@echo-nx/shared/ng/data-access";
import { BaseFormFieldComponent } from "../../base-form-field.component";
import { IWysiwygFieldSettings } from "./i-wysiwyg-field.settings";
import { FormGroupDirective } from "@angular/forms";
import { IMedium } from "@echo-nx/shared/common";
import { DataCardDialogComponent, IDataCardDialogSettings } from "../../../../../data-card";
import { WYSIWYG_FORMFIELD_CONFIG, WysiwygFormFieldConfig } from "./config";
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
import { onCopyCut, onPaste } from './froala.functions';

@Component({
  selector: 'echo-nx-wysiwyg-field',
  templateUrl: './wysiwyg-field.component.html',
  styleUrls: ['./wysiwyg-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WysiwygFieldComponent extends BaseFormFieldComponent<IWysiwygFieldSettings> {
  private mediaDialogRef?: MatDialogRef<
    DataCardDialogComponent,
    Record<string, any>[]
  >;
  public editor!: FroalaEditorModule & any;
  private editorInstance = null;

  froalaOptions: Partial<FroalaOptions> = {
    ...defaultFroalaOptions,
  };

  textChanged(value: string) {
    // console.log('textValue Changed',value);
    const div = document.createElement('div');
    div.innerHTML = value;
    div.replaceChildren(...Array.from(div.childNodes).map(x => this.fixNode(x)));
    this.formControl.setValue(div.innerHTML);
  }

  fixNode(node: Node): Node {
    if(node.nodeName === 'IMG'){
      const imgElement = node as HTMLImageElement;
      if(imgElement.style.width){
        imgElement.width = Number.parseFloat(imgElement.style.width.replace('px', ''));
      }
      if(imgElement.style.height){
        imgElement.height = Number.parseFloat(imgElement.style.height.replace('px', ''));
      }
      return imgElement;
    }
    if(node.hasChildNodes()){
      node.childNodes.forEach(child => node.replaceChild(child, this.fixNode(child)));
      return node;
    }
    return node;
  }

  constructor(
    public dialog: MatDialog,
    public zone: NgZone,
    formGroupDirective: FormGroupDirective,
    injector: Injector,
    @Optional() @Inject(DYNAMIC_COMPONENT_INPUT) entityDefinition?: any,
    @Optional()
    @Inject(WYSIWYG_FORMFIELD_CONFIG)
    config?: WysiwygFormFieldConfig
  ) {
    super(entityDefinition, formGroupDirective, injector);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctx = this;

    this.froalaOptions = {
      ...defaultFroalaOptions,
      ...(config ?? {}),
      autofocus: false,
      events: {
        contentChanged: () => {
          this.textChanged(this.editor.html.get());
        },
        initialized: function () {
          ctx.disableFroala(this);
          ctx.getInitialValue();
          this.undo.saveStep();
        },
        focus: function () {
          ctx.disableFroala(this);
        },
        click: function () {
          ctx.disableFroala(this);
        },
        blur: function () {
          ctx.disableFroala(this);
        },
        "window.copy": onCopyCut,
        "window.cut": onCopyCut,
        "paste.before": onPaste,
      },
    };
  }

  private disableFroala(editor: any) {
    if (this.disabled) {
      //WTF WHY DO I HAVE TO DO THIS MUCH
      editor.toolbar.disable();
      editor.edit.disableDesign();
      editor.events.disableBlur();
      editor.edit.off();
    }
  }

  private getInitialValue() {
    //set froala with entity value
    /*this.entity?.pipe(takeUntil(this.isDestroyed$)).subscribe(
      ent => {
        this.editor.html.set(ent[this.settings.formControlName]);
      }
    )*/
  }

  async initializeFroala(initControls: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;

    FroalaEditor.DefineIcon('openPicker', { NAME: 'info', SVG_KEY: "insertImage" });
    FroalaEditor.RegisterCommand('openPicker', {
      title: 'Vybrat médium',
      type: 'button',
      focus: false,
      undo: true,
      callback: function (iconName, clickedValue) {
        // The current context is the editor instance.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component.zone.run(() => component.openMediaDialog(this));
        return true;
      },
    });

    initControls.initialize();
    this.editor = initControls.getEditor();
  }

  private openMediaDialog(editor: FroalaEditor) {
    const dialogConfig = this.getDialogConfig();
    this.mediaDialogRef = this.dialog.open(
      DataCardDialogComponent,
      dialogConfig
    );

    // after close clear the ref and get the result
    this.mediaDialogRef.afterClosed().pipe(
      take(1)
    ).subscribe((res) => {
      if (res && res.length > 0) {
        const { url, name, _id } = (res as IMedium[])[0];
        editor.image.insert(url ?? '', true, { 'name': name, 'id': _id }, null, {});
      }
      this.mediaDialogRef = undefined
    });
  }

  private getDialogConfig(): MatDialogConfig<IDataCardDialogSettings> {
    const { mediaDialogSettings } = this.settings;
    const dialogConfig = new MatDialogConfig<IDataCardDialogSettings>();
    dialogConfig.data = {
      // use provided one or default
      ...(mediaDialogSettings ?? {
        title: 'Média',
        entityServiceData: {
          token: BASE_MEDIUM_SERVICE_TOKEN,
        },
        selectOnItemClick: true,
        viewType: 'grid',
      }),
    };
    dialogConfig.width = '85%';
    // dialogConfig.maxWidth = '95vw';
    // dialogConfig.maxHeight = '95vh';
    // dialogConfig.height = '100%';
    // dialogConfig.width = '100%';
    return dialogConfig;
  }
}
