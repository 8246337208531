import {IEntityDefinition} from "../../interfaces";
import {
  IEntityPickerFieldSettings,
  IGroupFieldSettings,
  ISelectFieldSettings,
} from "../../modules/form-fields";
import {Validators} from "@angular/forms";
import {of} from "rxjs";
import {mediaDialogSettings, routeToEntityDefinition} from "../entity-definition-helpers";

export const getImageWidgetEntityDefinition = (): IEntityDefinition[] => {
  return [
    {
      name: 'PkInputGroupFieldComponent',
      settings: {
        nazev: 'Nastavení',
        formControlName: 'settings',
        withoutCard: true,
        groupDefinition: [
          {
            name: 'EntityPickerFieldComponent',
            settings: {
              nazev: 'Hlavní obrázek',
              povinnost: true,
              formControlName: 'image',
              preview: {
                style: 'media',
                keys: {
                  name: ['name'],
                  url: ['url']
                }
              },
              dialogSettings: mediaDialogSettings,
              //transformFn: transformSingularId
            } as IEntityPickerFieldSettings,

            data: null,
            validators: [Validators.required],
            cols: 12,
          },
          {
            name: 'PkSelectFieldComponent',
            settings: {
              nazev: 'Škálování',
              defaultValue: 'cover',
              formControlName: 'objectFit'
            } as ISelectFieldSettings,
            data: of([
              {
                displayValue: 'Vyplnit celou plochu a zachovat poměr stran (cover)',
                _id: 'cover'
              },
              {
                displayValue: 'Vyplnit celou plochu (fill)',
                _id: 'fill'
              },
              {
                displayValue: 'Vyplnit dle nejdelší strany (contain)',
                _id: 'contain'
              },
              {
                displayValue: 'Žádné (none)',
                _id: 'none'
              },
            ]),
            validators: [],
            cols: 12,
          },
        ]
      } as IGroupFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    },
    routeToEntityDefinition,

  ];
}
