<!--TODO: need to be implemented-->
<div
  class="flex flex-wrap gap-4 px-2 py-1"
  [ngClass]="[
    fluid === 'horizontal' ? 'flex-1' : '',
    textColor | prefix: 'text',
    primaryColor | prefix: 'bg'
  ]"
>
  <span class="flex gap-2 items-center">
    <span>Dnes je {{ dateTime$ | async }}.</span>
  </span>

  <ng-container *ngIf="nameAndHoliday$ | async; let nameAndHoliday">
    <span *ngIf="nameAndHoliday.holiday">{{ nameAndHoliday.holiday }}.</span>

    <span *ngIf="nameAndHoliday.name"
      >Svátek má {{ nameAndHoliday.name }}.</span
    >
  </ng-container>
</div>
