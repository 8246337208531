import {
  Component,
  EventEmitter,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {EMPTY, merge, Subject} from "rxjs";
import {catchError, debounceTime, map, skip, switchMap, takeUntil} from "rxjs/operators";
import {FetchAllArgs, IEntityService} from "@echo-nx/shared/common";
import {Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {IInjectableServiceData} from "@echo-nx/shared/ng/feature/common";
import {IColumnDefinition} from "./ui/table/i-column-definition";
import {DataViewType, IDataEntityService} from "./interfaces";
import {RecordSelectionModel} from "./utils/record-selection-model";
import {IGridDefinition} from "./ui/grid/i-grid-definition";
import {
  getFilterValue,
  getPaginationValue,
  getSearchValue,
  getSortValue,
} from "./utils/filter-utils";
import {DataCardStateService} from "./utils/data-card-state.service";
//import {CmsAlertService} from "@echo-nx/shared/ng/feature/cms";

const DATA_CARD_FILTER_TOKEN = new InjectionToken('DATA_CARD_FILTER_TOKEN');

@Component({
  selector: 'echo-nx-data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.scss'],
  providers: [
    {
      provide: DATA_CARD_FILTER_TOKEN,
      useClass: DataCardStateService
    }
  ],
})
export class DataCardComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true})
  private paginator!: MatPaginator;

  private isDestroyed$ = new Subject<boolean>();

  public selection!: RecordSelectionModel;

  @Input()
  public initiallySelectedValues?: Record<string, any>[];

  @Input()
  public canSelectMultiple?: boolean;

  @Input()
  public selectOnItemClick?: boolean = false;

  @Input()
  public title!: string;

  public _filterServiceData!: IInjectableServiceData;

  @Input()
  set filterServiceData(isd: IInjectableServiceData) {
    // default to using own token

    this._filterServiceData = isd || {token: DATA_CARD_FILTER_TOKEN};
    //console.log(this._filterServiceData);
  }

  @Input()
  filterDefinition?: any[];


  @Input()
  public entityServiceData!: IInjectableServiceData;

  @Input()
  public hideFilters?: boolean;

  @Input()
  public hideSearch?: boolean;

  @Input()
  public viewType!: DataViewType;

  @Output()
  public itemClicked = new EventEmitter(); // output from table, list or grid

  public filterService!: DataCardStateService;
  public entityService!: IDataEntityService & IEntityService;

  @Input()
  public gridItemDefinition?: IGridDefinition;

  @Input()
  public listItemDefinition?: [];

  @Input()
  public tableItemDefinition?: IColumnDefinition[];

  public tableViewDefinitionCounter = 0;

  public data: any[] = [];
  public total = 0;
  public isLoading = false;
  private _refetchHandler = new Subject<void>();

  public defaultSort?: Sort;

  constructor(private injector: Injector/*, private _alertService: CmsAlertService*/) {

  }

  ngOnInit(): void {
    /**
     * This is smart component for displaying entities in the form of table, list or grid.
     * Mandatory @Inputs:
     *    - entityServiceData; necessary to fetch the correct data
     * Optionally @Inputs:
     *    - filterServiceData; to control the filtering of data from outside.
     *    - withFilters; set to true to spawn filters inside the card and let the smart component take care of filtering on its own.
     */
    this.injectFilterService(this._filterServiceData);
    this.injectEntityService(this.entityServiceData);

    this.gridItemDefinition = this.gridItemDefinition ? this.gridItemDefinition : (this.entityService.getGridItemDefinition ? this.entityService.getGridItemDefinition() : undefined);
    this.listItemDefinition = this.listItemDefinition ? this.listItemDefinition : (this.entityService.getListItemDefinition ? this.entityService.getListItemDefinition() : undefined);
    this.tableItemDefinition = this.tableItemDefinition ? this.tableItemDefinition : (this.entityService.getColumnDefinition ? this.entityService.getColumnDefinition() : undefined);
    this.filterDefinition = this.filterDefinition ? this.filterDefinition : (this.entityService.getFilterEntityDefinition ? this.entityService.getFilterEntityDefinition() : undefined);

    this.tableViewDefinitionCounter = [this.gridItemDefinition, this.listItemDefinition, this.tableItemDefinition].filter((value) => !!value).length;

    // helper class to keep track of selected items
    this.selection = new RecordSelectionModel<Record<string, any>>(this.canSelectMultiple, this.initiallySelectedValues);

    /* SET DEFAULTS FROM filterService */

    // SET PAGINATOR DEFAULTS
    const paginatorFormGroup = this.filterService.getPaginationControl();
    const {pageSize, pageIndex} = paginatorFormGroup.value;
    this.paginator.pageSize = pageSize;
    this.paginator.pageIndex = pageIndex;

    // SET SORT DEFAULTS
    const sortFilterGroup = this.filterService.getSortControl();
    this.defaultSort = sortFilterGroup.value;

    // SET ViewType Defaults
    const viewTypeControl = this.filterService.getDataViewTypeControl();
    this.viewType = viewTypeControl.value ?? this.viewType ?? 'table';

    // search observable
    const searchText$ = this.filterService.getSearchControl().valueChanges.pipe(debounceTime(375))

    // reset paginator when searching, sorting or filtering
    merge(
      sortFilterGroup.valueChanges,
      searchText$,
      this.filterService.getFiltersControl().valueChanges
    ).pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(() => {
      this.paginator.pageIndex = 0;

      //manually setting paginator will not trigger change in filterService so we manually update
      paginatorFormGroup.patchValue({pageIndex: 0}, {emitEvent: true});
    });

    // we could possibly use separate formcontrols to prevent refetching when viewType changes.
    merge(
      searchText$,
      sortFilterGroup.valueChanges,
      this.filterService.getPaginationControl().valueChanges,
      this.filterService.getFiltersControl().valueChanges.pipe(skip(1)), // skip the initial value change when form is created with default values
      this._refetchHandler.asObservable()
    ).pipe(
      takeUntil(this.isDestroyed$),
      debounceTime(125),
      switchMap(() => {
        const dataCardState = this.filterService.filterForm.value;
        this.isLoading = true;
        const {active, direction} = getSortValue(dataCardState);
        const {pageSize, pageIndex} = getPaginationValue(dataCardState);
        const search = getSearchValue(dataCardState);
        const {transformFilterValue} = this.entityService;
        const filterValue = getFilterValue(dataCardState);

        const args: FetchAllArgs = {
          sortColumn: active,
          sortDirection: direction === 'asc' ? 'ASC' : 'DESC',
          take: pageSize ?? 10,
          page: pageIndex ?? 0,
          search,
          includeNotPublished: true,
          filter: transformFilterValue ? transformFilterValue(filterValue) : undefined
        };
        const {fetchFunction} = this.entityServiceData;
        return (fetchFunction ? fetchFunction(this.entityService, this.injector, args, ) : this.entityService.fetchAll(args)).pipe(
          takeUntil(this.isDestroyed$),
          catchError((err, caught) => {
            // todo this is circular dependency. CmsAlertService
            /*this._alertService.show({data: {
                type: 'error',
                text: err,
              },
              duration: 2500});*/
            this.isLoading = false;
            return EMPTY;
          })
        );
      }),
      // delay(5000),
      map(({items, total}) => {
        this.isLoading = false;
        this.total = total;
        return items;
      }),
    ).subscribe(items => this.data = items);
    //initial data load
    this.refetchData();
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  // to trigger refetch from outside
  public refetchData() {
    this._refetchHandler.next();
  }

  private injectFilterService(filterServiceData: IInjectableServiceData) {
    /* if (!this.withFilters) {
          console.log('Data card without filters - not injecting filter service. Filter service data is:', filterServiceData)
          return;
        }
    */

    // every dataCard needs a filterService because it handles pagination and sorting now
    const {token} = filterServiceData;
    this.filterService = this.injector.get(token);
  }

  private injectEntityService(entityServiceData: IInjectableServiceData) {
    const {token} = entityServiceData;
    this.entityService = this.injector.get(token);
  }

  public onItemClicked(item: any) {
    this.itemClicked.emit(item);

    if (this.selectOnItemClick) {
      this.onItemSelected(item);
    }
  }

  public onSortChanged(sort: Sort) {
    this.filterService.getSortControl().patchValue(sort);
  }

  public onPageChanged(page: PageEvent) {
    this.filterService.getPaginationControl().patchValue(page);
  }

  public onViewTypeChanged(viewType: DataViewType) {
    this.filterService.getDataViewTypeControl().patchValue(viewType);
    this.viewType = viewType;
  }

  public onItemSelected(item: any) {
    this.selection.toggle(item);
  }

  public onMasterSelected() {
    // if you find item on page that is not selected, we will be selecting
    const shouldSelect = !!this.data.find(item => !this.selection.isSelected(item));

    shouldSelect ? this.selection.select(...this.data) : this.selection.deselect(...this.data);
  }
}
