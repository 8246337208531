import gql from 'graphql-tag';

// for frontend:
export const readPageSettingsByIdentifier = gql`
  query ReadPageSettingsByIdentifier(
    $identifier: MarlenkaPageIdentifier!
    $language: String!
  ) {
    response: readPageSettingsByIdentifier(
      identifier: $identifier
      language: $language
    ) {
      ... on CommonpageSettingsObject {
        _id
        language
        languageGroupId
        title
        icon
        identifier
        logo {
          _id
          name
          description
          mime
          type
          url
        }
        texts {
          footer {
            links {
              privacyTermsLink
              privacyTerms
              newDocument
              newDocumentLink
              cookieTerms
            }
            becomePartner {
              title
              subtitle
            }
            company {
              title
              subtitle
            }
            customerCare {
              title
              subtitle
            }
            faq {
              title
              subtitle
            }
            merchandise {
              title
              subtitle
            }
            payments {
              title
              subtitle
            }
            reception {
              title
              subtitle
            }
            service {
              title
              subtitle
            }
            writeUs {
              title
              subtitle
            }
            ico {
              title
              subtitle
            }
            dic {
              title
              subtitle
            }
            bank {
              title
              subtitle
            }
            dataBox {
              title
              subtitle
            }
            accountNo {
              title
              subtitle
            }
            swift {
              title
              subtitle
            }
            iban {
              title
              subtitle
            }
            tel {
              title
              subtitle
            }
            email {
              title
              subtitle
            }
            web {
              title
              subtitle
            }
            court
          }
          buttons {
            readMore
            readLess
            archiveVideos
            archiveAwards
            archiveCertificates
            archiveArticles
            otherPhotos
          }
        }
      }
      ... on HomepageSettingsObject {
        _id
        language
        languageGroupId
        title
        icon
        identifier
        texts {
          menuBanner {
            products {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
              link
            }
            aboutUs {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
              link
            }
            achievements {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
              link
            }
            cakes {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
              link
            }
            jobs {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
              link
            }
            events {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
              link
            }
          }
          articles {
            title
            subtitle
          }
          reviews {
            title
            subtitle
          }
          social {
            title
            subtitle
          }
        }
        newsArticleCategory {
          _id
          name
          language
          languageGroupId
        }
        reviewsArticleCategory {
          _id
          name
          language
          languageGroupId
        }
      }
      ... on AboutpageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          jumbotron {
            title
            subtitle
          }
          history {
            title
            subtitle
          }
          achievements {
            title
            subtitle
          }
          certificates {
            title
            subtitle
          }
          videos {
            title
            subtitle
          }
        }
        awardsArticleCategory {
          _id
          name
          language
          languageGroupId
        }
        certificatesArticleCategory {
          _id
          name
          language
          languageGroupId
        }
        videosArticleCategory {
          _id
          name
          language
          languageGroupId
        }
      }
      ... on ProductpageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          jumbotron {
            title
            subtitle
          }
        }
      }
      ... on CakesExclusiveSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          jumbotron {
            title
            subtitle
          }
        }
      }
      ... on CakesExclusiveDetailSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          jumbotron {
            title
            subtitle
          }
        }
      }
      ... on ExcursionpageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        storeGallery {
          _id
          featuredImage {
            mime
            type
            url
          }
          media {
            mime
            type
            url
          }
          name
          description
        }
        excursionGallery {
          _id
          featuredImage {
            mime
            type
            url
          }
          media {
            mime
            type
            url
          }
          name
          description
        }
        cafeGallery {
          _id
          featuredImage {
            mime
            type
            url
          }
          media {
            mime
            type
            url
          }
          name
          description
        }
        texts {
          menuBanner {
            main {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
            }
            info {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
              link
            }
            reservation {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
              link
            }
            virtualTour {
              title
              subtitle
              backgroundImg {
                _id
                url
                thumbnailUrl
                name
                mime
                description
                type
              }
              link
            }
          }
          infoJumbotron {
            title
            content
            medium {
              _id
              url
              thumbnailUrl
              name
              mime
              description
              type
            }
          }
          tourProgramJumbotron {
            title
            content
            medium {
              _id
              url
              thumbnailUrl
              name
              mime
              description
              type
            }
          }
          virtualTourJumbotron {
            title
            content
            medium {
              _id
              url
              thumbnailUrl
              name
              mime
              description
              type
            }
          }
          presentationJumbotron {
            title
            content
            medium {
              _id
              url
              thumbnailUrl
              name
              mime
              description
              type
            }
          }
          reservationJumbotron {
            title
            content
            medium {
              _id
              url
              thumbnailUrl
              name
              mime
              description
              type
            }
            enterReservationSystem {
              title
              subtitle
            }
            medium {
              _id
              url
              thumbnailUrl
              name
              mime
              description
              type
            }
          }
          contentJumbotron {
            title
            content
            medium {
              _id
              url
              thumbnailUrl
              name
              mime
              description
              type
            }
          }
        }
      }
      ... on DistributionpageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          jumbotron {
            title
            subtitle
          }
        }
      }
      ... on BlogspageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          jumbotron {
            title
            subtitle
          }
        }
      }
      ... on AwardspageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          jumbotron {
            title
            subtitle
          }
        }
      }
      ... on CareerpageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          jumbotron {
            title
            subtitle
          }
        }
        careerArticleCategory {
          _id
          name
          language
          languageGroupId
        }
      }
      ... on CreateReservationSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          warning {
            title
            subtitle
          }
          voucherBannerText {
            title
            subtitle
          }
        }
      }
      ... on CreatePageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          jumbotron {
            title
            subtitle
          }
        }
      }
      ... on ContactpageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        title
        icon
        texts {
          workingHours
          jumbotron {
            title
            subtitle
          }
        }
      }
      ... on ProductDetailpageSettingsObject {
        _id
        language
        languageGroupId
        identifier
        icon
        title
        texts {
          boxDimensions {
            title
          }
          cakeCountOfMassPackaging {
            title
          }
          cakeCountOfPalletPackaging {
            title
          }
          dimensionsOfMassPackaging {
            title
          }
          ean {
            title
          }
          eanOfMassPackaging {
            title
          }
          layerCountOfPalletPackaging {
            title
          }
          massPackageCountOfPalletPackaging {
            title
          }
          massPackageLayerCountOfPalletPackaging {
            title
          }
          massPackaging {
            title
          }
          palletPackaging {
            title
          }
          storage {
            title
          }
          storageTemperatureFrom {
            title
          }
          storageTemperatureTo {
            title
          }
          weight {
            title
          }
          weightOfMassPackaging {
            title
          }
          units {
            centimetres
            millimetres
            grams
            pieces
          }
          readMore
          readLess
          goBack
        }
      }
    }
  }
`;
