import {Component, HostBinding, Input} from '@angular/core';
import {IBaseWeatherData, IBaseWeatherDesign, IEpxIcon} from "@echo-nx/shared/common";

@Component({
  selector: 'echo-nx-weather-data-variant-b',
  templateUrl: './weather-data-variant-b.component.html',
  styleUrls: ['./weather-data-variant-b.component.scss']
})
export class WeatherDataVariantBComponent implements IBaseWeatherData, IBaseWeatherDesign {

  @Input()
  accentColor?: string;

  @Input()
  description?: string;

  @Input()
  primaryColor?: string;

  @Input()
  secondaryColor?: string;

  @Input()
  startIcon?: IEpxIcon;

  @Input()
  suffix?: string;

  @Input()
  textColor?: string;

  @Input()
  title?: string;

  @Input()
  value?: string;

  @Input()
  fluid?;

  @Input()
  titleSize?: string;

  @Input()
  valueSize?: string;

  @Input()
  suffixSize?: string;

  @HostBinding('class') get classList(): any {
    return {
      [`bg-${this.primaryColor}`]: !!this.primaryColor,
      [`text-${this.textColor}`]: !!this.textColor,
      "flex-1": this.fluid
    };
  }

  @Input()
  set data({title, description, value, suffix}: IBaseWeatherData) {
    this.title = title;
    this.description = description;
    this.value = value;
    this.suffix = suffix;
  }

  @Input()
  set design({textColor, primaryColor, startIcon, accentColor, secondaryColor, fluid, suffixSize, valueSize, titleSize}: IBaseWeatherDesign) {
    this.textColor = textColor;
    this.primaryColor = primaryColor;
    this.startIcon = startIcon;
    this.accentColor = accentColor;
    this.secondaryColor = secondaryColor;
    this.fluid = fluid;
    this.titleSize = titleSize ?? '2xl';
    this.valueSize = valueSize ?? 'xl';
    this.suffixSize = suffixSize ?? 'md';
  }

}
