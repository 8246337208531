import { Pipe, PipeTransform } from '@angular/core';
import {DateTime, DateTimeUnit} from "luxon";

@Pipe({
  name: 'dateAdd'
})
export class DateAddPipe implements PipeTransform {

  transform(value: Date, amount: number, unit: DateTimeUnit, format = 'HH:mm'): string {
    return DateTime.fromJSDate(value).plus({ [unit]: amount }).toFormat(format)
  }

}
