<div class="flex gap-x-2 items-center">
  <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript min-w-50" *ngIf="!hideSearch">
    <mat-icon
      matPrefix
      fontSet="fas"
      fontIcon="fa-search"></mat-icon>
    <input
      matInput
      [formControl]="searchControl"
      [autocomplete]="'off'"
      placeholder="Vyhledat...">
  </mat-form-field>
  <ng-container *ngIf="filterDefinition?.length > 0 && !hideFilters">
    <button
      mat-icon-button
      [color]="isPanelOpened() ? 'primary' : undefined"
      (click)="openPanel()"
      #filterPanelOpener>
      <mat-icon svgIcon="heroicons_outline:filter"
                [matBadgeHidden]="filterService.filterCount === 0"
                [matBadge]="filterService.filterCount"></mat-icon>
    </button>
  </ng-container>
</div>

<ng-template #filterPanel>

  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg border-primary-500 border">

    <!-- Header -->
    <div class="flex flex-shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
      <div class="sm:hidden -ml-1 mr-3">
        <button
          mat-icon-button
          (click)="closePanel()">
          <mat-icon
            class="icon-size-5 text-current"
            [svgIcon]="'heroicons_solid:x'"></mat-icon>
        </button>
      </div>
      <div class="text-lg font-medium leading-5">Filtrace</div>
      <div class="ml-auto">
        <button
          mat-icon-button
          [matTooltip]="'Zrušit všechny'"
          (click)="cancelEverything()">
          <mat-icon
            class="icon-size-5 text-current"
            [svgIcon]="'heroicons_solid:ban'"></mat-icon>
        </button>
      </div>
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card p-2">
      <!-- [form]="filterService.getFiltersControl()" -->
      <echo-nx-entity-form
        [form]="filterService.getFiltersControl()"
        [entityDefinitions]="filterDefinition"
      ></echo-nx-entity-form>
    </div>
  </div>
</ng-template>
