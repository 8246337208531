import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeBarVariantNyrovComponent } from './date-time-bar-variant-nyrov.component';
import { PrefixModule } from "../../prefix/prefix.module";
import { NAMES_AND_HOLIDAYS_SERVICE_SETTINS_TOKEN, NamesAndHolidaysService, NamesAndHolidaysServiceSettings } from '../names-holidays.service';


@NgModule({
  declarations: [
    DateTimeBarVariantNyrovComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    DateTimeBarVariantNyrovComponent
  ],
  providers: [
    NamesAndHolidaysService,
    {
      provide: NAMES_AND_HOLIDAYS_SERVICE_SETTINS_TOKEN,
      useValue: {
        dateFormat: 'yyyy-MM-dd',
        dayInfoUrl: 'https://svatkyapi.cz/api/day/{0}',
        holidayFieldName: 'holidayName',
        nameFieldName: 'name'
      } as NamesAndHolidaysServiceSettings
    }
  ]
})
export class DatetimeBarVariantNyrovModule {
}
