<div
  class="flex rounded-md relative overflow-hidden items-center justify-center"
  style="min-height: 325px"
  [ngClass]="[
    image ? '' : (primaryColor | prefix: 'bg'),
    fluid === 'vertical' || fluid === 'both' ? 'h-full' : 'h-96'
  ]"
  [class.h-full]="fluid === 'vertical' || fluid === 'both'"
  [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
>
  <img
    *ngIf="image"
    class="object-cover w-full h-full"
    [src]="image?.url"
    [alt]="image.alt"
  />

  <div *ngIf="!image" class="text-3xl mb-8">
    <i
      class="fas fa-photo-video"
      [ngClass]="[accentColor | prefix: 'text']"
    ></i>
  </div>

  <!-- date -->
  <div
    class="rounded-r-full p-1 px-4 absolute left-0 top-5 font-medium"
    [ngClass]="[
      dateText?.bgColor | prefix: 'bg',
      dateText?.color | prefix: 'text'
    ]"
  >
    {{ date }}
  </div>

  <div
    class="absolute bottom-0 left-0 right-0 flex flex-col p-4 bg-black bg-opacity-25"
  >
    <div
      class="text-xl font-medium text-white"
      [ngClass]="[titleText?.color | prefix: 'text']"
    >
      {{ title }}
    </div>

    <div class="flex gap-x-2">
      <span
        *ngFor="let cat of categories; let last = last"
        class="font-light text-white"
        >{{ cat.name }}<ng-container *ngIf="!last">,</ng-container></span
      >
    </div>
  </div>
</div>
