import { Component, OnInit, EventEmitter } from '@angular/core';
import {
  Cookie,
  CookieConsentDialog,
} from '@echo-nx/shared/ng/feature/cookies';
import { MarlenkaRouteData } from '@echo-nx/marlenka/common';
import { LanguageRouteDataService } from '@echo-nx/shared/ng/feature/language';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CustomizeCookiesComponent } from './customize-cookies/customize-cookies.component';

import { convertChoicesToResult, setAll } from './utils';
import { CookiesLangTexts } from './i-cookie-texts';
import { GoogleTagManagerService } from '@echo-nx/shared/ng/feature/gtm';

@Component({
  selector: 'marlenka-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit, CookieConsentDialog {
  public consentChanged = new EventEmitter<Cookie>();

  constructor(
    private gtmService: GoogleTagManagerService,
    public mrdService: LanguageRouteDataService<MarlenkaRouteData>,
    private dialog: MatDialog
  ) {}

  public texts: CookiesLangTexts = {
    cs: {
      title: 'Soubory cookies',
      content: `Tato webová stránka používá soubory cookie k zajištění správného fungování, analýze a marketingovým účelům. K jejich použití je vyžadován Váš výslovný souhlas. V souladu se
  <a href="https://marlenka.cz/assets/zasady_ochrany_osobnich_udaju.pdf" target="_blank">Zásadami ochrany osobních údajů</a> sdílíme informace o vašem užívání naší webové stránky také s
  našimi partnery v oblasti sociálních médií, reklamy a analýzy.`,
      cannotModify: 'Nelze měnit',
      buttons: {
        deny: 'Odmítnout vše',
        allow: 'Povolit vše',
        customize: 'Přizpůsobit',
        confirm: 'Uložit volby',
      },
      customizer: {
        title: 'Spravovat',
        choices: [
          {
            alwaysOn: true,
            title: 'Nezbytné',
            information:
              'Webová aplikace nemůže správně fungovat bez použití těchto nezbytných cookies. Jsou používány například v rámci navigace, rezervace a přepínání jazyků.',
            event: 'functionality_storage',
          },
          {
            title: 'Analytické',
            information:
              'Tyto cookies nám umožňují sledovat zdroje návštěv a jejich počet, aby bylo možné měřit a zlepšit výkonnost našeho webu. Pomáhají nám zjistit, které stránky jsou nejvíce populární a sledovat jak uživatelé navigují webem. Všechny informace jsou agregovány, což zaručuje jejich anonymnost. Pokud nepovolíte tyto cookies, nebudeme vědět, že jste navštívili náš web.',
            event: 'analytics_storage',
          },
          {
            title: 'Personalizace',
            information:
              'Tyto cookies umožňují přizpůsobení obsahu webu Vašim preferencím.',
            event: 'personalization_storage',
          },
          {
            title: 'Marketingové',
            information:
              'Marketingové cookies jsou používány pro sledování návštěvníku napříč webovými stránkami. Cílem je zobrazit reklamy, které jsou relevantní a poutavé pro individuální uživatele a tedy více cenné pro vydavatele a inzerenty třetích stran.',
            event: 'ad_storage',
          },
        ],
      },
    },
    en: {
      title: 'Cookie notice',
      content: `This website uses cookies to ensure analytic, marketing and language functionality. They will be used only if you give consent. With respect to our
  <a href="https://marlenka.cz/assets/zasady_ochrany_osobnich_udaju.pdf" target="_blank">Privacy policy</a> we share information about your usage of our website with our social media, advertisement and analysis partners.`,
      cannotModify: 'Cannot change',
      buttons: {
        deny: 'Reject all',
        allow: 'Allow all',
        customize: 'Customize',
        confirm: 'Confirm My Choices',
      },
      customizer: {
        title: 'Confirm My Choices',
        choices: [
          {
            alwaysOn: true,
            title: 'Necessary',
            information:
              'Necessary cookies help make a website usable by storing preferences and remember certain actions. They are used for navigation, reservation services and language switching. The website cannot function properly without these cookies.',
            event: 'functionality_storage',
          },
          {
            title: 'Analytic',
            information:
              'These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.',
            event: 'analytics_storage',
          },
          {
            title: 'Personalization',
            information:
              'These cookies allow us to personalize content by your preference.',
            event: 'personalization_storage',
          },
          {
            title: 'Marketing',
            information:
              'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
            event: 'ad_storage',
          },
        ],
      },
    },
    ru: {
      title: 'Файлы Cookies',
      content: `Данный сайт использует файлы cookie для надлежащего функционирования, анализа и маркетинговых целей. Для их <a href="https://marlenka.cz/assets/zasady_ochrany_osobnich_udaju.pdf" target="_blank">использования</a> требуется ваше согласие. В соответствии с нашей Политикой конфиденциальности мы также передаем информацию об использовании вами нашего сайта нашим партнерам по социальным сетям, рекламе и аналитике.`,
      cannotModify: 'Невозможно изменить',
      buttons: {
        deny: 'Oтключить все',
        allow: 'Включить все',
        customize: 'Настроить',
        confirm: 'Сохранить параметры',
      },
      customizer: {
        title: 'Настроить',
        choices: [
          {
            alwaysOn: true,
            title: 'Администрировать',
            information:
              'Веб-приложение не может функционировать должным образом без использования этих основных файлов cookie. Они используются, например, в контексте навигации, бронирования и переключения языков.',
            event: 'functionality_storage',
          },
          {
            title: 'Аналитические',
            information:
              'Эти файлы cookie позволяют нам отслеживать источники и количество посещений, чтобы мы могли измерять и улучшать эффективность работы нашего сайта. Они помогают нам определить, какие страницы пользуются наибольшей популярностью, и отследить, как пользователи перемещаются по сайту. Вся информация агрегируется, что гарантирует ее анонимность. Если вы не разрешите эти файлы cookie, мы не будем знать, что вы посетили наш сайт.',
            event: 'analytics_storage',
          },
          {
            title: 'Персонализация',
            information:
              'Эти файлы cookie позволяют адаптировать содержимое сайта к вашим предпочтениям.',
            event: 'personalization_storage',
          },
          {
            title: 'Маркетинг',
            information:
              'Маркетинговые файлы cookie используются для отслеживания посетителей по всему сайту. Цель заключается в том, чтобы подавать объявления, которые релевантны и привлекательны для отдельных пользователей и, следовательно, более ценны для издателей и сторонних рекламодателей.',
            event: 'ad_storage',
          },
        ],
      },
    },
  };

  public customize() {
    const dialogConfig = {
      autoFocus: true,
      data: this.texts,
      panelClass: 'cookies',
    } as MatDialogConfig;

    const dialogRef = this.dialog.open(CustomizeCookiesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      console.log('customizer closed with result', result);
      this.consentChanged.next(result);
    });
  }

  allowAll() {
    this.consentChanged.next(
      setAll(convertChoicesToResult(this.texts.cs.customizer.choices), true)
    );
  }

  denyAll() {
    this.consentChanged.next(
      setAll(convertChoicesToResult(this.texts.cs.customizer.choices), false)
    );
  }

  ngOnInit(): void {
    return;
  }
}
