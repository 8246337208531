<mat-card
  *ngIf="!settings.withoutCard; else formFields"
  class="rounded-xl overflow-hidden"
>
  <div class="header bg-accent" [style.background]="color">
    <mat-card-title
      >{{ settings.nazev
      }}<span style="color: red" *ngIf="settings.povinnost">*</span>
    </mat-card-title>
    <div *ngIf="!settings.hideAddButton">
      <ng-container *ngIf="settings.iconAddButton">
        <button mat-icon-button class="button" (click)="createItem()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="!settings.iconAddButton">
        <button
          mat-raised-button
          class="button"
          color="primary"
          (click)="createItem()"
        >
          + Nový řádek
        </button>
      </ng-container>

      <!-- if it has Expandable option and if have at least one item -->
      <button
        *ngIf="settings.expandable"
        mat-button
        (click)="expanded = !expanded"
      >
        <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>
    </div>
  </div>
  <mat-card-content>
    <ng-container *ngTemplateOutlet="formFields"></ng-container>
  </mat-card-content>
</mat-card>

<ng-template #formFields>
  <div
    cdkDropList
    (cdkDropListDropped)="onReorder($event)"
    [class]="'rows ' + settings.allowReorder ? 'py-4 pr-4' : 'p-4'"
    echoNxRepeaterComponentTree
    [entityFormService]="entityFormService"
    [repeaterControlName]="settings.formControlName"
    [style.display]="expanded ? '' : 'none'"
    [ngClass]="settings.withDivider ? 'flex flex-col gap-y-8' : ''"
  >
    <ng-container *ngIf="formControl">
      <!-- radky pomoci gridu-->
      <div
        cdkDrag
        class="fx-row"
        [class.reorder]="settings.allowReorder"
        *ngFor="let row of formControl.controls; let rowIndex = index"
      >
        <!-- DRAG BUTTON -->
        <div
          cdkDragHandle
          *ngIf="settings.allowReorder"
          style="grid-column: 1"
          [style.grid-row]="'1 / ' + getMaxY(settings.rowDefinition, 2)"
          class="flex items-center align-center pr-4"
        >
          <mat-icon>drag_indicator</mat-icon>
        </div>
        <div
          *ngFor="let field of settings.rowDefinition"
          [ngClass]="
            field.name === 'PkHiddenFieldComponent' ? 'hidden-field' : ''
          "
          [formGroup]="getFormGroupForRow(rowIndex)"
          [style.grid-row-start]="field.y ? field.y + 1 : undefined"
          [style.grid-column-start]="
            field.x ? field.x + (settings.allowReorder ? 2 : 1) : undefined
          "
          [style.grid-column-end]="
            field.x
              ? field.x + (settings.allowReorder ? 2 : 1) + field.cols
              : undefined
          "
          [style.grid-column]="'span ' + field.cols"
        >
          <ng-template
            (attached)="
              onPortalAttached(field.settings.formControlName, rowIndex, $event)
            "
            [cdkPortalOutlet]="field | dynamicComponent"
          >
          </ng-template>
        </div>
        <!-- delete button -->
        <button
          *ngIf="!settings.hideAddButton"
          mat-icon-button
          class="delete-button"
          (click)="deleteItem(rowIndex)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>
