import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationListNyrovComponent } from "./navigation-list-nyrov.component";
import { PrefixModule } from "../../prefix/prefix.module";

@NgModule({
  declarations: [NavigationListNyrovComponent],
  imports: [CommonModule, PrefixModule],
  exports: [NavigationListNyrovComponent]
})
export class NavigationListNyrovModule {
}
