import { Component } from '@angular/core';
import { BaseSliderComponent } from '../base/base-slider.component';
import { state, style, trigger } from '@angular/animations';

@Component({
  selector: 'echo-nx-slider-variant-nyrov',
  templateUrl: './slider-variant-nyrov.component.html',
  styleUrls: ['./slider-variant-nyrov.component.scss'],
  animations: [
    trigger('sliderAnimator', [
      state('none', style({ opacity: 0, 'pointer-events': 'none' })),
    ]),
  ],
})
export class SliderVariantNyrovComponent extends BaseSliderComponent {

}
