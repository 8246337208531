import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {
  IBaseButtonData,
  IBaseButtonDesign,
  IEpxColorful,
  IEpxIcon,
} from '@echo-nx/shared/common';

@Component({
  selector: 'echo-nx-button-theme-d',
  templateUrl: './button-theme-d.component.html',
  styleUrls: ['./button-theme-d.component.scss'],
})
export class ButtonThemeDComponent
  implements OnInit, IBaseButtonData, IBaseButtonDesign
{
  @Input()
  accentColor?: string = 'accent-500';

  @Input()
  descriptionColorful?: IEpxColorful;

  @Input()
  description?: string;

  @Input()
  endIcon?: IEpxIcon;

  @Input()
  glow = false;

  @Input()
  bgColor = 'primary-500';

  @Input()
  startIcon?: IEpxIcon;

  @Input()
  textColor?: string;

  @Input()
  title!: string;

  @Input()
  fluid;

  @Input()
  set design({
    textColor,
    bgColor,
    startIcon,
    endIcon,
    fluid,
  }: IBaseButtonDesign) {
    this.textColor = textColor ?? this.textColor;
    this.bgColor = bgColor ?? this.bgColor;
    this.startIcon = startIcon;
    this.endIcon = endIcon;
  }

  @Input()
  set data({ title, description }: IBaseButtonData) {
    this.title = title;
    this.description = description;
  }

  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal' || this.fluid === 'both',
      flex: this.fluid === 'horizontal' || this.fluid === 'both',
      'h-full': this.fluid === 'vertical' || this.fluid === 'both',
    };
  }

  ngOnInit(): void {
    return;
  }
}
