import { Component } from '@angular/core';
import {MatRadioChange} from "@angular/material/radio";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {getPersonCountFromCoupons, getPersonCountFromTicketTypes} from "@echo-nx/marlenka/common";
import {SnackbarComponent, SnackbarData} from "@echo-nx/shared/ng/feature/snackbar";


@Component({
  selector: 'marlenka-gluten-free-bar',
  templateUrl: './gluten-free-bar.component.html',
  styleUrls: ['./gluten-free-bar.component.scss']
})
export class GlutenFreeBarComponent {

  constructor(public reservationService: ReservationService, private snackBar: MatSnackBar) { }



  glutenFreeChange(requiresGF: MatRadioChange) {
    this.reservationService.requiresGlutenFree = requiresGF.value;
  }

  addGFPeople() {
    // destructure necessary data
    const {glutenFreeCount, selectedTickets, selectedCoupons} = this.reservationService;

    // calculate person count todo maybe make personCount$,  the calculations are on quite a few places
    const ticketTypes = selectedTickets.map(t => t.itemType);
    const ticketPersonCount = getPersonCountFromTicketTypes(ticketTypes);
    const couponPersonCount = getPersonCountFromCoupons(selectedCoupons);
    const personCount = ticketPersonCount + couponPersonCount;


    if (glutenFreeCount + 1 > personCount) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          text: 'Počet bezlepkových degustací přesáhl počet osob v rezervaci.',
          type: 'WARNING',
        } as SnackbarData,
        panelClass: 'custom-snackbar',
        verticalPosition: "top",
        horizontalPosition: "center"
      });
      return
    }

    // add another
    this.reservationService.glutenFreeCount += 1;
  }

  removeGFPeople() {
    if (this.reservationService.glutenFreeCount <= 0) {
      return;
    }
    this.reservationService.glutenFreeCount -= 1;
  }

}
