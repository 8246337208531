import {AfterViewInit, Component, ElementRef, Inject, Injector, Optional, ViewChild} from '@angular/core';
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {BaseFormFieldComponent} from "../base-form-field.component";
import {IEntityPickerFieldSettings} from "./i-entity-picker-field.settings";
import {IEntityDefinition} from "../../../interfaces";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {map, startWith, take, takeUntil} from "rxjs/operators";
import {FormBuilder, FormGroupDirective} from "@angular/forms";
import {LanguageProviderService} from "@echo-nx/shared/ng/data-access";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Sortable, {SortableEvent} from 'sortablejs';
import {moveItemInArray} from "@angular/cdk/drag-drop";
import {MatChipGrid} from "@angular/material/chips";
import {hasValue} from '../../../utils/form-utils';
import {DataCardDialogComponent, IDataCardDialogSettings} from '../../../../data-card';



@Component({
  selector: 'echo-nx-entity-picker-field',
  templateUrl: './entity-picker-field.component.html',
  styleUrls: ['./entity-picker-field.component.scss']
})
export class EntityPickerFieldComponent extends BaseFormFieldComponent<IEntityPickerFieldSettings> implements AfterViewInit {
  private entityDialogRef?: MatDialogRef<DataCardDialogComponent, Record<string, any>[]>;
  public hasValue = false;

  @ViewChild(MatChipGrid, {read: ElementRef})
  chipList?: ElementRef;

  constructor(
    @Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IEntityPickerFieldSettings>,
    formGroupDirective: FormGroupDirective,
    private dialog: MatDialog,
    @Optional() private languageProvider: LanguageProviderService,
    //private fb: FormBuilder,
    private elementRef: ElementRef<HTMLElement>,
    injector: Injector
  ) {
    super(def, formGroupDirective, injector);
    this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      takeUntil(this.isDestroyed$),
      map(hasValue)
    ).subscribe(hasValue => {
      this.hasValue = hasValue
    });
  }

  ngAfterViewInit(): void {
    //Sortable chiplist
    if (this.chipList) {
      Sortable.create((this.chipList.nativeElement.children[0]), {
        onUpdate: this.sortEvents.bind(this)
      });
    } else {
      //sortable media grid
      const mediaWrapperElements = this.elementRef.nativeElement.getElementsByClassName('preview');
      if (mediaWrapperElements.length === 1) {
        Sortable.create((mediaWrapperElements[0] as HTMLElement), {
          onUpdate: this.sortEvents.bind(this),
        });
      }
    }
  }

  sortEvents(evt: SortableEvent) {
    const selectedItems = [...this.formControl.value];
    moveItemInArray(selectedItems, evt.oldIndex ?? 0, evt.newIndex ?? 0);
    this.formControl.setValue(selectedItems);
  }

  public openEntityPicker() {
    const dialogConfig = this.getDialogConfig();
    this.entityDialogRef = this.dialog.open(DataCardDialogComponent, dialogConfig);

    // after close clear the ref and get the result
    this.entityDialogRef.afterClosed().pipe(
      take(1)
    ).subscribe((res) => {
      if (res) {
        if (this.settings.dialogSettings.isMultiple) {
          this.formControl.setValue(res);
        } else {
          this.formControl.setValue(res[0]);
        }
      }
      this.entityDialogRef = undefined
    });
  }

  public removeAllEntities(event: Event) {
    if (this.settings.dialogSettings.isMultiple) {
      this.formControl.setValue([]);
    } else {
      this.formControl.setValue(null);
    }

    event.stopPropagation();

  }

  public removeEntity(entity: { _id: string }) {
    if (Array.isArray(this.formControl.value)) {
      const filtered = this.formControl.value.filter(i => i._id !== entity._id);
      this.formControl.setValue(filtered);
    } else {
      this.formControl.setValue(null);
    }

  }

  public itemsChanged(entities: { _id: string }[]) {
    this.formControl.setValue(entities);
  }

  private getDialogConfig(): MatDialogConfig<IDataCardDialogSettings> {
    const {dialogSettings} = this.settings;
    const dialogConfig = new MatDialogConfig<IDataCardDialogSettings>();
    const {value} = this.formControl;
    const initialValues = Array.isArray(value) ? value : (value ? [value] : []);
    dialogConfig.data = {
      ...dialogSettings,
      initiallySelectedValues: initialValues
    };
    dialogConfig.width = '85%';
    return dialogConfig;
  }
}
