<marlenka-section-header
  name="Přizpůsobte si Váš voucher"
  [description]="description"
  class="padded-header"
>
</marlenka-section-header>

<div class="container small">
  <div class="background">
    <h3>Vyberte si pozadí:</h3>
    <div class="images" *ngIf="voucherTypes$ | async as voucherTypes">
      <a
        class="image"
        *ngFor="let voucherType of voucherTypes"
        [class.selected]="selectedBackgroundId === voucherType._id"
        (click)="selectBackground(voucherType)"
      >
        <ng-container [ngSwitch]="voucherType._id">
          <img
            *ngSwitchDefault
            [src]="voucherType.voucherPreview.url"
            [alt]="voucherType.voucherPreview.name"
          />
          <div class="no-voucher" *ngSwitchCase="null">
            Bez dárkového poukazu
          </div>
        </ng-container>
      </a>
    </div>
  </div>

  <hr id="voucher-texts" />

  <div class="text" *ngIf="selectedBackgroundId !== null">
    <h3>Napište nadpis a přání</h3>
    <!-- TiTLE -->
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Text nadpisu</mat-label>
      <input matInput [formControl]="title" maxlength="50" />
      <mat-hint align="end"
        >{{ title.value?.length ? title.value?.length : 0 }}/50</mat-hint
      >
    </mat-form-field>
    <!-- CONTENT -->
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Text přání</mat-label>
      <textarea
        matInput
        type="text"
        [rows]="2"
        [formControl]="text"
        maxlength="150"
      ></textarea>
      <mat-hint align="end"
        >{{ text.value?.length ? text.value?.length : 0 }}/150</mat-hint
      >
    </mat-form-field>
    <!-- SIGNatURE -->
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Podpis</mat-label>
      <textarea
        matInput
        type="text"
        [formControl]="signature"
        maxlength="30"
      ></textarea>
      <mat-hint align="end"
        >{{
          signature.value?.length ? signature.value?.length : 0
        }}/30</mat-hint
      >
    </mat-form-field>
  </div>
</div>
