import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  BehaviorSubject,
  interval,
  merge,
  race,
  Subject,
  Subscription,
} from 'rxjs';
import { skip, startWith, switchMap, takeUntil } from 'rxjs/operators';
import {
  AnimationStyle,
  ImageFit,
  ISliderData,
  ISliderDesign,
} from '@echo-nx/shared/common';

@Component({
  selector: 'echo-nx-base-slider',
  template: '',
  animations: [],
})
export class BaseSliderComponent implements OnInit, OnDestroy {
  //array of 0...n for easier template binding - calculated in onInit
  public sliderIndexes: number[] = [];

  // We have three indexes, current is for indicator, first and second are for wrappers/animation
  // two of these should be equal, third will be different and used for transitions
  // currentIdx will change after each animation, while first and secondIdx will change before animation
  public currentIdx$ = new BehaviorSubject(0);
  public firstIdx = 0;
  public secondIdx = 0;

  //these are helpers to know which wrapper should be animated, in theory it could be replaced with firstIdx == currentIdx
  public showFirst = true;
  public showSecond = false;

  //what should we do after end of animation (decrement or increment), was used for
  public playNextAnimation = false;
  public playPreviousAnimation = false;

  // if you want smooth and stable animation, u need to have one animation with variables... these are offset variables for slide animation
  // sadly not working properly rn
  public distanceToFirst = '0';
  public distanceFromFirst = '0';
  public distanceFromSecond = '0';
  public distanceToSecond = '0';

  //value in percentage for progressBar (is mapped to % width)
  public progressBarValue = 0;

  //Subjects
  public onTimerStop$ = new Subject<void>();
  public timerEnded$ = new Subject<void>();
  public onTimerStart$ = new Subject<void>();
  public onDestroy$ = new Subject<void>();

  //Subscription so we know if we are paused or not
  public timerSubscription?: Subscription;

  get isTimerPaused() {
    return !this.timerSubscription || this.timerSubscription?.closed;
  }

  @Input() transitionDurationInMilis = 400;
  @Input() slideDurationInMilis = 10000;
  @Input() resumePlaybackDurationInMilis = 20000;
  @Input() updateProgressBarInMilis = 200;

  @Input()
  primaryColor = 'primary-500';

  @Input()
  secondaryColor = 'primary-200';

  @Input()
  textColor = 'white';

  @Input()
  hideIndicatorButtons = false;

  @Input()
  hideIndicators = false;

  //do not use slide rn, buggy af
  @Input()
  animationStyle: AnimationStyle = AnimationStyle.none;

  @Input()
  imageFit: ImageFit = ImageFit.none;

  @Input()
  data: ISliderData[];

  @Input()
  set design({
    primaryColor,
    secondaryColor,
    textColor,
    hideIndicatorButtons,
    hideIndicators,
    animationStyle,
    imageFit,
  }: ISliderDesign) {
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.textColor = textColor;
    this.hideIndicatorButtons = hideIndicatorButtons;
    this.hideIndicators = hideIndicators;
    this.animationStyle = animationStyle;
    this.imageFit = imageFit;
  }

  @Output()
  timerStarted: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  timerStopped: EventEmitter<void> = new EventEmitter<void>();

  animationDone() {
    if (this.playNextAnimation) {
      this.currentIdx$.next(
        (this.currentIdx$.getValue() + 1) % this.sliderIndexes.length
      );
      this.playNextAnimation = false;
    } else if (this.playPreviousAnimation) {
      this.currentIdx$.next(
        (this.currentIdx$.getValue() - 1 + this.sliderIndexes.length) %
        this.sliderIndexes.length
      );
      this.playPreviousAnimation = false;
    }
    // console.log("switched to idx", this.currentIdx$);
  }

  toggleTimer() {
    if (this.isTimerPaused) {
      this.startSliderTimer(this.slideDurationInMilis);
      this.timerStarted.next();
    } else {
      this.stopTimer();
      this.timerStopped.next();
    }
  }

  stopTimer() {
    if (this.isTimerPaused) {
      console.log('already paused/ended', this.timerSubscription);
    } else {
      this.onTimerStop$.next();

      //these two could be in onTimerStop subscription if there was any.
      this.startResumerTimer();
      this.progressBarValue = 0;
    }
  }

  // prepared for dynamic targetMilis (from media/timeline definition)
  startSliderTimer(targetMillis: number, intervalMillis?: number) {
    this.onTimerStart$.next();

    // console.log('timer started');
    const targetCount =
      targetMillis / (intervalMillis ?? this.updateProgressBarInMilis);
    this.timerSubscription = interval(
      intervalMillis ?? this.updateProgressBarInMilis
    )
      .pipe(takeUntil(race(this.timerEnded$, this.onTimerStop$)), startWith(-1))
      .subscribe((count) => {
        this.progressBarValue = ((count + 1) * 100) / targetCount;
        if (count >= targetCount) {
          this.timerEnded$.next();
        }
      });
  }

  // starts when main slider timer is paused
  startResumerTimer() {
    const targetCount = this.resumePlaybackDurationInMilis / 1000;
    // restart when currentIdx changes, possibly extend this when there are more events that should restart timer
    this.currentIdx$
      .pipe(
        takeUntil(merge(this.onDestroy$, this.onTimerStart$)),
        switchMap(() =>
          interval(1000).pipe(
            //stop ticking when user triggers timer, or triggers Idx change (will restart above)
            takeUntil(
              merge(
                this.onDestroy$,
                this.onTimerStart$,
                this.currentIdx$.pipe(skip(1))
              )
            )
          )
        )
      )
      .subscribe((count) => {
        // if we counted enough - start slider (which will destroy timer and restarter itself)
        if (count >= targetCount) {
          this.startSliderTimer(this.slideDurationInMilis);
          this.timerStarted.next();
        }
      });
  }

  previousImage() {
    if (this.playNextAnimation || this.playPreviousAnimation) {
      return;
    }

    if (!this.isTimerPaused) {
      this.stopTimer();
      this.startSliderTimer(this.slideDurationInMilis);
    }

    this.playPreviousAnimation = true;
    if (this.showFirst) {
      if (this.animationStyle === 'slide') {
        this.distanceFromFirst = '0%';
        this.distanceToFirst = '100%';
        this.distanceFromSecond = '-100%';
        this.distanceToSecond = '0%';
      }
      this.secondIdx =
        (this.currentIdx$.getValue() - 1 + this.sliderIndexes.length) %
        this.sliderIndexes.length;
      this.showSecond = true;
      this.showFirst = false;
    } else {
      if (this.animationStyle === 'slide') {
        this.distanceFromSecond = '0%';
        this.distanceToSecond = '100%';
        this.distanceFromFirst = '-100%';
        this.distanceToFirst = '0%';
      }
      this.firstIdx =
        (this.currentIdx$.getValue() - 1 + this.sliderIndexes.length) %
        this.sliderIndexes.length;
      this.showSecond = false;
      this.showFirst = true;
    }
  }

  nextImage() {
    if (this.playNextAnimation || this.playPreviousAnimation) {
      return;
    }

    if (!this.isTimerPaused) {
      this.stopTimer();
      this.startSliderTimer(this.slideDurationInMilis);
    }

    this.playNextAnimation = true;
    if (this.showFirst) {
      if (this.animationStyle === 'slide') {
        this.distanceFromSecond = '0%';
        this.distanceToSecond = '-100%';
        this.distanceFromFirst = '100%';
        this.distanceToFirst = '0%';
      }
      this.secondIdx =
        (this.currentIdx$.getValue() + 1) % this.sliderIndexes.length;
      this.showSecond = true;
      this.showFirst = false;
    } else {
      this.firstIdx =
        (this.currentIdx$.getValue() + 1) % this.sliderIndexes.length;
      this.showSecond = false;
      this.showFirst = true;
    }
  }

  selectImage(index: number) {
    if (this.playNextAnimation || this.playPreviousAnimation) {
      return;
    }
    this.currentIdx$.next(index - 1)
    this.nextImage();
  }

  ngOnInit(): void {
    this.sliderIndexes =
      Array(this.data?.length)
        .fill(0)
        .map((x, i) => i) ?? [];
    this.secondIdx =
      (this.currentIdx$.getValue() + 1) % this.sliderIndexes.length;
    if (this.slideDurationInMilis) {
      this.timerEnded$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.nextImage();
      });
      if (this.sliderIndexes.length > 1) {
        this.startSliderTimer(this.slideDurationInMilis);
      }
    }
    // console.log('primary', this.firstIdx, 'second', this.secondIdx);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
