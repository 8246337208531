import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TicketsStepComponent } from '../marlenka-stepper/steps/tickets-step/tickets-step.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { ProductsStepComponent } from '../marlenka-stepper/steps/products-step/products-step.component';
import { SummaryStepComponent } from '../marlenka-stepper/steps/summary-step/summary-step.component';
import { ContactStepComponent } from '../marlenka-stepper/steps/contact-step/contact-step.component';
import { EventStepComponent } from '../marlenka-stepper/steps/event-step/event-step.component';
import { combineLatest, EMPTY, firstValueFrom, from, of, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  catchError,
  concatMap,
  debounce,
  debounceTime,
  distinct, distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { MarlenkaStepperComponent } from '../marlenka-stepper/marlenka-stepper.component';
import { MatDialog } from '@angular/material/dialog';
import { CouponDialogComponent } from '../coupon-dialog/coupon-dialog.component';
import { UseVoucherStepComponent } from '../marlenka-stepper/steps/use-voucher-step/use-voucher-step.component';
import { EventsService, ReservationService } from "@echo-nx/marlenka/ng/data-access";
import {
  getEventsForDay,
  getPersonCountFromCoupons,
  getPersonCountFromTicketTypes,
  getStepperTitleFor,
  IMEvent,
  initiateMarlenkaPayment,
  MarlenkaRouteData
} from "@echo-nx/marlenka/common";
import { BaseLoadingService } from "@echo-nx/shared/ng/feature/loading";
import { IPKStep, TICKET_STEP_COMPONENT_INPUT, TicketStepComponentInput } from "../utils";
import { pluralize } from "@echo-nx/shared/common";
import { createCustomSnackbar, SnackbarComponent, SnackbarData } from "@echo-nx/shared/ng/feature/snackbar";
import { LanguageRouteDataService } from "@echo-nx/shared/ng/feature/language";
import { createPortalInjector } from "@echo-nx/shared/ng/feature/common";
import { DateTime } from 'luxon';


@Component({
  selector: 'marlenka-reservation-stepper',
  templateUrl: './reservation-stepper.component.html',
  styleUrls: ['./reservation-stepper.component.scss'],
  providers: [
    ReservationService,
    EventsService
  ]
})
export class ReservationStepperComponent implements OnInit {
  @ViewChild('marlenkaStepper', { static: true })
  public marlenkaStepper!: MarlenkaStepperComponent;

  @Input() adminToken?: string;

  @Output() reservationCompleted = new EventEmitter<string>();

  @Output()
  public backClicked = new EventEmitter<number>();

  public readonly isDestroyed$ = new Subject<boolean>();

  constructor(
    public reservationService: ReservationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private loadingService: BaseLoadingService,
    private mrdService: LanguageRouteDataService<MarlenkaRouteData>,
    public eventsService: EventsService,
    private injector: Injector
  ) {
  }

  public reservationSteps!: IPKStep[];

  ngOnInit(): void {
    // create the steps
    console.log('adminToken', this.adminToken);
    this.reservationSteps = this.createReservationSteps();

    // forward errors on next to reservation service
    this.marlenkaStepper.stepper.error$.pipe(
      takeUntil(this.isDestroyed$),
      filter((v) => v.type === 'nextError')
    ).subscribe(() => this.reservationService.onNextFailed.next());

    // watch for completion
    combineLatest([
      // vstupenky
      this.reservationService.selectedTickets$.pipe(
        distinct(),
        debounceTime(250),
      ),
      // event
      this.reservationService.selectedEvent$.pipe(
        filter(event => !!event),
        distinctUntilChanged((x, y) => x?._id === y?._id)
      ),
      this.reservationService.selectedCoupons$
      // zmena kroku
      // this.marlenkaStepper.stepper.stepChanged$
    ])
      .pipe(
        takeUntil(this.isDestroyed$),
        switchMap(() =>
          // https://www.intertech.com/angular-best-practice-rxjs-error-handling/
          this.reservationService.saveReservation(this.mrdService.currentLanguage).pipe(
            catchError((err: Error) => {
              console.log('reservation save error', err);
              createCustomSnackbar(err.message, this.snackBar, 'ERROR');
              return EMPTY;
            }),
          )
        ),
        // filter(res => !!res),
        switchMap(async (res) => {
          const { selectedMonth, selectedDate } = this.eventsService;
          this.eventsService.events = await this.eventsService.fetchEventsInMonth(selectedMonth) as any;
          this.reservationService.selectedEvent = this.eventsService.events.find((item) => this.reservationService.selectedEvent?._id === item._id) as IMEvent<any>;

          this.eventsService.days = this.eventsService.getDaysWithEvents(selectedMonth, this.eventsService.events, this.eventsService.filter);
          this.eventsService.dayEvents = getEventsForDay(this.eventsService.events, selectedDate);
          return res;
        })
      )
      .subscribe(res => {
        console.log('reservation saved', res);
      });
  }

  private createReservationSteps(): IPKStep[] {
    const ticketsAndProductsCompletion$ = combineLatest([
      this.reservationService.selectedEvent$,
      this.reservationService.selectedTickets$,
      this.reservationService.selectedCoupons$
    ]).pipe(
      skip(1), // skip the first behavior subject
      map(([event, tickets, coupons]) => {
        const ticketTypes = tickets.map(t => t.itemType);
        const ticketPersonCount = getPersonCountFromTicketTypes(ticketTypes);
        const couponPersonCount = getPersonCountFromCoupons(coupons);
        const personCount = ticketPersonCount + couponPersonCount;
        const { availableCapacity } = event ?? {};
        console.log(availableCapacity, personCount);
        return availableCapacity !== undefined && personCount > 0 && availableCapacity >= 0;
      })
    );


    return [
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('event', lang))
        ),
        subtitle: this.reservationService.selectedEvent$
          .pipe(
            filter((event) => !!event),
            map((event) => {
              const availableCapacity = event?.availableCapacity ?? 0;
              if (availableCapacity < 0) {
                createCustomSnackbar(`Kapacita termínu byla překročena o ${pluralize(Math.abs(availableCapacity), ['místo', 'místa', 'míst'])}`, this.snackBar, 'ERROR')
              }
              const date = DateTime.fromJSDate(event?.start as Date).toFormat('dd.MM HH:mm');
              return `${date} · ${pluralize(availableCapacity, ['místo', 'místa', 'míst'])}`;
            })
          ),
        completed: this.reservationService.selectedEvent$.pipe(
          map(event => !!event)
        ),
        optional: false,
        nextButtonText: of('Pokračovat'),
        componentPortal: new ComponentPortal(EventStepComponent)
      },
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('use-voucher', lang))
        ),
        subtitle: this.reservationService.selectedCoupons$.pipe(
          map(coupons => coupons.length > 0 ? pluralize(coupons.length, ['voucher', 'vouchery', 'voucherů']) : '')
        ),
        completed: of(true),
        optional: true,
        nextButtonText: of('Pokračovat'),
        componentPortal: new ComponentPortal(UseVoucherStepComponent)
      },
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('tickets', lang))
        ),
        subtitle: this.reservationService.totals$.pipe(
          map(({ tickets }) => tickets.count > 0 ? `${tickets.count} ks - ${tickets.cost} Kč` : '')
        ),
        completed: ticketsAndProductsCompletion$,
        optional: false,
        nextButtonText: of('Pokračovat'),
        componentPortal:
          new ComponentPortal(TicketsStepComponent,
            null,
            createPortalInjector(
              TICKET_STEP_COMPONENT_INPUT,
              { showWarning: true } as TicketStepComponentInput,
              this.injector
            )
          )
      },
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('products', lang))
        ),
        subtitle: this.reservationService.totals$.pipe(
          map(({ products }) => products.count > 0 ? `${products.count} ks - ${products.cost} Kč` : '')
        ),
        completed: ticketsAndProductsCompletion$,
        optional: true,
        nextButtonText: of('Pokračovat'),
        componentPortal: new ComponentPortal(ProductsStepComponent)
      },
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('contact', lang))
        ),
        subtitle: this.reservationService.selectedPerson$.pipe(
          map(contact => contact.formData?.person.name || '')
        ),
        completed: this.reservationService.selectedPerson$.pipe(
          map(contact => contact.formValid)
        ),
        optional: false,
        nextButtonText: of('Pokračovat'),
        componentPortal: new ComponentPortal(ContactStepComponent)
      },
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('summary', lang))
        ),
        subtitle: this.reservationService.totals$.pipe(
          map(({ coupons, tickets, total }) => tickets.count > 0 || coupons.count > 0 ? `${total.cost} Kč` : '')
        ),
        completed: combineLatest([
          this.reservationService.selectedTickets$,
          this.reservationService.selectedEvent$,
          this.reservationService.selectedPerson$
        ]).pipe(
          map(([tickets, event, person]) => person.formValid && tickets.length > 0 && event !== null)
        ),
        optional: false,
        nextButtonText: this.reservationService.totals$.pipe(
          map(({ total }) => total.cost > 0 ? 'Zaplatit' : 'Dokončit')
        ),
        disableNextButton: this.reservationService.selectedEvent$.pipe(
          map(event => (event && event?.availableCapacity < 0) || false)
        ),
        componentPortal: new ComponentPortal(SummaryStepComponent)
      }
    ];
  }

  public openCouponDialog() {
    this.dialog.open(CouponDialogComponent, {
      data: {
        // inject it like this, because reservation-stepper will provide its own service, and voucher will have other instance
        reservationService: this.reservationService
      }
    });
  }

  public onReservationCompleted() {

    const loadingId = this.loadingService.startLoading();
    //payment time
    firstValueFrom(this.reservationService.saveReservation(this.mrdService.currentLanguage))
      .then((res: any) => res)
      .then(async ({ data }) => {
        if (data) {
          const { _id } = data.response[0];
          if (!_id) {
            throw Error(`ReservationID is missing. ReservationID = ${_id}`);
          }
          if (!this.adminToken) {
            await initiateMarlenkaPayment(_id, 'reservation');
          } else {
            this.reservationCompleted.emit(_id);
            return;
          }
        } else {
          console.error('empty data on reservation completed');
        }
      })
      .catch((err: Error) => {
        console.log('failed to save reservation payment', err);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: {
            text: err.message || 'Rezervaci se nepodařilo vytvořit, zkuste to prosím později.'
          } as SnackbarData,
          panelClass: 'custom-snackbar',
          verticalPosition: 'top',
          horizontalPosition: 'end'
        });
      })
      .finally(() => {
        this.loadingService.stopLoading(loadingId);
      });
  }

}
