import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarlenkaStepperComponent } from './marlenka-stepper/marlenka-stepper.component';
import { StepSummaryComponent } from './step-summary/step-summary.component';
import { EventStepComponent } from './marlenka-stepper/steps/event-step/event-step.component';
import { TicketsStepComponent } from './marlenka-stepper/steps/tickets-step/tickets-step.component';
import { ProductsStepComponent } from './marlenka-stepper/steps/products-step/products-step.component';
import { ContactStepComponent } from './marlenka-stepper/steps/contact-step/contact-step.component';
import { SummaryStepComponent } from './marlenka-stepper/steps/summary-step/summary-step.component';
import { ReservationStepperComponent } from './reservation-stepper/reservation-stepper.component';
import { VoucherStepperComponent } from './voucher-stepper/voucher-stepper.component';
import { CustomizeVoucherStepComponent } from './marlenka-stepper/steps/customize-voucher-step/customize-voucher-step.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatIconModule } from '@angular/material/icon';
import { PortalModule } from '@angular/cdk/portal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { EventListComponent } from './event-list/event-list.component';
import { CouponDialogComponent } from './coupon-dialog/coupon-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ItemListComponent } from './item-list/item-list.component';
import { ProductTypeGridComponent } from './item-grid/product-type-grid/product-type-grid.component';
import { TicketTypeGridComponent } from './item-grid/ticket-type-grid/ticket-type-grid.component';
import { GlutenFreeBarComponent } from './gluten-free-bar/gluten-free-bar.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ItemCountPipe } from './utils/item-count.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UseVoucherStepComponent } from './marlenka-stepper/steps/use-voucher-step/use-voucher-step.component';
import { MarlenkaUiModule } from '@echo-nx/marlenka/ng/ui';
import { SharedNgUiGenericModule } from '@echo-nx/shared/ng/ui/generic';
import { SharedNgFeatureCommonModule } from '@echo-nx/shared/ng/feature/common';
import { SharedNgUiSmartModule } from '@echo-nx/shared/ng/ui/smart';
import { SharedNgFeatureLanguageModule } from '@echo-nx/shared/ng/feature/language';
import { MarlenkaNotificationDialogComponent } from './marlenka-notification-dialog/marlenka-notification-dialog.component';

@NgModule({
  declarations: [
    ItemCountPipe,
    ItemListComponent,
    GlutenFreeBarComponent,
    CouponDialogComponent,
    MarlenkaStepperComponent,
    EventListComponent,
    StepSummaryComponent,
    EventStepComponent,
    TicketsStepComponent,
    ProductsStepComponent,
    ContactStepComponent,
    SummaryStepComponent,
    ReservationStepperComponent,
    VoucherStepperComponent,
    ProductTypeGridComponent,
    TicketTypeGridComponent,
    CustomizeVoucherStepComponent,
    UseVoucherStepComponent,
    MarlenkaNotificationDialogComponent,
  ],
  exports: [
    ItemListComponent,
    EventListComponent,
    CouponDialogComponent,
    MarlenkaStepperComponent,
    StepSummaryComponent,
    EventStepComponent,
    TicketsStepComponent,
    ProductsStepComponent,
    ContactStepComponent,
    SummaryStepComponent,
    UseVoucherStepComponent,
    ReservationStepperComponent,
    VoucherStepperComponent,
    CustomizeVoucherStepComponent,
  ],
  imports: [
    SharedNgUiGenericModule,
    SharedNgUiSmartModule,
    SharedNgFeatureCommonModule,
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    CdkStepperModule,
    MatIconModule,
    PortalModule,
    MarlenkaUiModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatBadgeModule,
    MatDialogModule,
    MatRadioModule,
    MatTooltipModule,
    MatAutocompleteModule,
    SharedNgFeatureLanguageModule,
  ],
})
export class MarlenkaReservationModule {}
