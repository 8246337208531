import { Injectable, InjectionToken } from '@angular/core';
import { Apollo, MutationResult } from 'apollo-angular';
import {
  addDepartment,
  deleteDepartment,
  IMarlenkaOwner,
  IMDepartment,
  MarlenkaEntityTypes,
  readAllDepartment,
  readDepartmentLanguageGroups,
  readSelectedDepartment,
} from '@echo-nx/marlenka/common';
import { readAllCategoriesOfEntity } from '@echo-nx/shared/ng/data-access';
import {
  FetchAllArgs,
  ICategory,
  ICompanyContact,
  IContact,
  IEntityService,
  IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult,
} from '@echo-nx/shared/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BaseDepartmentService
  implements IEntityService<IMDepartment<any>>
{
  constructor(protected apollo: Apollo) {}

  fetchAll(
    args?: FetchAllArgs
  ): Observable<
    IPaginatedResponse<IMDepartment<IContact[] | ICompanyContact[]>>
  > {
    return this.apollo
      .query<
        IPKApolloResult<
          IPaginatedResponse<IMDepartment<IContact[] | ICompanyContact[]>>
        >
      >({
        query: readAllDepartment,
        variables: args,
      })
      .pipe(map((result) => result.data.response));
  }

  fetchLanguageGroups(
    args?: FetchAllArgs
  ): Observable<IPaginatedLanguageGroup<IMDepartment>> {
    return this.apollo
      .query<IPKApolloResult<IPaginatedLanguageGroup<IMDepartment>>>({
        query: readDepartmentLanguageGroups,
        variables: args,
      })
      .pipe(map((result) => result.data.response));
  }

  fetchSelected(
    ids: string[]
  ): Observable<IMDepartment<IContact[] | ICompanyContact[]>[]> {
    return this.apollo
      .query<IPKApolloResult<IMDepartment<IContact[] | ICompanyContact[]>[]>>({
        query: readSelectedDepartment,
        variables: {
          ids: ids,
        },
      })
      .pipe(map((res) => res.data.response));
  }

  //IContact[] | ICompanyContact[]
  fetchSingle(
    id: string
  ): Observable<IMDepartment<IContact[] | ICompanyContact[]>> {
    return this.apollo
      .query<IPKApolloResult<IMDepartment<IContact[] | ICompanyContact[]>[]>>({
        query: readSelectedDepartment,
        variables: {
          ids: [id],
        },
      })
      .pipe(map((result) => result.data.response[0] || null));
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteDepartment,
      variables: { input: ids },
    });
  }

  save(
    department: IMDepartment[]
  ): Observable<MutationResult<IPKApolloResult<IMDepartment[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMDepartment[]>>({
      mutation: addDepartment,
      variables: { input: department },
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo
      .query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
        query: readAllCategoriesOfEntity,
        variables: {
          type: this.getType(),
        },
      })
      .pipe(map((result) => result.data.response));
  }

  getType(): string {
    return MarlenkaEntityTypes.Department;
  }
}

export const MDEPARTMENT_SERVICE_TOKEN = new InjectionToken<
  IEntityService<IMDepartment>
>('MDEPARTMENT_SERVICE_TOKEN');
