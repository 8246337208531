import {InjectionToken, Injector} from "@angular/core";
import {IAddress} from "@echo-nx/shared/common";
import {DateTime} from 'luxon';

export const createPortalInjector = (key: InjectionToken<never>, dataToPass: any, injector: Injector) => {
  return Injector.create(
    {
      providers: [
        {provide: key, useValue: dataToPass}
      ],
      parent: injector
    })
}

export const addressToString = (address: IAddress) => {
  return [
    address.street,
    address.province,
    `${address.city ?? ''} ${address.zipcode ?? ''}`,
    address.state
  ]
    .filter(val => !!val)
    .join(', ');
}

export const formatPrice = (value: string | number, formatFn?: (val: any) => any) => {
  const formatter = new Intl.NumberFormat('cs', {
    style: 'currency',
    currency: 'CZK',
  });

  const val = typeof value === "string" ? parseInt(value, 10) : value;
  return formatter.format(formatFn ? formatFn(val) : val);
}

export const isEntityPublished = (publishedAt: Date, unpublishedAt: Date): boolean => {
  if(!publishedAt) return false;
  const now = DateTime.now();
  return DateTime.fromJSDate(publishedAt) <= now && (!unpublishedAt || DateTime.fromJSDate(unpublishedAt) >= now)
}

export const isEntityUnpublished = (publishedAt: Date, unpublishedAt: Date): boolean => {
  if(!publishedAt || !unpublishedAt) return false;
  const now = DateTime.now();
  return DateTime.fromJSDate(publishedAt) <= now && DateTime.fromJSDate(unpublishedAt) <= now;
}

export const formatEllipsisText = (text: string, charMaxLen: number = 56): string => text.length >= charMaxLen ? `${text.substr(0, charMaxLen)}...` : text;
