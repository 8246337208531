<div class="text">
  Provozovatel tohoto webového portálu používá soubory cookie, aby své webové
  stránky lépe přizpůsobil návštěvníkům a usnadnil jejich používání. Využíváme
  cookies nutné pro zajištění funkčnosti webu a rovněž cookies sloužící k
  analýze provozu na našich webových stránkách.
  <span *ngIf="gdprUrl"
    >V souladu se
    <a [href]="gdprUrl" target="_blank" class="url"
      >Zásadami ochrany osobních údajů sdílíme informace</a
    >
    o vašem užívání naší webové stránky také s našimi partnery v oblasti
    sociálních médií, reklamy a analýzy.</span
  >
</div>

<div class="flex gap-4 justify-end">
  <button class="button allow" (click)="allow()">Povolit vše</button>
  <button class="button deny" (click)="deny()">Odmítnout vše</button>
</div>
