import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckboxFieldComponent} from './checkbox-field.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ReactiveFormsModule} from "@angular/forms";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";


@NgModule({
  declarations: [
    CheckboxFieldComponent
  ],
  imports: [
    CommonModule,
    //_MatCheckboxRequiredValidatorModule,
    MatCheckboxModule,
    ReactiveFormsModule
  ]
})
export class CheckboxFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputCheckboxFieldComponent', CheckboxFieldComponent);
  }
}
