import { IMVoucherType } from '@echo-nx/marlenka/common';
import {
  BaseVoucherTypeService,
  ReservationService,
} from '@echo-nx/marlenka/ng/data-access';
import { Observable, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseLoadingService } from '@echo-nx/shared/ng/feature/loading';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'marlenka-customize-voucher-step',
  templateUrl: './customize-voucher-step.component.html',
  styleUrls: ['./customize-voucher-step.component.scss'],
})
export class CustomizeVoucherStepComponent implements OnInit, OnDestroy {
  private isDestroyed$ = new Subject<boolean>();

  //todo this step is missing language settings
  public description = '';

  public selectedBackgroundId!: string;
  public text = new UntypedFormControl('');
  public signature = new UntypedFormControl('');
  public title = new UntypedFormControl('');

  public voucherTypes$!: Observable<IMVoucherType[]>;

  constructor(
    public voucherTypeService: BaseVoucherTypeService,
    private reservationService: ReservationService,
    private loadingService: BaseLoadingService
  ) {
    this.text.patchValue(this.reservationService.voucherCustomization?.text);
    this.title.patchValue(this.reservationService.voucherCustomization?.title);
  }

  async ngOnInit() {
    // fetch backgrounds if not already fetched
    this.voucherTypes$ = this.voucherTypeService.fetchAll().pipe(
      map((voucherTypes) => {
        const emptyVoucher: IMVoucherType = {
          _id: undefined,
        };

        // preselect first if nothing is selected
        if (!this.reservationService.voucherCustomization) {
          this.selectBackground(emptyVoucher);
        } else {
          this.selectedBackgroundId =
            this.reservationService.voucherCustomization.voucherTypeId;
        }

        return [emptyVoucher, ...(voucherTypes.items ?? [])];
      })
    );

    // setup text observable
    this.text.valueChanges
      .pipe(debounceTime(150), takeUntil(this.isDestroyed$))
      .subscribe((text) => {
        this.reservationService.voucherCustomization = {
          ...this.reservationService.voucherCustomization,
          text,
        };
      });

    // setup title observable
    this.title.valueChanges
      .pipe(debounceTime(150), takeUntil(this.isDestroyed$))
      .subscribe((title) => {
        this.reservationService.voucherCustomization = {
          ...this.reservationService.voucherCustomization,
          title,
        };
      });

    // setup title observable
    this.signature.valueChanges
      .pipe(debounceTime(150), takeUntil(this.isDestroyed$))
      .subscribe((signature) => {
        this.reservationService.voucherCustomization = {
          ...this.reservationService.voucherCustomization,
          signature,
        };
      });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  public selectBackground(voucherType: IMVoucherType) {
    console.log('selecting voucher id', voucherType._id);
    this.selectedBackgroundId = voucherType._id as string;
    this.reservationService.voucherCustomization = {
      ...this.reservationService.voucherCustomization,
      voucherTypeId: voucherType._id as string,
    };

    if (voucherType._id) {
      document
        .getElementById('voucher-texts')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
