import {Component} from '@angular/core';
import {BaseItemCardComponent} from "../base-item-card.component";

@Component({
  selector: 'echo-nx-item-card-variant-nyrov',
  templateUrl: './item-card-variant-nyrov.component.html',
  styleUrls: ['./item-card-variant-nyrov.component.scss'],
})
export class ItemCardVariantNyrovComponent extends BaseItemCardComponent {

}
