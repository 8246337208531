<div
  class="flex relative items-center justify-center"
  style="min-height: 325px"
  [ngClass]="[
    image ? '' : (primaryColor | prefix: 'bg'),
    fluid === 'vertical' || fluid === 'both' ? 'h-full' : 'h-96'
  ]"
  [class.h-full]="fluid === 'vertical' || fluid === 'both'"
  [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
>
  <!-- https://xd.adobe.com/view/5922c0f0-580b-46ce-8fb6-e1711643130f-0c71/screen/e16a7034-58dd-4b8b-b88a-45d622656253?fullscreen -->

  <img
    *ngIf="image"
    class="object-cover h-full w-full"
    [src]="image?.url"
    [alt]="image.alt"
  />

  <div *ngIf="!image" class="text-3xl mb-8">
    <i
      class="fas fa-photo-video"
      [ngClass]="[accentColor | prefix: 'text']"
    ></i>
  </div>

  <!-- date -->
  <div
    class="p-1 px-4 absolute left-0 top-0 font-medium"
    [ngClass]="[
      dateText?.bgColor | prefix: 'bg',
      dateText?.color | prefix: 'text'
    ]"
  >
    {{ date }}
  </div>

  <div
    class="absolute bottom-0 left-0 right-0 flex flex-col bg-black bg-opacity-25"
  >
    <!-- TITLE -->
    <div
      class="text-xl font-medium p-4"
      [ngClass]="[titleText?.color ?? 'white' | prefix: 'text']"
    >
      {{ title }}
    </div>

    <!-- CATEGORIES -->
    <div class="flex flex-1 gap-x-2 text-sm">
      <span *ngFor="let cat of categories; let last = last"
            class="font-medium text-white p-1 px-4"
      [ngClass]="[cat.color | prefix: 'bg']">
        {{cat.name}}
      </span>
    </div>

    <!--<div *ngIf="perex" class="text-white" [ngClass]="[(titleText?.color ?? 'white') | prefix:'text']">
      {{perex}}
    </div>-->
  </div>
</div>
