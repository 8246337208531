import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WysiwygFieldComponent } from './wysiwyg-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
import { DynamicComponentRegistryService } from '@echo-nx/shared/ng/feature/common';
import { WYSIWYG_FORMFIELD_CONFIG, WysiwygFormFieldConfig } from './config';

@NgModule({
  declarations: [WysiwygFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
    FroalaEditorModule,
  ],
})
export class WysiwygFieldModule {
  /**
   * forRoot method for setting user configuration
   *
   * @param config
   */
  static forRoot(
    config: WysiwygFormFieldConfig
  ): ModuleWithProviders<WysiwygFieldModule> {
    return {
      ngModule: WysiwygFieldModule,
      providers: [
        {
          provide: WYSIWYG_FORMFIELD_CONFIG,
          useValue: config,
        },
      ],
    };
  }

  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add(
      'PkInputWysiwygComponent',
      WysiwygFieldComponent
    );
  }
}
