import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CardModule} from "@echo-nx/shared/ng/ui/generic";
import {MatRippleModule} from "@angular/material/core";
import {FilterPipeModule, FormatPipeModule, SharedNgFeatureCommonModule} from "@echo-nx/shared/ng/feature/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {UploadMediaComponent} from "./upload-media.component";

@NgModule({
  declarations: [
    UploadMediaComponent
  ],
  imports: [
    FormatPipeModule,
    FilterPipeModule,
    CommonModule,
    CommonModule,
    CardModule,
    MatRippleModule,
    SharedNgFeatureCommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
  ],
  exports: [
    UploadMediaComponent
  ],
})
export class MediaUploadModule {}
