import { Injectable, InjectionToken } from '@angular/core';
import { Apollo, MutationResult } from 'apollo-angular';
import {
  CommonEntityTypes,
  FetchAllArgs,
  IBaseOwner,
  ICategory,
  IEntityService,
  IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult,
  IArticle,
  IPaginatedLanguageGroup,
} from '@echo-nx/shared/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  readAllArticles,
  readAllCategories,
  readSelectedArticle,
} from '../queries';
import { addArticle, deleteArticle } from '../mutations';
import { readArticleLanguageGroups } from '../queries/articles/read-articles-language-groups';

@Injectable()
export class BaseArticlesService implements IEntityService<IArticle> {
  constructor(protected apollo: Apollo) {}

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IArticle>> {
    return this.apollo
      .query<IPKApolloResult<IPaginatedResponse<IArticle>>>({
        query: readAllArticles,
        variables: args,
      })
      .pipe(map((result) => result.data.response));
  }

  fetchLanguageGroups(
    args?: FetchAllArgs
  ): Observable<IPaginatedLanguageGroup<IArticle>> {
    return this.apollo
      .query<IPKApolloResult<IPaginatedLanguageGroup<IArticle>>>({
        query: readArticleLanguageGroups,
        variables: args,
      })
      .pipe(map((result) => result.data.response));
  }

  fetchSelected(ids: string[]): Observable<IArticle[]> {
    return this.apollo
      .query<IPKApolloResult<IArticle[]>>({
        query: readSelectedArticle,
        variables: {
          ids: ids,
        },
      })
      .pipe(map((res) => res.data.response));
  }

  fetchSingle(id: string): Observable<IArticle> {
    return this.fetchSelected([id]).pipe(map((res) => res[0] || null));
  }

  getType(): string {
    return CommonEntityTypes.Article;
  }

  delete(
    ids: string[]
  ): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    return this.apollo.mutate<IPKApolloResult<IMutationResponse>>({
      mutation: deleteArticle,
      variables: { input: ids },
    });
  }

  save(
    articles: IArticle[]
  ): Observable<MutationResult<IPKApolloResult<IArticle[]>>> {
    return this.apollo.mutate<IPKApolloResult<IArticle[]>>({
      mutation: addArticle,
      variables: { input: articles },
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(
    language: string,
    owner?: string
  ): Observable<ICategory<IBaseOwner>[]> {
    return this.apollo
      .query<IPKApolloResult<IPaginatedResponse<ICategory<IBaseOwner>>>>({
        query: readAllCategories,
        variables: {
          filter: JSON.stringify({
            type: this.getType(),
            language: language,
            owner,
          }),
        } as FetchAllArgs,
      })
      .pipe(map((result) => result?.data?.response?.items ?? []));
  }
}

export const BASE_ARTICLES_SERVICE_TOKEN = new InjectionToken<
  IEntityService<IArticle>
>('BASE_ARTICLES_SERVICE_TOKEN');
