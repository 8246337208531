<google-map
  #mapEl
  [width]="'100%'"
  [height]="600"
  [center]="center"
  (boundsChanged)="_boundsChanged()"
  [options]="options"
>
  <map-marker
    #marker="mapMarker"
    *ngFor="let point of mapMarkers"
    [options]="point.markerOptions"
    (mapClick)="openInfoWindow(marker, point.poi)"
  >
  </map-marker>
  <map-info-window (close)="closeInfoWindow()" [options]="infoWindowOptions">
    <ng-content></ng-content>
  </map-info-window>
  <map-kml-layer *ngIf="kmlOverlayUrl" [url]="kmlOverlayUrl"></map-kml-layer>
</google-map>
