import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {IVTPoint} from "@echo-nx/shared/common";

@Component({
  selector: 'marlenka-virtual-tour-point-detail',
  templateUrl: './virtual-tour-point-detail.component.html',
  styleUrls: ['./virtual-tour-point-detail.component.scss']
})
export class VirtualTourPointDetailComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IVTPoint) {}
}
