import {DateTime} from 'luxon';

import {
  IMarlenkaOwner,
  IMEvent,
  IMEventLanguage,
  IMEventTopic,
} from '@echo-nx/marlenka/common';
import { ICategory } from '@echo-nx/shared/common';
import { TableField } from '@echo-nx/shared/ng/feature/common';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import {
  EventsService,
  ReservationService,
} from '@echo-nx/marlenka/ng/data-access';
import { debounceTime, map, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MarlenkaNotificationDialogComponent } from '../marlenka-notification-dialog/marlenka-notification-dialog.component';

@Component({
  selector: 'marlenka-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventListComponent implements OnDestroy {
  private readonly isDestroyed$: Subject<boolean> = new Subject<boolean>();
  public readonly eventTableFields: TableField<IMEvent>[] = [
    {
      key: 'eventLanguage',
      label: 'Jazyk',
      formatter: (item) =>
        (item.eventLanguage as IMEventLanguage | undefined)?.nameShort ?? '',
    },
    {
      key: 'start',
      label: 'Čas',
      formatter: item => DateTime.fromJSDate(item.start).toFormat('HH:mm')
    },
    {
      key: 'eventTopic',
      label: 'Okruh',
      formatter: (item) =>
        (item.eventTopic as IMEventTopic | undefined)?.name ?? '',
    },
    {
      key: 'availableCapacity',
      label: 'Volná místa',
    },
    // {
    //   key: 'duration',
    //   label: 'Délka',
    //   formatter: item => `${item.duration} min`
    // }
  ];
  public readonly dayEvents$: Observable<IMEvent[]>;

  constructor(
    public eventsService: EventsService,
    public reservationService: ReservationService,
    private dialog: MatDialog
  ) {
    // filter out old stuff
    this.dayEvents$ = combineLatest([
      this.eventsService.filter$.pipe(debounceTime(175)),
      this.eventsService.dayEvents$,
    ]).pipe(
      map(([filter, events]) => {
        const currentEvents = events.filter(ev => DateTime.fromJSDate(ev.start) > DateTime.now());
        return currentEvents.map(event => ({
          ...event,
          backgroundColor:
            event.availableCapacity >= filter.people ? '#ffc400' : undefined,
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }

  public eventSelected(event: IMEvent<ICategory<IMarlenkaOwner>>) {
    if ((event.eventTopic as IMEventTopic | undefined)?.warningContent) {
      const dialogRef = this.dialog.open(MarlenkaNotificationDialogComponent, {
        height: '400px',
        width: '600px',
        panelClass: 'marlenka-notification-dialog',
        data: {
          title: (event.eventTopic as IMEventTopic).warningTitle ?? 'Varování',
          content: (event.eventTopic as IMEventTopic).warningContent,
        },
        disableClose: true,
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this.reservationService.selectedEvent = event;
          }
        });
      return;
    }
    if ((event.eventLanguage as IMEventLanguage | undefined)?.warningContent) {
      const dialogRef = this.dialog.open(MarlenkaNotificationDialogComponent, {
        height: '400px',
        width: '600px',
        panelClass: 'marlenka-notification-dialog',
        data: {
          title:
            (event.eventLanguage as IMEventLanguage).warningTitle ?? 'Varování',
          content: (event.eventLanguage as IMEventLanguage).warningContent,
        },
        disableClose: true,
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this.reservationService.selectedEvent = event;
          }
        });
      return;
    }
    console.log('event selected', event);
    this.reservationService.selectedEvent = event;
  }
}
