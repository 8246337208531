<div
  class="flex border-t border-black pt-6"
  [ngClass]="[
    baseOrientation | prefix: 'base-orientation',

  ]"
  #responsive="echoNxResponsive"
  [class.h-full]="fluid === 'vertical' || fluid === 'both'"
  [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
  [echoNxResponsive]="breakpointSettings"
>
  <!-- IMAGE -->
  <div *ngIf="image" [ngClass]="[isVertical  ? 'h-64' : 'basis-1/4']">
    <!-- image   -->
    <img
      class="object-cover w-full h-full"
      [src]="image.thumbnailUrl ?? image.url"
      [alt]="image.alt"
    />
  </div>

  <!-- CONTENT -->
  <div
    class="flex flex-col space-y-4 p-2 h-full"
    [ngClass]="[!isVertical  ? 'basis-3/4' : '']"
  >
    <!-- title, attributes -->
    <div
      class="flex flex-col space-y-2 px-4 py-2 items-center sm:items-start text-center sm:text-start"
      [ngClass]="[
        titleText?.color | prefix: 'text'
      ]"
    >
      <!-- title -->
      <div
        class="text-xl uppercase underline font-normal font-display"
        [ngClass]="[titleText?.color | prefix: 'text']"
      >
        {{ title }}
      </div>
    </div>

    <!-- date -->
    <div class="flex px-3 font-bold text-base self-center sm:self-start text-center sm:text-start">
      <!-- date -->
      <div
        class="font-medium"
        *ngIf="date"
        [ngClass]="[
          dateText?.color | prefix: 'text'
        ]"
      >
        {{ date }}
      </div>

    </div>
  </div>
</div>
