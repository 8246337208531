import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SliderVariantCComponent} from './slider-variant-c.component';
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    SliderVariantCComponent
  ],
  imports: [
    CommonModule,
    PrefixModule,
    //BrowserAnimationsModule
  ],
  exports: [
    SliderVariantCComponent,

  ]
})
export class SliderVariantCModule {
}
