import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject, switchMap, timer } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import {
  IBusDepartureData,
  IBusDepartureDesign,
  IEpxFluid,
} from '@echo-nx/shared/common';

interface TimetableRow {
  line: string;
  direction: string;
  departure: string;
  platform: string;
  delay: string;
}

@Component({
  selector: 'echo-nx-bus-departure',
  templateUrl: './bus-departure.component.html',
  styleUrls: ['./bus-departure.component.scss'],
})
export class BusDepartureComponent
  implements
    IBusDepartureData,
    IBusDepartureDesign,
    OnChanges,
    OnDestroy,
    OnInit
{
  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      dark: this.isDark,
    };
  }

  @Input()
  stopId: string;

  @Input()
  mode: 'Departures' | 'Arrivals';

  @Input()
  maxLines: number;

  @Input()
  showOngoing: boolean;

  @Input()
  modifyHeader = true;

  @Input()
  endpoint = '/api/bus-departure';

  @Input()
  lineBg: string;

  @Input()
  oddLineBg: string;

  @Input()
  primaryColor: string;

  @Input()
  stationName: string;

  @Input()
  bgColor: string;

  @Input()
  fluid: IEpxFluid;

  @Input()
  panelClass: string;

  @Input()
  isDark = true;

  /* Setters for Smart Component */
  @Input()
  set design({
    maxLines,
    bgColor,
    isDark,
    lineBg,
    oddLineBg,
    primaryColor,
    showOngoing,
    modifyHeader,
  }: IBusDepartureDesign) {
    this.maxLines = maxLines;
    this.bgColor = bgColor;
    this.isDark = isDark || true;
    this.lineBg = lineBg || 'white';
    this.oddLineBg = oddLineBg;
    this.primaryColor = primaryColor;
    this.showOngoing = showOngoing;
    this.modifyHeader = modifyHeader;
  }

  @Input()
  set data({ mode, stopId, endpoint, stationName }: IBusDepartureData) {
    this.mode = mode;
    this.stopId = stopId;
    this.stationName = stationName;
    this.endpoint = endpoint;
  }

  public table$: Observable<TimetableRow[]>;
  public syncTime: string;

  private isDestroyed$ = new Subject<void>();
  private inputChanged$ = new Subject<void>();

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  ngOnInit(): void {
    this.endpoint = '/api/bus-departure';
    this.table$ = this.inputChanged$.pipe(
      startWith(null),
      takeUntil(this.isDestroyed$),
      switchMap(() =>
        timer(0, 1000 * 60).pipe(
          takeUntil(this.isDestroyed$),
          switchMap(async () =>
            fetch(
              `${this.endpoint}?${new URLSearchParams({
                stopId: this.stopId,
                mode: this.mode,
              })}`,
              {
                method: 'GET',
              }
            )
              .then((response) => response.text())
              .then((txt) => {
                const date = new Date();
                this.syncTime = `${String(date.getHours()).padStart(
                  2,
                  '0'
                )}:${String(date.getMinutes()).padStart(2, '0')}`;
                return this.parseHtmlToJson(txt);
              })
          )
        )
      )
    );
  }

  ngOnChanges(): void {
    this.inputChanged$.next();
  }

  private parseHtmlToJson(txtHtml: string): any[] {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(txtHtml, 'text/html');
    const jsonResult = [];

    // Vybereme všechny `.timetable-row` kromě prvního, který obsahuje hlavičky
    const rows = htmlDoc.querySelectorAll('.timetable-row:not(:first-child)');

    rows.forEach((row) => {
      if (jsonResult.length < this.maxLines) {
        const cells = row.querySelectorAll('.timetable-item');
        const line = cells[0]?.querySelector('.timetable-value div')?.textContent.trim();
        const direction = cells[1]?.querySelector('.timetable-value')?.textContent.trim();
        const departure = cells[3]?.textContent.replace('Odjezd:', '').trim();
        const platform = cells[4]?.textContent.replace('Nást.:', '').trim();
        const delayElement = cells[5]?.textContent.replace('Zpož.:', '').trim();
        const delay = delayElement !== "" ? `${delayElement} min` : null;

        jsonResult.push({
          line,
          direction,
          departure,
          platform,
          delay
        });
      }
    });

    return jsonResult;
  }
}
