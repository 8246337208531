import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarVariantNyrovComponent } from "./navbar-variant-nyrov.component";
import { NavDropdownItemModule } from '../../nav-dropdown-item/nav-dropdown-item.module';
import { PrefixModule } from '../../../prefix/prefix.module';

@NgModule({
  declarations: [
    NavbarVariantNyrovComponent
  ],
  imports: [
    CommonModule,
    PrefixModule,
    NavDropdownItemModule
  ],
  exports: [
    NavbarVariantNyrovComponent
  ]
})
export class NavbarVariantNyrovModule {
}
