import {Injectable, InjectionToken} from "@angular/core";
import {
  CommonEntityTypes,
  FetchAllArgs, IBaseAuth, IBaseOwner,
  ICategory,
  IEntityService, IMutationResponse, IOwnedContact,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {concat, finalize, forkJoin, merge, Observable, of, switchMap} from "rxjs";
import {Apollo, MutationResult} from "apollo-angular";
import {readAllAuths, readAllContacts, readSelectedContact} from "../queries/contact";
import {map} from "rxjs/operators";
import {readAllCategories} from "../queries";
import {deleteContact, addContact, deleteAuth} from "../mutations/contact";

@Injectable()
export class BaseContactService implements IEntityService<IOwnedContact> {

  constructor(protected apollo: Apollo) {
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IBaseAuth<IBaseOwner>>>>({
      query: readAllAuths,
      variables: {
        filter: JSON.stringify({
          user: {
            $in: ids
          }
        })
      } as FetchAllArgs
    }).pipe(
      map(result => result.data.response?.items?.map(auth => auth._id)),
      switchMap(authIds => merge(
        this.apollo.mutate<IPKApolloResult<IMutationResponse>>({
          mutation: deleteAuth,
          variables: {input: authIds, forceDelete: true}
        }),
        this.apollo.mutate<IPKApolloResult<IMutationResponse>>({
          mutation: deleteContact,
          variables: {input: ids,  forceDelete: true}
        })
      )),
    );
  }

  executeMutation(mutation: any, vars: any): any {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IOwnedContact>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IOwnedContact>>>({
      query: readAllContacts,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchCategories(language: string, owner?: string): Observable<ICategory<IBaseOwner>[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory<IBaseOwner>>>>({
      query: readAllCategories,
      variables: {
        filter: JSON.stringify({
          type: this.getType(),
          language: language,
          owner
        })
      } as FetchAllArgs
    }).pipe(
      map(result => result?.data?.response?.items ?? [])
    );
  }

  fetchSelected(ids: string[]): Observable<IOwnedContact[]> {
    return this.apollo.query<IPKApolloResult<IOwnedContact[]>>({
      query: readSelectedContact,
      variables: {
        ids: ids
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSingle(id: string): Observable<IOwnedContact> {
    return this.fetchSelected([id]).pipe(
      map(users => users[0])
    );
  }

  getType(): string {
    // todo should be contact perhaps?
    return CommonEntityTypes.Contact;
  }

  save(entities: IOwnedContact[]): Observable<MutationResult<IPKApolloResult<IOwnedContact[]>>> {
    return this.apollo.mutate<IPKApolloResult<IOwnedContact<IBaseOwner>[]>>({
      mutation: addContact,
      variables: {input: entities}
    });
  }
}

export const BASE_CONTACT_SERVICE_TOKEN = new InjectionToken<IEntityService<IOwnedContact>>('BASE_CONTACT_SERVICE_TOKEN');

