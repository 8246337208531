<nav class="w-full relative h-12 sm:h-20 font-body">
  <div class="absolute flex h-full inset-0">
    <div [ngClass]="[primaryColor | prefix: 'bg']" class="flex-1 h-full"></div>
    <div [ngClass]="[bgColor | prefix: 'bg']" class="flex-1 h-full"></div>
  </div>

  <div
    [ngClass]="[bgColor | prefix: 'bg']"
    class="relative h-full inset-0 flex container mx-auto justify-between"
  >
    <!-- LOGO -->
    <div
      (click)="itemClicked.next(navigation?.screen?.slug ?? '/')"
      class="box-border h-full cursor-pointer flex items-center sm:pl-0 sm:p-4 px-4 py-1 rounded-b-xl sm:rounded-bl-none sm:rounded-br-2xl order-2 sm:order-1"
      [ngClass]="[primaryColor | prefix: 'bg']"
    >
      <img
        class="hidden h-full sm:block"
        [src]="fullLogo?.url ?? smallLogo?.url"
        [alt]="fullLogo?.alt ?? smallLogo?.alt"
      />
      <img
        class="h-full block sm:hidden"
        [src]="smallLogo?.url ?? fullLogo?.url"
        [alt]="smallLogo?.alt ?? fullLogo?.alt"
      />
    </div>

    <!-- NAV ITEMS -->
    <div
      class="flex sm:flex-1 items-center items-center p-2 sm:p-4 order-1 sm:order-2"
    >
      <div class="hidden sm:flex">
        <echo-nx-nav-dropdown-item
          (itemClicked)="itemClicked.next($event)"
          [item]="navigation"
        ></echo-nx-nav-dropdown-item>
      </div>

      <div
        class="flex sm:hidden relative cursor-pointer"
        style="width: 2.25rem; height: 2.25rem"
        (click)="toggleSideNav()"
      >
        <div
          class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
          <span
            class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
            [ngClass]="{
              'rotate-45': sideMenuActive,
              ' -translate-y-2': !sideMenuActive
            }"
          ></span>
          <span
            class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
            [ngClass]="{ 'opacity-0': sideMenuActive }"
          ></span>
          <span
            class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
            [ngClass]="{
              '-rotate-45': sideMenuActive,
              ' translate-y-2': !sideMenuActive
            }"
          ></span>
        </div>
      </div>
    </div>

    <!-- SEARCH -->
    <div class="flex items-center justify-end order-3">
      <form
        (submit)="onSearchSubmit($event, searchInput.value)"
        class="hidden sm:block relative"
      >
        <input
          #searchInput
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 mr-4"
          placeholder="Vyhledat..."
          type="text"
        />
        <button
          type="submit"
          class="absolute top-0 bottom-0 right-0 bg-primary-500 text-white font-medium rounded-r-lg text-sm p-2.5 text-center inline-flex items-center mr-2"
        >
          <i class="fas fa-search"></i>
        </button>
      </form>
      <div
        class="flex sm:hidden items-center justify-center cursor-pointer p-2 text-lg"
        (click)="showSearch()"
        [ngClass]="[textColor | prefix: 'text']"
      >
        <i class="fas fa-search"></i>
      </div>
    </div>
  </div>
</nav>
