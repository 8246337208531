import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CookieConsentComponent} from "./cookie-consent.component";
import {DynamicComponentRegistryService, SharedNgFeatureCommonModule} from "@echo-nx/shared/ng/feature/common";
import {MatButtonModule} from "@angular/material/button";
import {SharedNgFeatureLanguageModule} from "@echo-nx/shared/ng/feature/language";
import { CustomizeCookiesComponent } from './customize-cookies/customize-cookies.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    CookieConsentComponent,
    CustomizeCookiesComponent
  ],
  imports: [
    SharedNgFeatureLanguageModule,
    SharedNgFeatureCommonModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule
  ],
  exports: [
    CookieConsentComponent
  ]
})
export class MarlenkaCookieConsentModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('MarlenkaCookieConsent', CookieConsentComponent);
  }
}
