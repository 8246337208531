import gql from 'graphql-tag';

export const readSelectedKiosks = gql`
  query ReadSelectedKiosks($ids: [String!]) {
    response: readSelectedKiosk(ids: $ids) {
      _id
      createdAt
      updatedAt
      name
      electronUniqueId
      pwaUniqueId
      externalUrl
      timeline {
        _id
        name
      }
      idleTimeout
      timelineTimeout
      location {
        street
        province
        zipcode
        city
        state
      }
      timeSchedules {
        timeOn
        timeOff
        weekday
      }
      screenshot {
        _id
        url
        createdAt
      }
      browserSettings {
        blacklist
        whitelist
        boundaries {
          normalOffsetTop
          normalOffsetBottom
          accessibilityOffsetTop
          accessibilityOffsetBottom
          barHeight
          useAccessibility
        }
        corsModifierBlacklist
        themeProps {
          accentColor
          accentTextColor
          primaryColor
          primaryTextColor
          secondaryColor
          secondaryTextColor
        }
      }
      macAddress
      homescreen {
        _id
        name
      }
      pin
      owner {
        _id
        name
      }
      role {
        _id
        name
        description
      }
    }
  }
`;
