import { Injectable, InjectionToken } from '@angular/core';
import { Apollo, MutationResult } from 'apollo-angular';
import {
  addEventLanguage,
  deleteEventLanguage,
  IMarlenkaOwner,
  IMEventLanguage,
  MarlenkaEntityTypes,
  readAllEventLanguages,
  readSelectedEventLanguages,
} from '@echo-nx/marlenka/common';
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedResponse,
  IPKApolloResult,
} from '@echo-nx/shared/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BaseEventLanguageService
  implements IEntityService<IMEventLanguage>
{
  constructor(protected apollo: Apollo) {}

  fetchAll(
    args?: FetchAllArgs
  ): Observable<IPaginatedResponse<IMEventLanguage>> {
    return this.apollo
      .query<IPKApolloResult<IPaginatedResponse<IMEventLanguage>>>({
        query: readAllEventLanguages,
        variables: args,
      })
      .pipe(map((result) => result.data.response));
  }

  fetchSelected(ids: string[]): Observable<IMEventLanguage[]> {
    return this.apollo
      .query<IPKApolloResult<IMEventLanguage[]>>({
        query: readSelectedEventLanguages,
        variables: {
          ids: ids,
        },
      })
      .pipe(map((res) => res.data.response));
  }

  fetchSingle(id: string): Observable<IMEventLanguage> {
    return this.apollo
      .query<IPKApolloResult<IMEventLanguage[]>>({
        query: readSelectedEventLanguages,
        variables: {
          ids: [id],
        },
      })
      .pipe(map((res) => res.data.response[0] || null));
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteEventLanguage,
      variables: { input: ids },
    });
  }

  save(
    events: IMEventLanguage[]
  ): Observable<MutationResult<IPKApolloResult<IMEventLanguage[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMEventLanguage[]>>({
      mutation: addEventLanguage,
      variables: { input: events },
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    throw Error('NOT IMPLEMENTED :(');
  }

  getType(): string {
    return MarlenkaEntityTypes.EventLanguage;
  }
}

export const MEVENT_LANGUAGE_SERVICE_TOKEN = new InjectionToken<
  IEntityService<IMEventLanguage>
>('MEVENT_LANGUAGE_SERVICE_TOKEN');
