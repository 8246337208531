import {getMaximumFreeCapacity, getTotalCapacity, IMEvent, IMFilter} from "@echo-nx/marlenka/common";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {getDaysInMonth, isSameDate, pluralize} from "@echo-nx/shared/common";
import {Apollo} from "apollo-angular";
import {CalendarDay, CalendarDaySettings, getBackgroundColor} from "@echo-nx/shared/ng/feature/reservation";
import {BaseEventService} from "./base-event.service";
import {DateTime} from 'luxon';

import * as chroma from 'chroma-js';


@Injectable()
export class EventsService extends BaseEventService {
  public days!: CalendarDay<CalendarDaySettings>[];
  public selectedDate: Date;
  public selectedMonth: Date;

  public events: IMEvent[] = [];
  private readonly _dayEvents: BehaviorSubject<IMEvent[]>;
  public readonly dayEvents$: Observable<IMEvent[]>;

  private readonly _filter: BehaviorSubject<IMFilter>;
  public readonly filter$: Observable<IMFilter>;


  constructor(apollo: Apollo) {
    super(apollo);
    this._dayEvents = new BehaviorSubject<IMEvent[]>([]);
    this.dayEvents$ = this._dayEvents.asObservable();

    this._filter = new BehaviorSubject<IMFilter>({
      language: 'cs',
      people: 4
    });
    this.filter$ = this._filter.asObservable();
    this.selectedDate = new Date();
    this.selectedMonth = new Date();
  }


  set dayEvents(events: IMEvent[]) {
    this._dayEvents.next(events);
  }

  get filter() {
    return this._filter.getValue();
  }

  set filter(filter: IMFilter) {
    this._filter.next(filter);
  }

  public getDaysWithEvents(date: Date, events: IMEvent[], filter: IMFilter) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = getDaysInMonth(month, year);

    // map events to days
    const days: CalendarDay<CalendarDaySettings>[] = [];
    const yesterday = DateTime.now().minus({ day: 1 });

    for (let i = 0; i < daysInMonth; i++) {
      const day = new Date(year, month, i + 1);
      const eventsInDay = events.filter(event => isSameDate(event.start, day, 'day'));


      const totalFreeCapacity = getTotalCapacity(eventsInDay);
      const isFull = totalFreeCapacity === 0;
      const maximumFreeCapacity = getMaximumFreeCapacity(eventsInDay);
      const isHistorical = DateTime.fromJSDate(day) < yesterday;
      const background = getBackgroundColor(day, eventsInDay, isFull, isHistorical, filter);
      days.push({
        date: day,
        name: 'CalendarDayComponent',
        disabled: eventsInDay.length === 0 || isFull || isHistorical,
        settings: {
          text: eventsInDay.length === 0 ? 'zavřeno' : isFull ? 'obsazeno' : `${pluralize(maximumFreeCapacity, ['místo', 'místa', 'míst'])}`,
          textFontSize: '1.075rem',
          backgroundColor: background,
          dayFontSize: '1rem',
          color: chroma(background).luminance() > 0.6 ? '#6e4b00' : '#ffffff'
        }
      });
    }

    return days;
  }

  public fetchEventsInMonth(date: Date) {
    const start = DateTime.fromJSDate(date).startOf('month').toJSDate();
    const end = DateTime.fromJSDate(date).endOf('month').toJSDate();

    return this.fetchEventsInRange(start, end).toPromise();
  }

}
