<div class="top-row">
  <ng-content select="[buttons]"></ng-content>
</div>

<echo-nx-stepper panelClass="marlenkaStepper" linear #stepper>
  <cdk-step
    *ngFor="let step of steps; index as i"
    [completed]="step.completed | async"
    [optional]="step.optional"
  >
    <ng-template cdkStepLabel let-cdkstep>
      <echo-nx-step-label
        [title]="step.title | async"
        [subtitle]="step.subtitle | async"
      ></echo-nx-step-label>
    </ng-template>

    <!-- lazy load with the if -->
    <ng-container
      *ngIf="stepper.selectedIndex === i"
      [cdkPortalOutlet]="step.componentPortal"
    ></ng-container>
  </cdk-step>
</echo-nx-stepper>
<div class="bottom-row" style="margin-top: 2rem; z-index: 1010">
  <button
    class="previous-button"
    mat-stroked-button
    (click)="backClicked.emit(stepper.selectedIndex); stepper.previous()"
  >
    <mat-icon>arrow_back</mat-icon>
    <span class="text">Krok zpět</span>
  </button>

  <button
    class="next-button"
    mat-stroked-button
    (click)="onNextStep()"
    [disabled]="steps[stepper.selectedIndex].disableNextButton | async"
  >
    <span class="text">{{
      steps[stepper.selectedIndex].nextButtonText | async
    }}</span>
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
