<div
  class="flex"
  [ngClass]="[
    baseOrientation | prefix: 'base-orientation',
    primaryColor | prefix: 'bg'
  ]"
  #responsive="echoNxResponsive"
  [class.h-full]="fluid === 'vertical' || fluid === 'both'"
  [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
  [echoNxResponsive]="breakpointSettings"
>
  <!-- IMAGE, CATEGORIES -->
  <div
    *ngIf="image"
    class="relative flex"
    [ngClass]="[isVertical ? 'h-64' : 'max-h-64 max-w-sm']"
  >
    <!-- image   -->
    <img
      class="object-cover w-full h-full"
      [src]="image.thumbnailUrl ?? image.url"
      [alt]="image.alt"
    />

    <!-- categories -->
    <ng-container *ngTemplateOutlet="categoriesRef"></ng-container>
  </div>

  <!-- TEXTS, DATE, ... -->
  <div
    class="flex flex-col"
    [ngClass]="[
      secondaryColor | prefix: 'text',
      !isVertical && image ? 'basis-2/3' : ''
    ]"
  >
    <!-- HEADER -->
    <div class="flex flex-col">
      <!-- categories, if no image -->
      <ng-container *ngIf="!image">
        <ng-container *ngTemplateOutlet="categoriesRef"></ng-container>
      </ng-container>

      <div class="flex flex-col p-4 gap-y-2 items-start">
        <!-- DATE -->
        <span
          *ngIf="date"
          [ngClass]="[
            dateText?.bgColor | prefix: 'bg',
            dateText?.color | prefix: 'text'
          ]"
          >{{ date }}</span
        >

        <!-- TITLE -->
        <span
          class="text-2xl font-display font-medium"
          [ngClass]="[
            titleText?.bgColor | prefix: 'bg',
            titleText?.color | prefix: 'text'
          ]"
        >
          {{ title }}
        </span>

        <!-- ATTRIBUTES -->
        <ng-container *ngIf="attributes">
          <div class="gap-x-2 flex items-center font-base text-sm"
               *ngFor="let attribute of attributes">
            <i *ngIf="attribute.startIcon" [ngClass]="attribute.startIcon"></i>
            <span>{{ attribute.text }}</span>
            <i *ngIf="attribute.endIcon" [ngClass]="attribute.endIcon"></i>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- PEREX, FOOTER -->
    <!-- todo textColor ? -->
    <div *ngIf="perex || footer" class="flex flex-col p-4 gap-y-4" [ngClass]="[accentColor | prefix: 'bg']">
      <!-- perex -->
      <div
        *ngIf="perex"
        class="prose max-w-none"
        style="color: inherit"
        [innerHTML]="perex | safeHtml"
      ></div>

      <!-- footer -->
      <div class="flex flex-row gap-x-2">
        <!-- icon -->
        <div
          *ngIf="footerText?.icon"
          [ngClass]="[
            footerText?.bgColor | prefix: 'bg',
            footerText?.color | prefix: 'text'
          ]"
        >
          <i [ngClass]="footerText.icon"></i>
        </div>
        <!-- text -->
        <span>{{ footer }}</span>
      </div>
    </div>
  </div>
</div>

<ng-template #categoriesRef>
  <!-- categories -->
  <div class="flex items-start"
       [class.absolute]="!!image"
       [class.top-0]="baseOrientation !== 'col-reverse'"
       [class.bottom-0]="baseOrientation === 'col-reverse'"
       [class.right-0]="baseOrientation === 'row-reverse'"
       [class.flex-col]="isVertical"
       [ngClass]="[isVertical ? 'gap-y-2' : 'gap-x-2']">
      <div *ngFor="let cat of categories"
            class="py-2 px-4 flex items-center justify-center text-white gap-x-2"
            [ngClass]="[cat.color | prefix:'bg']">
        <i *ngIf="cat.icon" class="fa-fw" [ngClass]="[cat?.icon]"></i>
        <span class="font-medium text-base">{{cat.name}}</span>
      </div>
  </div>
</ng-template>
