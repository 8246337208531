import { Component, HostBinding, Input } from '@angular/core';
import {
  IBaseDatetimeBarData,
  IBaseDatetimeBarDesign,
} from '@echo-nx/shared/common';
import { DateTime } from 'luxon';
import { Observable, distinctUntilChanged, interval, map, startWith, switchMap } from 'rxjs';
import { NameAndHolidayInfo, NamesAndHolidaysService } from '../names-holidays.service';

@Component({
  selector: 'echo-nx-date-time-bar-variant-nyrov',
  templateUrl: './date-time-bar-variant-nyrov.component.html',
  styleUrls: ['./date-time-bar-variant-nyrov.component.scss'],
})
export class DateTimeBarVariantNyrovComponent
  implements IBaseDatetimeBarData, IBaseDatetimeBarDesign {
  @Input()
  accentColor?: string;

  @Input()
  primaryColor?: string;

  @Input()
  secondaryColor: string;

  @Input()
  textColor?: string;

  @Input()
  title: string;

  /* Current screens do not have fluid set - remove default when screens are fixed */
  @Input()
  fluid: 'horizontal' | 'vertical' | 'both' = 'horizontal';

  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal',
    };
  }

  @Input()
  set data(data: IBaseDatetimeBarData) {
    this.title = data?.title;
  }

  @Input()
  set design({
    primaryColor,
    accentColor,
    secondaryColor,
    textColor,
    fluid,
  }: IBaseDatetimeBarDesign) {
    this.primaryColor = primaryColor;
    this.accentColor = accentColor;
    this.secondaryColor = secondaryColor;
    this.textColor = textColor;
    /* Current screens do not have fluid set - remove default when screens are fixed */
    this.fluid = fluid || 'horizontal';
  }

  // TODO: need to be implemented

  public dateTime$: Observable<string> = interval(1000).pipe(
    startWith(() => new Date()),
    map(() => new Date()),
    map(x => DateTime.fromJSDate(x).toFormat('dd.MM.yyyy')),
    distinctUntilChanged()
  );

  public nameAndHoliday$: Observable<NameAndHolidayInfo> =
    this.dateTime$.pipe(
      map(x => DateTime.fromFormat(x, 'dd.MM.yyyy')),
      switchMap(d => this.service.getInfo(d.toJSDate()))
    )

  constructor(private readonly service: NamesAndHolidaysService) { }
}
