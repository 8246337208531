<form content [formGroup]="contactForm">
  <div class="wrap">
    <marlenka-section-header name="Osobní údaje" style="padding: 2rem">
      <div class="section" content>
        <!-- JMENO A PRIJMENI -->
        <div class="dual-row">
          <mat-form-field appearance="fill" formGroupName="person">
            <mat-label>Jméno</mat-label>
            <input
              matInput
              type="text"
              formControlName="name"
              autocomplete="given-name"
            />
            <mat-error *ngIf="contactForm.hasError('required', 'person.name')">
              Jméno je povinné.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" formGroupName="person">
            <mat-label>Příjmení</mat-label>
            <input
              matInput
              type="text"
              formControlName="surname"
              autocomplete="family-name"
            />
            <mat-error
              *ngIf="contactForm.hasError('required', 'person.surname')"
            >
              Příjmení je povinné.
            </mat-error>
          </mat-form-field>
        </div>

        <!-- EMAIL -->
        <mat-form-field appearance="fill" formGroupName="person">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            autocomplete="email"
          />
          <mat-error *ngIf="contactForm.hasError('required', 'person.email')">
            Email je povinný.
          </mat-error>
          <mat-error *ngIf="contactForm.hasError('error', 'person.email')">
            {{ contactForm.getError('error', 'person.email') }}
          </mat-error>
        </mat-form-field>

        <!-- TEL.CISLO -->
        <mat-form-field appearance="fill" formGroupName="person">
          <mat-label>Telefonní číslo</mat-label>
          <input matInput type="tel" formControlName="tel" autocomplete="tel" />
          <mat-error *ngIf="contactForm.hasError('required', 'person.tel')">
            Telefonní číslo je povinné.
          </mat-error>
          <mat-error *ngIf="contactForm.hasError('telephoneInt', 'person.tel')">
            Telefonní číslo zadejte s mezinárodní předponou a bez mezer!
          </mat-error>
        </mat-form-field>

        <!-- POZNAMKY -->
        <mat-form-field appearance="fill" formGroupName="person">
          <mat-label>Poznámky k rezervaci</mat-label>
          <textarea
            matInput
            type="text"
            formControlName="note"
            rows="2"
          ></textarea>
        </mat-form-field>

        <!-- GDPR -->
        <div class="fx-row space-between">
          <ng-container formGroupName="person">
            <mat-checkbox formControlName="gdpr"
              >Souhlasím se
              <a
                (click)="
                  stopPropagationAndOpen(
                    $event,
                    '/assets/zasady_ochrany_osobnich_udaju.pdf'
                  )
                "
              >
                zpracováním osobních údajů </a
              >*
            </mat-checkbox>
            <mat-checkbox formControlName="tos"
              >Souhlasím s
              <a
                (click)="
                  stopPropagationAndOpen(
                    $event,
                    '/assets/obch_podm_marlenka_2023.pdf'
                  )
                "
              >
                obchodními podmínkami </a
              >*
            </mat-checkbox>
          </ng-container>
          <!-- NAKUP NA FIRMU? -->
          <mat-checkbox formControlName="isCompany"
            >Nakupuji na firmu
          </mat-checkbox>
        </div>
      </div>
    </marlenka-section-header>

    <marlenka-section-header
      name="Firemní údaje"
      *ngIf="contactForm.get('isCompany').value"
      style="padding: 2rem"
    >
      <div content class="section">
        <!-- ICO -->
        <div class="dual-row">
          <mat-form-field appearance="fill" formGroupName="company">
            <mat-label>IČO</mat-label>
            <input matInput type="tel" maxlength="8" formControlName="ICO" />
            <mat-error *ngIf="contactForm.hasError('required', 'company.ICO')">
              IČO je povinné.
            </mat-error>
            <mat-error *ngIf="contactForm.hasError('minlength', 'company.ICO')">
              IČO musí být
              {{
                contactForm.get('company.ICO').errors.minlength.requiredLength
              }}
              znaků dlouhé.
            </mat-error>
          </mat-form-field>

          <button
            type="button"
            class="echo-nx-button secondary spinner"
            mat-stroked-button
            [disabled]="
              fetchingAres || contactForm.get('company.ICO').value.length < 8
            "
            (click)="fetchFromAres()"
          >
            Doplnit z ARES
            <mat-spinner
              *ngIf="fetchingAres"
              diameter="25"
              color="accent"
            ></mat-spinner>
          </button>
        </div>

        <!-- DIC -->
        <mat-form-field
          appearance="fill"
          formGroupName="company"
          *ngIf="contactForm.get('company.paysTaxes')?.value"
        >
          <mat-label>DIČ</mat-label>
          <input matInput type="text" formControlName="DIC" />
          <mat-error *ngIf="contactForm.hasError('required', 'company.DIC')">
            DIČ je povinné.
          </mat-error>
        </mat-form-field>

        <!-- NAZEV -->
        <mat-form-field appearance="fill" formGroupName="company">
          <mat-label>Název</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error *ngIf="contactForm.hasError('required', 'company.name')">
            Název firmy je povinný.
          </mat-error>
        </mat-form-field>

        <!-- ADRESA -->
        <mat-form-field appearance="fill" formGroupName="company">
          <mat-label>Ulice</mat-label>
          <input
            matInput
            type="text"
            formControlName="street"
            autocomplete="street"
          />
          <mat-error *ngIf="contactForm.hasError('required', 'company.street')">
            Ulice firmy je povinná.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" formGroupName="company">
          <mat-label>Město</mat-label>
          <input
            matInput
            type="text"
            formControlName="city"
            autocomplete="city"
          />
          <mat-error *ngIf="contactForm.hasError('required', 'company.city')">
            Město firmy je povinná.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" formGroupName="company">
          <mat-label>PSČ</mat-label>
          <input
            matInput
            type="text"
            formControlName="PSC"
            autocomplete="zipcode"
          />
          <mat-error *ngIf="contactForm.hasError('required', 'company.PSC')">
            Poštovní Směrovací Číslo firmy je povinné.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" formGroupName="company">
          <mat-label>Stát</mat-label>
          <input
            matInput
            type="text"
            formControlName="state"
            autocomplete="state"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredStates | async"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="contactForm.hasError('required', 'company.state')">
            Stát firmy je povinný.
          </mat-error>
        </mat-form-field>

        <!-- PLÁTCE DPH -->
        <div formGroupName="company">
          <mat-checkbox formControlName="paysTaxes"
            >Firma je plátce DPH
          </mat-checkbox>
        </div>
      </div>
    </marlenka-section-header>
  </div>
</form>
