import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemDetailVariantNyrovComponent } from './item-detail-variant-nyrov.component';
import { PrefixModule } from '../../prefix/prefix.module';

@NgModule({
  declarations: [
    ItemDetailVariantNyrovComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ItemDetailVariantNyrovComponent
  ],
})
export class ItemDetailVariantNyrovModule { }
