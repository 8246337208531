import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SortableGridComponent} from './sortable-grid/sortable-grid.component';
import {MatButtonModule} from "@angular/material/button";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatIconModule} from "@angular/material/icon";
import {CheckPipesModule, FunctionPipeModule, ResolveKeyPipeModule} from "@echo-nx/shared/ng/feature/common";

@NgModule({
  imports: [CommonModule, MatButtonModule, DragDropModule, MatIconModule, CheckPipesModule,
    ResolveKeyPipeModule,
    FunctionPipeModule,],
  declarations: [
    SortableGridComponent
  ],
  exports:[
    SortableGridComponent
  ]
})
export class SharedNgFeatureSortableGridModule {}
