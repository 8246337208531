import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { forkJoin, of, Subject } from "rxjs";
import { IMedium, IPKApolloResult, roundToDecimals } from "@echo-nx/shared/common";
import { concatMap, defaultIfEmpty, filter, map, take, takeUntil, tap } from "rxjs/operators";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { IFileUploadContainer } from "./i-file-upload-container";
import { BaseMediumService } from "@echo-nx/shared/ng/data-access";
import { IInjectableServiceData } from "@echo-nx/shared/ng/feature/common";
import { MutationResult } from "apollo-angular";

@Component({
  selector: 'echo-nx-upload-media',
  templateUrl: './upload-media.component.html',
  styleUrls: ['./upload-media.component.scss']
})
export class UploadMediaComponent implements OnDestroy, OnInit {
  private _isDestroyed$ = new Subject<boolean>();

  public filesToUpload: IFileUploadContainer[] = [];
  public filesUploadProgress: number[] = [];

  @Input()
  public mediaServiceData!: IInjectableServiceData<BaseMediumService>; // is this :poop: code? idk. if you believe so, use injection tokens like a normal programmer.

  @Input()
  public uploadOnAdd = false;

  @Output()
  public uploadCompleted = new EventEmitter<{ error: boolean }[]>();

  // injected with mediaServiceData.token using Injector
  public mediaService!: BaseMediumService;

  constructor(private injector: Injector) {
  }

  ngOnInit() {
    const { token } = this.mediaServiceData;
    this.mediaService = this.injector.get<BaseMediumService>(token);
  }

  ngOnDestroy(): void {
    this._isDestroyed$.next(true);
    this._isDestroyed$.complete();
  }

  public uploadFiles() {
    if (!this.filesToUpload.length) {
      return this.uploadCompleted.emit([]);
    }
    const uploads$ = this.filesToUpload.map((fileUpload) => this.uploadFile(fileUpload))
    forkJoin(uploads$).pipe(take(1)).subscribe(res => this.uploadCompleted.emit(res));
  }

  private uploadFile(fileUpload: IFileUploadContainer){
    const { file } = fileUpload;
    let lastNow = new Date().getTime();
    let lastBytes = 0;
    return this.mediaService.upload(file,
      {
        name: file.name,
        mime: file.type,
        // todo add category?
      })
      .pipe(
        // this is basically tapOnce - set state to UPLOADING
        concatMap((value, index) => {
          if (index === 0) {
            return of(value).pipe(tap(() => {
              fileUpload.state = 'UPLOADING';
            }));
          } else {
            return of(value);
          }
        }),
        // calculate progress and upload speed
        tap((event) => {
          if (event?.type === HttpEventType.UploadProgress) {
            const { loaded, total } = event;
            if (loaded && total) {
              // we want to set 100% when the response is finished, not when upload is done
              // so we set 99% in here and 100% after the Response arrives from GQL
              const done = Math.round((100 * loaded) / total);
              fileUpload.progress = done === 100 ? 99 : done;

              // backend is now processing the uploaded file (thumbnails, S3 upload, ...)
              if (done === 100) {
                fileUpload.state = 'PROCESSING';
              }
            }

            const now = new Date().getTime();
            const elapsedSeconds = (now - lastNow) / 1000;
            const uploadedBytes = loaded - lastBytes;
            const bps = elapsedSeconds ? (uploadedBytes / elapsedSeconds) : 0;
            fileUpload.speed = roundToDecimals(bps / 1000);
            lastBytes = loaded;
            lastNow = now;
          }
        }),
        filter((event) => event?.type === HttpEventType.Response),
        // the backend responded after processing the media
        map((event) => {
          const body: MutationResult<IPKApolloResult<IMedium<any>>> = (<HttpResponse<any>>event)?.body;
          if (!body.errors) {
            fileUpload.progress = 100;
            fileUpload.state = 'COMPLETE';
            return {
              error: false,
              data: body.data?.response
            };
          } else {
            fileUpload.state = 'ERROR';
            return {
              error: true,
              data: body.errors
            }
          }
        }),
        // user can cancel the individual upload any time
        takeUntil(fileUpload.cancel),
        // if the user cancels, we must emit something in order for forkJoin to not close the other sources$
        defaultIfEmpty({ error: false }),
      )
  }


  public onFilesAdded(event: Event) {
    const { target } = event as HTMLInputEvent;
    const { files } = target;
    if (files && files.length > 0) {
      this._addFilesToUploadList(files);
    }
  }

  public onFilesDropped(event: DragEvent) {
    const { dataTransfer } = event;
    const { files } = dataTransfer ?? {};
    if (files && files.length > 0) {
      this._addFilesToUploadList(files);
    }
  }

  public removeFile(file: IFileUploadContainer, index: number) {
    file.cancel.next(true);
    this.filesToUpload.splice(index, 1);
  }

  private _addFilesToUploadList(fileList: FileList) {
    const addedItems = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const fileContainer = {
        file,
        progress: 0,
        cancel: new Subject()
      } as IFileUploadContainer;
      this.filesToUpload.push(fileContainer);
      addedItems.push(fileContainer);
    }

    if(this.uploadOnAdd){
      forkJoin(addedItems.map(file => this.uploadFile(file))).pipe(take(1)).subscribe(res => this.uploadCompleted.emit(res));
    }
  }
}


interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
