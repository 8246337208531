<!--dunno if divideSize should be connected to outer padding, but it looks really ugly without-->
<div
  *ngIf="navigation && navigation.children?.length > 0"
  class="flex flex-col"
  [class.h-full]="fluid === 'vertical' || fluid === 'both'"
  [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
  [ngClass]="[
    divideSize === 'medium' ? 'p-4' : divideSize === 'large' ? 'p-6' : 'p-2',
    applyBorder ? 'border' : '',
    dividerColor | prefix: 'border',
    bgColor | prefix: 'bg'
  ]"
>
  <span
    class="font-display font-bold text-xl mb-4"
    (click)="
      itemClicked.next(navigation.screen?.slug ?? navigation.externalLink)
    "
    [ngClass]="[
      titleColor ?? 'primary-500' | prefix: 'text',
      navigation.screen?.slug ?? navigation.externalLink
        ? (hoverColorful?.color | prefix: 'hover:text')
        : '',
      navigation.screen?.slug ?? navigation.externalLink
        ? (hoverColorful?.bgColor | prefix: 'hover:bg')
        : '',
      navigation.screen?.slug ?? navigation.externalLink ? 'cursor-pointer' : ''
    ]"
    *ngIf="!hideTitle"
  >
    {{ navigation.title }}
  </span>
  <ul
    *ngIf="navigation.children"
    class="flex flex-wrap justify-between"
    [ngClass]="[
      textColor | prefix: 'text',
      orientation | prefix: 'flex',
      orientation | prefix: 'divide',
      divideSize === 'medium'
        ? 'gap-4'
        : divideSize === 'large'
        ? 'gap-6'
        : 'gap-2'
    ]"
  >
    <ng-container *ngFor="let item of navigation.children; last as isLast">
      <li
        *ngIf="item.isActive"
        [ngClass]="[
          item.screen?.slug ?? item.externalLink
            ? (hoverColorful?.color | prefix: 'hover:text')
            : '',
          item.screen?.slug ?? item.externalLink
            ? (hoverColorful?.bgColor | prefix: 'hover:bg')
            : ''
        ]"
        class="cursor-pointer flex flex-row items-start px-2"
        (click)="itemClicked.next(item.screen?.slug ?? item.externalLink)"
      >
        <i *ngIf="startIcon" class="mr-2" [ngClass]="[startIcon?.icon]"></i>
        <span>{{ item.title }}</span>
      </li>
      <li
        *ngIf="!isLast && dividerColor"
        class="separator"
        [ngClass]="[orientation, dividerColor | prefix: 'bg']"
      ></li>
    </ng-container>
  </ul>
</div>
