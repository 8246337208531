import gql from 'graphql-tag';

export const readAllAggregatedSensorsData = gql`
  query ReadAllAggregatedSensorsData($group: String!, $filter: String, $project: String){
    response: readAllAggregatedSensorData(
      filter: $filter
      project: $project
      group: $group
    ){
      total
      page
      take
      hasMore
      items{
        metrics
      }
    }
  }
`
