<h1 mat-dialog-title>Vyberte {{settings.isMedia ? 'média' : 'položky'}}</h1>

<mat-tab-group
  *ngIf="settings.isMedia; else noMedia"
  animationDuration="0ms">
  <mat-tab label="Média">
    <ng-container *ngTemplateOutlet="dataCard"></ng-container>
  </mat-tab>
  <mat-tab label="Nahrát nové">
    <ng-container *ngTemplateOutlet="mediaUpload"></ng-container>
  </mat-tab>
</mat-tab-group>

<!-- DATA CARD DEFAULT WHEN NO MEDIA-->
<ng-template #noMedia>
  <ng-container *ngTemplateOutlet="dataCard"></ng-container>
</ng-template>

<ng-template #dataCard>
  <echo-nx-data-card
    mat-dialog-content
    [entityServiceData]="settings.entityServiceData"
    [title]="settings.title"
    [canSelectMultiple]="settings.isMultiple"
    [viewType]="settings.viewType ?? 'table'"
    [filterServiceData]="settings.filterServiceData"
    [selectOnItemClick]="settings.selectOnItemClick"
    [initiallySelectedValues]="settings.initiallySelectedValues"
    [filterDefinition]="settings.filterDefinition"
    [tableItemDefinition]="settings.tableItemDefinition"
    [gridItemDefinition]="settings.gridItemDefinition"
  >
  </echo-nx-data-card>

  <div mat-dialog-actions>
    <button mat-button (click)="onCancelClicked()">
      Zrušit
    </button>
    <button mat-button (click)="onConfirmClicked()" color="primary">
      Potvrdit
    </button>
  </div>
</ng-template>


<ng-template #mediaUpload>
  <div mat-dialog-content>
    <div *ngIf="uploadResult" class="py-2 px-4 rounded-md border-2 mb-4 flex item-center gap-x-4"
         [class.border-warn-900]="uploadResult.error"
         [class.bg-warn-600]="uploadResult.error"
         [class.border-green-900]="!uploadResult.error"
         [class.bg-green-600]="!uploadResult.error"
    >
      <mat-icon
        class="text-white"
        [svgIcon]="uploadResult.error ? 'heroicons_outline:exclamation-circle' : 'heroicons_outline:check-circle'"></mat-icon>

      <span class="font-medium text-lg flex-1">{{uploadResult.message}}</span>

      <mat-icon
        (click)="uploadResult = null"
        role="button"
        matRipple
        class="text-white cursor-pointer	"
        svgIcon="heroicons_outline:x"></mat-icon>
    </div>
    <echo-nx-upload-media [mediaServiceData]="settings.entityServiceData" (uploadCompleted)="onUploadCompleted" #uploadMediaComponent></echo-nx-upload-media>
  </div>


  <div mat-dialog-actions>
    <button mat-button (click)="onCancelClicked()">
      Zrušit
    </button>
    <button mat-button (click)="uploadMedia()" color="primary">
      Nahrát
    </button>
  </div>
</ng-template>



