import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {CardModule, LoadingModule} from "@echo-nx/shared/ng/ui/generic";
import {ListModule} from "./ui/list/list.module";
import {TableModule} from "./ui/table/table.module";
import {GridModule} from "./ui/grid/grid.module";
import {DataCardComponent} from "./data-card.component";
import {DataCardFilterModule} from "./modules/data-card-filter/data-card-filter.module";
@NgModule({
  imports: [CommonModule],
})
export class SharedNgFeatureDataCardModule {}


@NgModule({
  declarations: [
    DataCardComponent
  ],
  exports: [
    DataCardComponent
  ],
  imports: [
    CommonModule,
    CardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    DataCardFilterModule,
    MatTooltipModule,
    MatPaginatorModule,
    TableModule,
    ListModule,
    GridModule,
    LoadingModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: () => {
        const paginatorIntl = new MatPaginatorIntl();
        paginatorIntl.itemsPerPageLabel = "Počet položek na stránce:";
        paginatorIntl.nextPageLabel = "Další stránka";
        paginatorIntl.previousPageLabel = "Předchozí stránka";
        paginatorIntl.firstPageLabel = "První stránka";
        paginatorIntl.lastPageLabel = "Poslední stránka";
        paginatorIntl.getRangeLabel = getRangeLabel.bind(this);
        return paginatorIntl;
      }
    }
  ]
})
export class DataCardModule {
}

const getRangeLabel = (page: number, pageSize: number, length: number): string => {
  if (length === 0 || pageSize === 0) {
    return `0 z ${length}`;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
  return `${startIndex + 1} - ${endIndex} z ${length}`;
}
