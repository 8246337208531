<div
  class="flex border-t border-black pt-6"
  [ngClass]="[
    baseOrientation | prefix: 'base-orientation',

  ]"
  #responsive="echoNxResponsive"
  [class.h-full]="fluid === 'vertical' || fluid === 'both'"
  [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
  [echoNxResponsive]="breakpointSettings"
>
  <!-- IMAGE -->
  <div *ngIf="image" [ngClass]="[isVertical ? 'h-64' : 'basis-1/4']">
    <!-- image   -->
    <img
      class="object-cover w-full h-full"
      [src]="image.thumbnailUrl ?? image.url"
      [alt]="image.alt"
    />
  </div>

  <!-- CONTENT -->
  <div
    class="flex flex-col space-y-4 p-2"
    [ngClass]="[!isVertical && image ? 'basis-3/4' : '']"
  >
    <!-- title, attributes -->
    <div
      class="flex flex-col space-y-2 px-4 py-2 items-center sm:items-start text-center sm:text-start"
      [ngClass]="[
        secondaryColor | prefix: 'border',
        secondaryColor | prefix: 'text'
      ]"
    >
      <!-- title -->
      <div
        class="text-xl uppercase underline font-normal font-display"
        [ngClass]="[titleText?.color | prefix: 'text']"
      >
        {{ title }}
      </div>

      <!-- attributes -->
      <div
        *ngIf="attributes"
        [class.flex-col]="isVertical"
        [class.space-y-2]="isVertical"
        [class.flex-row]="!isVertical"
        [class.space-x-4]="!isVertical"
        class="flex"
      >
        <div
          class="space-x-2 flex items-center font-base text-sm font-medium justify-center sm:justify-start"
          *ngFor="let attribute of attributes"
        >
          <i *ngIf="attribute.startIcon" [ngClass]="attribute.startIcon"></i>
          <span>{{ attribute.text }}</span>
          <i *ngIf="attribute.endIcon" [ngClass]="attribute.endIcon"></i>
        </div>
      </div>
    </div>

    <!-- perex -->
    <div *ngIf="perex" class="font-base" [innerHTML]="perex"></div>

    <!-- categories, date -->
    <div class="flex px-3 font-bold text-sm">

      <div class='flex' *ngIf="categories" >
        <div >Kategorie: </div>
        <div class='flex' *ngFor="let cat of categories">
          <span class='px-1' >{{ cat.name }} </span>
        </div>
      </div>

      <!-- date -->
      <div
        class="font-medium"
        *ngIf="date"
        [ngClass]="[
          dateText?.bgColor | prefix: 'bg',
          dateText?.color | prefix: 'text'
        ]"
      >
        - {{ date }}
      </div>

    </div>

    <!-- footer -->
    <div
      [ngClass]="footerText?.bgColor | prefix: 'bg'"
      class="mt-3 space-x-2 flex items-center text-gray-700"
      *ngIf="footer"
    >
      <i
        [ngClass]="[footerText?.icon ?? '', footerText?.color | prefix: 'text']"
      ></i>
      <span class="font font-medium text-sm underline">{{ footer }}</span>
    </div>
  </div>
</div>
