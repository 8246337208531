<!--<div> PLSZZZZ {{entityFormService?.form?.value | json}}</div>-->

<ng-container *ngIf="entityFormService.hasControls">
  <form [formGroup]="entityFormService.form">
    <div
      *ngFor="let field of entityDefinitionsWithToken"
      [ngClass]="field.name === 'PkHiddenFieldComponent' ? 'hidden-field' : ''"
      [style.grid-row-start]="field.y ? field.y + 1 : undefined"
      [style.grid-column-start]="field.x ? field.x + 1 : undefined"
      [style.grid-column-end]="field.x ? field.x + 1 + field.cols : undefined"
      [style.grid-column]="'span ' + field.cols"
    >
      <ng-template
        (attached)="onPortalAttached(field.settings.formControlName, $event)"
        [cdkPortalOutlet]="field | dynamicComponent">
      </ng-template>
    </div>
  </form>


  <ng-container *ngIf="errors$ | async as errors">
    <hr>

    <div id="errors" *ngIf="errors.errorMessages.length > 0 && !entityFormService.isConcept; else noError"
         class="flex flex-col gap-y-2 mb-8">
      <div class="flex flex-col gap-y-4 border-red-700 border-2 p-4">
        <span class="text-lg font-medium">Formulář obsahuje chyby:</span>

        <ul class="list-disc list-inside flex flex-col gap-y-2 mt-2">
          <li class="underline cursor-pointer hover:text-red-600" *ngFor="let error of errors.errorMessages"
              (click)="onErrorMessageClick(error)">
            <span class="font-medium">{{error.label.join(' > ')}}</span> - {{error.errorKey | translate:error.params}}
          </li>
        </ul>
      </div>
    </div>

    <!-- valid message -->
    <ng-template #noError>
      <div class="border-2 border-green-500 p-4"
           [class.border-yellow-500]="entityFormService.isConcept"
           [class.border-green-500]="entityFormService.form.status === 'VALID'">
        {{entityFormService.form.status | formStatusText:entityFormService.isConcept}}
      </div>
    </ng-template>
  </ng-container>


</ng-container>
