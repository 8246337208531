<ng-container *ngIf="navigation && navigation.children.length">
  <h1
    class="font-display text-2xl my-2"
    [ngClass]="[titleColor ?? 'primary-500' | prefix: 'text']"
    *ngIf="!hideTitle && navigation.title"
  >
    {{ navigation.title }}
  </h1>
  <div class="items-grid" [ngClass]="[textColor | prefix: 'text']">
    <div
      class="cursor-pointer hover:text-secondary-500 flex flex-row justify-between items-center gap-3 text-lg"
      *ngFor="let item of navigation.children"
      (click)="itemClicked.emit(item.screen?.slug ?? item.externalLink)"
      [ngClass]="[
        divideSize === 'small' ? 'py-1' : '',
        divideSize === 'medium' ? 'py-4' : '',
        divideSize === 'large' ? 'py-8' : '',
        dividerColor ? 'border-b border-dashed' : '',
        dividerColor | prefix: 'border'
      ]"
    >
      <span class="uppercase">{{ item.title }}</span>
      <i *ngIf="item.icon" class="mx-2" [ngClass]="[item?.icon]"></i>
    </div>
  </div>
</ng-container>
