import { Component, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import {ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {
  getSummaryForCoupons,
  getSummaryForItemType,
  IMDialogCommodity,
  IMProductType,
  IMTicketType, priceDescComparator,
  SummaryItem
} from "@echo-nx/marlenka/common";
import {TableField} from "@echo-nx/shared/ng/feature/common";
import {SnackbarComponent, SnackbarData} from "@echo-nx/shared/ng/feature/snackbar";



@Component({
  selector: 'marlenka-summary-step',
  templateUrl: './summary-step.component.html',
  styleUrls: ['./summary-step.component.scss']
})
export class SummaryStepComponent implements OnDestroy {
  private readonly isDestroyed$ = new Subject<boolean>();

  public readonly summaryItems$: Observable<SummaryItem[]>;
  public readonly summaryTableFields: TableField<SummaryItem>[] = [
    {
      key: 'nazev',
      label: 'Název zboží'
    },
    {
      key: 'itemCount',
      label: 'Počet kusů'
    },
    {
      key: 'unitPrice',
      label: 'Cena za ks',
      formatter: item => `${item.unitPrice} Kč`
    },
    {
      key: 'totalPrice',
      label: 'Cena celkem',
      //formatter: item => isCoupon(item) ? `-${item.totalCost} Kč` : `${item.totalCost} Kč`
      formatter: item => `${item.totalPrice} Kč`
    },
    {
      key: 'delete-cell',
      label: 'Odstranit'
    }
  ];


  constructor(public reservationService: ReservationService, private snackBar: MatSnackBar) {
    this.summaryItems$ = combineLatest([
      reservationService.selectedProducts$,
      reservationService.selectedTickets$,
      reservationService.selectedCoupons$
    ])
      .pipe(
        map(([products, tickets, coupons]) => {
          const summaryProducts = getSummaryForItemType(products, 'local_dining', 'product');//.sort(priceDescComparator);
          const summaryTickets = getSummaryForItemType(tickets, 'style', 'ticket')//;.sort(priceDescComparator);
          const summaryCoupons = getSummaryForCoupons(coupons).sort(priceDescComparator);

          return [...summaryTickets, ...summaryProducts, ...summaryCoupons];
        })
      );
  }


  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }

  addItem(item: SummaryItem) {
    const { type } = item;
    switch (type) {
      case 'product':
        this.reservationService.addProduct(item.itemType as IMProductType<IMDialogCommodity>);
        break;
      case 'ticket':
        this.reservationService.addTicket(item.itemType as IMTicketType<IMDialogCommodity>);
        break;
    }
  }

  removeItem(item: SummaryItem) {
    // dont let them go past 1
    if (item.unitCount <= 1) return;

    const { type } = item;
    switch (type) {
      case 'product':
        this.reservationService.removeProduct(item.itemType as IMProductType<IMDialogCommodity>);
        break;
      case 'ticket':
        this.reservationService.removeTicket(item.itemType as IMTicketType<IMDialogCommodity>);
        break;
    }
  }

  deleteItem(item: SummaryItem) {
    const { type } = item;
    let showError = false;
    if (type === 'product') {
      this.reservationService.selectedProducts = this.reservationService.selectedProducts.filter(p => p.itemType._id !== item.itemType?._id);
    } else if (type === 'ticket') {
      const toDelete = this.reservationService.selectedTickets.filter(t => t.itemType._id !== item.itemType?._id);
      // dont let them fk up the steps by removing the necessities
      if (toDelete.length === 0  && this.reservationService.selectedCoupons.length === 0) {
        showError = true
      } else {
        // delete all ticket types
        this.reservationService.selectedTickets = toDelete;
      }
    } else {
      // coupon
      const toDelete = this.reservationService.selectedCoupons.filter(c => c.code !== item.uniqueId);
      if (toDelete.length === 0 && this.reservationService.selectedTickets.length === 0) {
        showError = true;
      } else {
        this.reservationService.selectedCoupons = toDelete;
      }

    }


    if (showError) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          text: 'V rezervaci je nutné mít alespoň jednu vstupenku.', //todo INTL
          type: 'WARNING'
        } as SnackbarData,
        panelClass: 'custom-snackbar',
        verticalPosition: 'top',
        horizontalPosition: 'end'
      });
    }

  }


}



