import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, switchMap } from 'rxjs/operators';
import { MarlenkaPageIdentifier } from '@echo-nx/marlenka/common';
import {
  FetchAllArgs,
  filterNullish,
  IArticle,
  IPaginatedResponse,
  IPKApolloResult,
} from '@echo-nx/shared/common';
import {
  BaseArticlesService,
  readAllArticleForSynchronizing,
  readAllArticles,
} from '@echo-nx/shared/ng/data-access';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends BaseArticlesService {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  public mapIdentifierToCategory(type: MarlenkaPageIdentifier): string[] {
    switch (type) {
      case MarlenkaPageIdentifier.ARTICLES:
        return ['Aktuality', 'News', 'Nachrichten', 'Новости'];
      case MarlenkaPageIdentifier.AWARDS:
        return ['Awards', 'Auszeichnungen', 'Ocenění', 'Премии'];
      case MarlenkaPageIdentifier.CAREER:
        return ['Kariéra', 'Career', 'Karriere', 'Карьера'];
      case MarlenkaPageIdentifier.CERTIFICATES:
        return ['Certifikáty', 'Certificates', 'Zertifikate', 'Сертификаты'];
      case MarlenkaPageIdentifier.VIDEOS:
        return ['Videa', 'Videos', 'Видео'];
      default:
        throw new Error('mapIdentifierToCategory error - type not implemented');
    }
  }

  public fetchSingleMultilanguage(
    id: string,
    language: string
  ): Observable<IArticle> {
    return this.apollo
      .query<IPKApolloResult<IPaginatedResponse<IArticle>>>({
        query: readAllArticleForSynchronizing,
        variables: {
          includeDeleted: false,
          filter: JSON.stringify({
            languageGroupId: id,
            language,
          }),
        } as FetchAllArgs,
      })
      .pipe(map((result) => result.data.response.items[0]));
  }

  public fetchPaginated(type: string[], page: number, take = 10, lang = 'cs') {
    return this.fetchCategories(lang).pipe(
      map((categories) => categories.find((cat) => type.includes(cat.name))),
      filterNullish(),
      switchMap(({ _id }) =>
        this.fetchAll({
          filter: JSON.stringify({ categories: { $in: [_id] } }),
          page,
          take,
          sortDirection: 'DESC',
          sortColumn: 'publishedAt',
        })
      )
    );
  }

  public fetchPreviews(type: MarlenkaPageIdentifier, count = 3, lang = 'cs') {
    console.log('fetching preview for type', type, 'lang is', lang);
    return this.fetchCategories(lang).pipe(
      map((categories) =>
        categories.find((cat) =>
          this.mapIdentifierToCategory(type).includes(cat.name)
        )
      ),
      filterNullish(),
      switchMap(({ _id }) =>
        this.fetchAll({
          filter: JSON.stringify({
            categories: { $in: [_id] },
            language: lang,
          }),
          take: count,
          sortDirection: 'DESC',
          sortColumn: 'publishedAt',
        }).pipe(map((res) => res.items))
      )
    );
  }

  public fetchArticlesInCategory(
    categoryId: string,
    take = 3,
    language = 'cs'
  ) {
    return this.fetchAll({
      filter: JSON.stringify({
        categories: { $in: [categoryId] },
        language,
      }),
      take,
      sortDirection: 'DESC',
      sortColumn: 'publishedAt',
    }).pipe(map((res) => res.items));
  }
}
