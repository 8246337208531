import { Component, HostBinding, Input } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  IBaseDatetimeBarData,
  IBaseDatetimeBarDesign,
} from '@echo-nx/shared/common';

@Component({
  selector: 'echo-nx-date-time-bar-variant-b',
  templateUrl: './date-time-bar-variant-b.component.html',
  styleUrls: ['./date-time-bar-variant-b.component.scss'],
})
export class DateTimeBarVariantBComponent
  implements IBaseDatetimeBarData, IBaseDatetimeBarDesign
{
  @Input()
  accentColor?: string;

  @Input()
  primaryColor?: string;

  @Input()
  secondaryColor: string;

  @Input()
  textColor?: string;

  @Input()
  title: string;

  /* Current screens do not have fluid set - remove default when screens are fixed */
  @Input()
  fluid: 'horizontal' | 'vertical' | 'both' = 'horizontal';

  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal',
    };
  }

  @Input()
  set data(data: IBaseDatetimeBarData) {
    this.title = data?.title;
  }

  @Input()
  set design({
    primaryColor,
    accentColor,
    secondaryColor,
    textColor,
    fluid,
  }: IBaseDatetimeBarDesign) {
    this.primaryColor = primaryColor;
    this.accentColor = accentColor;
    this.secondaryColor = secondaryColor;
    this.textColor = textColor;
    /* Current screens do not have fluid set - remove default when screens are fixed */
    this.fluid = fluid || 'horizontal';
  }

  public dateTime$: Observable<Date> = interval(1000).pipe(
    startWith(() => new Date()),
    map(() => new Date())
  );
}
