import { Component } from '@angular/core';
import { ArticleService } from '../../../services/article.service';
import { Observable, switchMap } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';

import { map, tap } from 'rxjs/operators';

import { NavigationService } from '../../../services/navigation.service';
import { IMarlenkaOwner, MarlenkaRouteData } from '@echo-nx/marlenka/common';
import { IArticle, IMedium, MediumType } from '@echo-nx/shared/common';
import { LanguageRouteDataService } from '@echo-nx/shared/ng/feature/language';

interface AttachmentsGroup {
  images: IMedium<IMarlenkaOwner>[];
  videos: IMedium<IMarlenkaOwner>[];
  others: IMedium<IMarlenkaOwner>[];
}

@Component({
  selector: 'marlenka-article-detail-page',
  templateUrl: './article-detail-page.component.html',
  styleUrls: ['./article-detail-page.component.scss'],
})
export class ArticleDetailPageComponent {
  public article$: Observable<
    IArticle<IMarlenkaOwner, IMedium<IMarlenkaOwner>>
  >;
  public attachmentsGroup: AttachmentsGroup;

  constructor(
    private blogService: ArticleService,
    private route: ActivatedRoute,
    private router: Router,
    public mrdService: LanguageRouteDataService<MarlenkaRouteData>,
    private navigationService: NavigationService
  ) {
    const { id } = this.route.snapshot.params;
    this.article$ = this.mrdService.currentLanguage$.pipe(
      switchMap((language) =>
        this.blogService.fetchSingleMultilanguage(id, language).pipe(
          tap((article) => {
            console.log('ahoj', article);
            if (article) {
              if (article.attachments?.length > 0) {
                this.groupAttachmentsRelativeToType(
                  article.attachments as IMedium<IMarlenkaOwner>[]
                );
              }
            } else {
              this.router.navigateByUrl('/404');
            }
          })
        )
      )
    );
  }

  private groupAttachmentsRelativeToType(
    attachments: IMedium<IMarlenkaOwner>[]
  ) {
    this.attachmentsGroup = attachments.reduce(
      (acc, curr) => {
        switch (curr.type) {
          case MediumType.Image:
            acc.images.push(curr);
            break;
          case MediumType.YouTubeVideo:
            acc.videos.push(curr);
            break;
          default:
            acc.others.push(curr);
            break;
        }
        return acc;
      },
      {
        images: [],
        videos: [],
        others: [],
      } as AttachmentsGroup
    );
  }
}
