import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';

@Pipe({
  name: 'daysOfMonth'
})
export class DaysOfMonthPipe implements PipeTransform {

  transform(value: Date, ...args: unknown[]): number[] {
    const numberOfDays = DateTime.fromJSDate(value).daysInMonth;
    return Array.from(Array(numberOfDays).keys())
  }

}
