import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CalendarDayComponent} from "./calendar-day/calendar-day.component";
import {CalendarComponent} from "./calendar/calendar.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatRippleModule} from "@angular/material/core";
import {PortalModule} from "@angular/cdk/portal";
import {
  DynamicComponentPipeModule,
  DynamicComponentRegistryService, FirstDayOfMonthModule,
  SharedNgFeatureCommonModule
} from "@echo-nx/shared/ng/feature/common";


@NgModule({
  declarations: [
    CalendarDayComponent,
    CalendarComponent
  ],
  imports: [
    DynamicComponentPipeModule,
    SharedNgFeatureCommonModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    PortalModule,
    FirstDayOfMonthModule
  ],
  exports:[
    CalendarDayComponent,
    CalendarComponent
  ]
})
export class SharedNgReservationFeatureModule {
  constructor(private dynamicComponentRegistry: DynamicComponentRegistryService) {
    this.dynamicComponentRegistry.add('CalendarDayComponent', CalendarDayComponent);
  }
}
