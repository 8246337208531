import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasePaginationComponent } from "../base";


@Component({
  selector: 'echo-nx-pagination-variant-nyrov',
  templateUrl: './pagination-variant-nyrov.component.html',
  styleUrls: ['./pagination-variant-nyrov.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationVariantNyrovComponent extends BasePaginationComponent {
  get showingFrom() {
    return this.page * this.take + 1;
  }

  get showingTo() {
    return Math.min((this.page + 1) * this.take, this.total)
  }
}

