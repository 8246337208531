import { Validators } from "@angular/forms";
import { IEntityDefinition } from "../../interfaces";
import { IGroupFieldSettings, ITextFieldSettings } from "../../modules/form-fields";

export const getSearchBarWidgetEntityDefinition = (): IEntityDefinition[] => {
  return [
    {
      name: 'PkInputGroupFieldComponent',
      settings: {
        nazev: 'Nastavení',
        formControlName: 'settings',
        withoutCard: true,
        groupDefinition: [
          {
            name: 'PkInputTextFieldComponent',
            settings: {
              nazev: 'Slug výsledku vyhledávání',
              formControlName: 'searchRoute',
              povinnost: true,
            } as ITextFieldSettings,
            data: null,
            validators: [Validators.required],
            cols: 12,
          },
        ]
      } as IGroupFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    }
  ];
};