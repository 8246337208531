import gql from 'graphql-tag';

export const readAllDepartment = gql`
  query ReadAllDepartment(
    $search: String
    $page: Int
    $take: Int
    $sortColumn: String
    $sortDirection: String
    $filter: String
    $includeNotPublished: Boolean
  ) {
    response: readAllDepartment(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
      includeNotPublished: $includeNotPublished
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        language
        languageGroupId
        name
        description
        openingHours
        publishedAt
        unpublishedAt
        contact {
          _id
          name
          priority
          websites
          companyName
          firstName
          lastName
          telephones
          emails
          name
          ico
          dic
          dataBox
          tradeRegisterText
          bankInfo {
            bankName
            iban
            swift
            bankNumber
            bankCode
          }
          address {
            city
            province
            state
            zipcode
            street
          }
        }
      }
    }
  }
`;
