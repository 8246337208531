import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {IBaseButtonData, IBaseButtonDesign, IEpxColorful, IEpxIcon} from "@echo-nx/shared/common";


@Component({
  selector: 'echo-nx-button-variant-nyrov',
  templateUrl: './button-variant-nyrov.component.html',
  styleUrls: ['./button-variant-nyrov.component.scss']
})
export class ButtonVariantNyrovComponent implements OnInit, IBaseButtonData, IBaseButtonDesign {

  @Input()
  orientation;

  @Input()
  bgColor: string;

  @Input()
  accentColor: string;

  @Input()
  prominent = false;

  @Input()
  dense = false;

  @Input()
  outlined = false;

  @Input()
  textColor: string;

  @Input()
  secondaryColor: string;

  @Input()
  description?: string;

  @Input()
  descriptionColorful?: IEpxColorful;

  @Input()
  title!: string;

  @Input()
  startIcon?: IEpxIcon & { offset?: boolean };

  @Input()
  endIcon?: IEpxIcon & { offset?: boolean };

  @Input()
  fluid;

  @Input()
  fontSize: string;

  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal' || this.fluid === 'both',
      'flex': this.fluid === 'horizontal' || this.fluid === 'both',
      'h-full': this.fluid === 'vertical' || this.fluid === 'both',
    };
  }

  @Input()
  set design({
               textColor,
               bgColor,
               startIcon,
               endIcon,
               fluid,
               descriptionColorful,
               prominent,
               orientation,
               outlined,
               dense,
               secondaryColor,
               fontSize
             }: IBaseButtonDesign) {
    this.fluid = fluid;
    this.textColor = textColor;
    this.bgColor = bgColor;
    this.startIcon = startIcon;
    this.endIcon = endIcon;
    this.descriptionColorful = descriptionColorful;
    this.prominent = prominent;
    this.orientation = orientation ?? 'row';
    this.dense = dense;
    this.outlined = outlined;
    this.secondaryColor = secondaryColor;
    this.fontSize = fontSize;
  }

  @Input()
  set data({
             title,
             description,
           }: IBaseButtonData) {
    this.title = title;
    this.description = description;
  }

  ngOnInit(): void {
    return;
  }

}
