<div class="main" [class.topWave]="topWave">
  <div
    class="col first-col"
    [class.firstColBigger]="firstColBigger"
    [style.background]="
      'url(' + mainItem.backgroundImg + ') center center / cover no-repeat'
    "
  >
    <ng-container *ngIf="mainItem.isLinkExternal; else internalBlock">
      <a
        class="item"
        [href]="mainItem.routeTo"
        [style.color]="mainItem.foregroundColor"
      >
        <div class="contentWrap">
          <h2 class="title">
            {{ mainItem.title }}
          </h2>
          <!-- <hr /> -->
          <p class="subtitle">
            <marlenka-wysiwyg-view [html]="mainItem.subtitle">
            </marlenka-wysiwyg-view>
          </p>
        </div>
        <!-- <div [style.background]="mainItem.gradientStyle" class="overlay"></div> -->
      </a>
    </ng-container>

    <ng-template #internalBlock>
      <a
        class="item"
        [routerLink]="mainItem.routeTo"
        [fragment]="mainItem.fragment"
        [style.color]="mainItem.foregroundColor"
      >
        <div class="contentWrap">
          <h2 class="title">
            {{ mainItem.title }}
          </h2>
          <!-- <hr /> -->
          <p class="subtitle">
            <marlenka-wysiwyg-view [html]="mainItem.subtitle">
            </marlenka-wysiwyg-view>
          </p>
        </div>
        <!-- <div [style.background]="mainItem.gradientStyle" class="overlay"></div> -->
      </a>
    </ng-template>
  </div>

  <div class="col second-col" *ngIf="mainSideItems?.length > 0">
    <div
      *ngFor="let item of mainSideItems"
      class="item item-second-col"
      [style.color]="item.foregroundColor"
      [style.background]="
        'url(' + item.backgroundImg + ') center center / cover no-repeat'
      "
    >
      <ng-container *ngIf="item.isLinkExternal; else internalBlock2">
        <a [href]="item.routeTo">
          <div class="contentWrap">
            <h2 class="title">
              {{ item.title }}
            </h2>
            <!-- <hr /> -->
            <p class="subtitle">
              <marlenka-wysiwyg-view [html]="item.subtitle">
              </marlenka-wysiwyg-view>
            </p>
          </div>
          <div
            [style.backgroundColor]="item.backgroundColor"
            class="overlay"
            *ngIf="item.backgroundColor"
          ></div>
        </a>
      </ng-container>
      <ng-template #internalBlock2>
        <a [routerLink]="item.routeTo" [fragment]="item.fragment">
          <div class="contentWrap">
            <h2 class="title">
              {{ item.title }}
            </h2>
            <!-- <hr /> -->
            <p class="subtitle">
              <marlenka-wysiwyg-view [html]="item.subtitle">
              </marlenka-wysiwyg-view>
            </p>
          </div>
          <div
            [style.backgroundColor]="item.backgroundColor"
            class="overlay"
            *ngIf="item.backgroundColor"
          ></div>
        </a>
      </ng-template>
    </div>
  </div>
</div>

<div class="bottom" *ngIf="bottomItems?.length > 0">
  <div
    *ngFor="let item of bottomItems"
    class="item item-bottom-col"
    [style.background]="
      'url(' + item.backgroundImg + ') center center / cover no-repeat'
    "
    [style.color]="item.foregroundColor"
  >
    <ng-container *ngIf="item.isLinkExternal; else internalBlock3">
      <a [href]="item.routeTo">
        <div class="contentWrap">
          <h3 class="title">
            {{ item.title }}
          </h3>
        </div>
        <div
          [style.backgroundColor]="item.backgroundColor"
          class="overlay"
          *ngIf="item.backgroundColor"
        ></div>
      </a>
    </ng-container>
    <ng-template #internalBlock3>
      <a [routerLink]="item.routeTo" [fragment]="item.fragment">
        <div class="contentWrap">
          <h3 class="title">
            {{ item.title }}
          </h3>
        </div>
        <div
          [style.backgroundColor]="item.backgroundColor"
          class="overlay"
          *ngIf="item.backgroundColor"
        ></div>
      </a>
    </ng-template>
  </div>
</div>
