import {gql} from 'apollo-angular';

export const deleteKiosk = gql`
  mutation deleteKiosk($input: [String!]!, $forceDelete: Boolean){
    response: deleteKiosk(ids: $input, forceDelete: $forceDelete){
      userMessage
      error
      description
    }
  }
`;
