import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  IBaseCalendarDesign, IEpxFluid
} from "@echo-nx/shared/common";
import { DateTime } from 'luxon';

@Component({
  selector: 'echo-nx-calendar-variant-nyrov',
  templateUrl: './calendar-variant-nyrov.component.html',
  styleUrls: ['./calendar-variant-nyrov.component.scss'],
})
export class CalendarVariantNyrovComponent implements OnInit, OnChanges, IBaseCalendarDesign {

  @Input()
  public language!: string;

  @Input()
  public currentMonth!: Date;

  @Input()
  public selectedDay!: Date;

  @Output()
  public monthChanged = new EventEmitter<Date>();

  @Output()
  public daySelected = new EventEmitter<Date>();

  @Input()
  public bgColor? = 'gray-100';

  @Input()
  public secondaryColor? = 'gray-800';

  @Input()
  public accentColor? = 'gray-600';

  @Input()
  public activeColor? = 'primary-500';

  public days: string[] = [];

  @Input()
  public fluid?: IEpxFluid;

  @Input()
  set design({
    bgColor,
    secondaryColor,
    activeColor,
    accentColor,
    fluid,
  }: IBaseCalendarDesign) {
    this.bgColor = bgColor;
    this.secondaryColor = secondaryColor;
    this.activeColor = activeColor;
    this.accentColor = accentColor;
    this.fluid = fluid;
  }

  ngOnInit(): void {
    if (!this.currentMonth) {
      this.currentMonth = new Date();
    }
    return;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { language } = changes;
    if (language) {
      const { currentValue } = language;
      this.days = createLocalizedDays(currentValue);
    }
  }

  public nextMonth() {
    this.currentMonth = DateTime.fromJSDate(this.currentMonth).plus({ months: 1 }).toJSDate();
    this.monthChanged.emit(this.currentMonth);
  }

  public previousMonth() {
    this.currentMonth = DateTime.fromJSDate(this.currentMonth).minus({ months: 1 }).toJSDate();
    this.monthChanged.emit(this.currentMonth);

  }

  public isSelected(index: number) {
    // todo pipe
    return (
      this.selectedDay?.getDate() === index + 1 &&
      this.selectedDay?.getMonth() === this.currentMonth.getMonth() &&
      this.selectedDay?.getFullYear() === this.currentMonth.getFullYear()
    );
  }

  public selectDay(index: number) {
    this.selectedDay = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), index + 1, 6);
    this.daySelected.emit(this.selectedDay);
  }
}


const createLocalizedDays = (lang: string): string[] => {
  const dayNames = []
  for (let i = 1; i <= 7; i++) {
    const date = new Date(0, 0, i)
    const dn = date.toLocaleString(lang, { weekday: 'short' });
    dayNames.push(dn);
  }
  return dayNames;
}